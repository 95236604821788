/**
 * DocumentSuccess
 * @flow
 */
'use strict'

import React from 'react'
import { connect } from 'react-redux'
import { removeDocumentData } from '../../../redux/actions'

import { AppView, BackgroundView, Button, ElevatedView, Text, View } from '../../../components'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  // ...
}


class DocumentSuccess extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    // this.state = {}

    // const DocumentSuccess = (this: any)
    // DocumentSuccess.onEvent = this.onEvent.bind(this)
  }

  componentDidMount() {
    // remove document data from redux store
    this.props.removeDocumentData()
  }

  render() {
    return (
      <AppView id="DocumentSuccess">
        <BackgroundView>
          <Text className="welcomeTitle">Tresorinhalt erstellt</Text>
          <ElevatedView>
            <Text className="highlightTitle">
              Der Tresorinhalt wurde erfolgreich erstellt.
            </Text>
          </ElevatedView>
          <View style={{ marginTop: 'auto' }}>
            <Button
              style={{ marginBottom: 0 }}
              type="gradient"
              onPress={() => this.props.history.push('/vault')}
            >Zum Tresor</Button>
            <Button
              className="plain"
              type="outlined"
              onPress={() => this.props.history.push('/')}
            >Zurück zum Hauptmenü</Button>
          </View>
        </BackgroundView>
      </AppView>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  removeDocumentData: () => dispatch(removeDocumentData()),
})

export default connect(
  null,
  mapDispatchToProps
)(DocumentSuccess)
