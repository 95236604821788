/**
 * SignIn
 * @flow
 */
'use strict'

import React from 'react'
import { Link } from 'react-router-dom'

import { api } from '../../helpers'

import { AppView, BackgroundView, Button, Colors, ElevatedView, Input, Text, View } from '../../components'
import Container from '@material-ui/core/Container'

import logo from '../../assets/img/logo.png'
import hidePassword from '../../assets/img/hide-password.png'
import showPassword from '../../assets/img/show-password.png'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  error: ?string,
  email: ?string,
  password: ?string,
  showPassword: boolean,
}


export default class SignIn extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      email: null,
      password: null,
      showPassword: false,
    }

    const SignIn = (this: any)
    SignIn.onLogin = this.onLogin.bind(this)
  }

  render() {
    const { error, email, password, showPassword } = this.state

    return (
      <AppView
        id="SignIn"
        hideMenu={true}
      >
        <BackgroundView>
          <ElevatedView>
            <form>
              <Input
                autoFocus={true}
                placeholder="E-Mail"
                required={true}
                type="email"
                value={email}
                onChangeText={(value: string) => this.setState({ email: value })}
              />
              <Input
                icon={ password && ! showPassword ?
                    {
                      src: require('../../assets/img/show-password.png'),
                      style: { height: 18, width: 18 },
                      onPress: () => this.setState({ showPassword: ! showPassword }),
                    }
                  : password && showPassword ?
                    {
                      src: require('../../assets/img/hide-password.png'),
                      style: { height: 18, width: 18 },
                      onPress: () => this.setState({ showPassword: ! showPassword }),
                    }
                  : undefined
                }
                placeholder="Passwort"
                required={true}
                type={ ! showPassword ? 'password' : 'text' }
                value={password}
                onChangeText={(value: string) => this.setState({ password: value })}
              />
            </form>

            <View className="linkWrapper">
              <Link to='/forgot-password'>Passwort vergessen?</Link>
            </View>

            <Button
              className="loginButton"
              type="gradient"
              style={{ marginTop: 0, marginBottom: 0 }}
              onPress={this.onLogin}
            >Anmelden</Button>

            { error
              ? <Text className="error">{error}</Text>
              : null
            }

            <View className="separatorWrapper">
              <View className="separatorLine" />
              <Text className="separatorText">oder</Text>
              <View className="separatorLine" />
            </View>

            <Button
              className="signupButton"
              type="outlined"
              style={{ marginTop: 0, marginBottom: 0 }}
              onPress={() => this.props.history.push('/signup')}
            >Jetzt registrieren</Button>
          </ElevatedView>
        </BackgroundView>
      </AppView>
    )
  }

  onLogin() {
    const { email, password } = this.state

    if ( email && password ) {
      api({
        url: 'login',
        method: 'post',
        data: { email, password },
        onSuccess: (response) => {
          // console.debug(response)
          if ( response
            && response.data
            && response.data.status === 'ok'
            && ( response.data.verified
              || ( typeof response.data.has_verified_phone === 'boolean'
                && typeof response.data.has_phone_verification_code === 'boolean'
                && typeof response.data.has_verified_email === 'boolean'
              )
            )
          ) {
            if ( response.data.verified ) {
              this.setState({
                error: null,
                email: null,
                password: null,
              })
              this.props.history.push('/')
            } else {
              this.props.history.push({
                pathname: '/signup',
                state: {
                  step: 2,
                  email,
                  has_verified_phone: response.data.has_verified_phone,
                  has_phone_verification_code: response.data.has_phone_verification_code,
                  has_verified_email: response.data.has_verified_email,
                }
              })
            }
          } else {
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.'
            })
          }
        },
        onError: (error) => {
          // console.error(error.response)
          if ( error
            && error.response
            && error.response.status == 422
          ) {
            this.setState({ error: 'Die Zugangsdaten sind nicht korrekt.' })
          } else {
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.'
            })
          }
        }
      })
    } else {
      this.setState({ error: 'Bitte alle Felder ausfüllen.' })
    }
  }
}
