/**
 * SignupData
 * @flow
 */
'use strict'

import React from 'react'
import moment from 'moment'

import { api } from '../../../helpers'
import {
  AppView, BackgroundView, Button, Colors, Dropdown, ElevatedView, Input, LegendaryButton, Text,
} from '../../../components'
import MomentUtils from "@date-io/moment"
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'


type Props = {
  data: Object,
  onContinue: (data: Object) => void,
}

type State = {
  error: ?string,
  validationErrors: ?Object,
  isValidating: boolean,
  showDatePicker: boolean,

  salutation: ?string,
  firstname: ?string,
  lastname: ?string,
  address: ?string,
  zipcode: ?string,
  city: ?string,
  country: ?string,
  birthdate: ?Object,
}


export default class SignupData extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      validationErrors: null,
      isValidating: false,
      showDatePicker: false,

      salutation: null,
      firstname: null,
      lastname: null,
      address: null,
      zipcode: null,
      city: null,
      country: null,
      birthdate: null,
    }

    const SignupData = (this: any)
    SignupData.onValidate = this.onValidate.bind(this)
  }

  render() {
    const {
      error, validationErrors, isValidating, showDatePicker,
      salutation, firstname, lastname, address,
      zipcode, city, country, birthdate,
    } = this.state

    // user has to be at least 18 years old
    const maximumDate = moment().subtract(18, 'years').toDate()

    return (
      <AppView
        id="SignupData"
        hideMenu={true}
      >
        <BackgroundView>
          <Text className="welcomeTitle">Registrieren</Text>
          <ElevatedView>
            <Dropdown
              validationError={ validationErrors && validationErrors['salutation']
                ? validationErrors['salutation'][0]
                : null
              }
              items={[ { label: 'Frau', value: 1 }, { label: 'Herr', value: 2 } ]}
              placeholder="Anrede"
              value={salutation}
              onValueChange={(value: string, index: number) => {
                this.setState({ salutation: value })
              }}
            />
            <Input
              error={ error && ! firstname }
              validationError={ validationErrors && validationErrors['firstname']
                ? validationErrors['firstname'][0]
                : null
              }
              placeholder="Vorname*"
              type="text"
              value={firstname}
              onChangeText={(value: string) => this.setState({ firstname: value })}
            />
            <Input
              error={ error && ! lastname }
              validationError={ validationErrors && validationErrors['lastname']
                ? validationErrors['lastname'][0]
                : null
              }
              placeholder="Nachname*"
              type="text"
              value={lastname}
              onChangeText={(value: string) => this.setState({ lastname: value })}
            />
            <Input
              validationError={ validationErrors && validationErrors['address']
                ? validationErrors['address'][0]
                : null
              }
              placeholder="Straße / Hausnummer / Stiege / Tür"
              type="text"
              value={address}
              onChangeText={(value: string) => this.setState({ address: value })}
            />
            <Input
              validationError={ validationErrors && validationErrors['zipcode']
                ? validationErrors['zipcode'][0]
                : null
              }
              placeholder="PLZ"
              type="text"
              value={zipcode}
              onChangeText={(value: string) => this.setState({ zipcode: value })}
            />
            <Input
              error={ error && ! city }
              validationError={ validationErrors && validationErrors['city']
                ? validationErrors['city'][0]
                : null
              }
              placeholder="Ort*"
              type="text"
              value={city}
              onChangeText={(value: string) => this.setState({ city: value })}
            />
            <Dropdown
              error={ error && ! country }
              validationError={ validationErrors && validationErrors['country']
                ? validationErrors['country'][0]
                : null
              }
              items={[ { label: 'Österreich', value: 'Österreich' }, { label: 'Deutschland', value: 'Deutschland' }, { label: 'Schweiz', value: 'Schweiz' } ]}
              placeholder="Land*"
              type="text"
              value={country}
              onValueChange={(value: string, index: number) => {
                this.setState({ country: value })
              }}
            />
            <LegendaryButton
              error={ error && ! birthdate }
              validationError={ validationErrors && validationErrors['birthdate']
                ? validationErrors['birthdate'][0]
                : null
              }
              title="Geburtsdatum*"
              value={ birthdate ? moment(birthdate).format('DD.MM.YYYY') : null }
              onPress={() => {
                this.setState({ showDatePicker: ! showDatePicker })
              }}
            />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                open={showDatePicker}
                disableFuture
                format="YYYY-MM-DD"
                maxDate={maximumDate}
                value={birthdate}
                onClose={() => {
                  this.setState({ showDatePicker: false })
                }}
                onChange={(newDate: Object) => {
                  this.setState({
                    birthdate: newDate,
                    showDatePicker: false,
                  })
                }}
              />
            </MuiPickersUtilsProvider>
          </ElevatedView>

          { error
            ? <Text className="error">{error}</Text>
            : null
          }

          <Button
            disabled={isValidating}
            type="gradient"
            containerStyle={{ marginTop: 'auto' }}
            onPress={this.onValidate}
          >Weiter{ isValidating ? '...' : null }</Button>
        </BackgroundView>
      </AppView>
    )
  }

  onValidate() {
    const {
      isValidating, salutation, firstname, lastname, address,
      zipcode, city, country, birthdate,
    } = this.state

    if ( ! isValidating && firstname && lastname
         && city && country && birthdate
    ) {
      this.setState({ isValidating: true }, () => {
        // remove timezone offset
        const formattedBirthdate = moment(birthdate).format('YYYY-MM-DD')

        api({
          url: 'validateData',
          method: 'post',
          data: {
            salutation, firstname, lastname, address, zipcode,
            city, country, birthdate: formattedBirthdate,
          },
          onSuccess: (response) => {
            // console.debug(response)
            if ( response
              && response.data
              && response.data.status === 'ok'
            ) {
              this.setState({
                error: null,
                validationErrors: null,
                isValidating: false,
              })
              const registerData = {
                salutation, firstname, lastname, address, zipcode,
                city, country, birthdate: formattedBirthdate,
              }
              this.props.onContinue({ registerData })
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isValidating: false,
              })
            }
          },
          onError: (error) => {
            // console.debug(error.response)
            if ( error
              && error.response
              && error.response.status == 422
              && error.response.data
              && error.response.data.errors
            ) {
              const errorInputNames = []
              Object.keys(error.response.data.errors).map((key) => {
                errorInputNames.push(key)
              })
              this.setState({
                error: 'Bitte Eingaben überprüfen.',
                validationErrors: error.response.data.errors,
                isValidating: false,
              })
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isValidating: false,
              })
            }
          }
        })
      })
    } else {
      this.setState({ error: 'Bitte Felder ausfüllen.' })
    }
  }
}
