/**
 * MessageReceiver
 * @flow
 */
'use strict'

import React from 'react'

import { BASE_URL, api } from '../../../helpers'
import {
  AppView, ArrowButtonPlainHighlight, BackgroundView, BottomNavigation, Button, Checkbox, Colors, Content,
  ElevatedView, FlatList, Icon, Image, LoadingSpinner, Popup, Text, TouchableOpacity, View,
} from '../../../components'


type Props = {
  messageData: ?Object,
  onNavigate: (messageData: ?Object, route: string) => void,
}

type State = {
  error: ?string,
  isLoading: boolean,
  showPopup: boolean,

  contacts: Array<?Object>,
  currentContact: ?number,
  selectedContacts: Array<?Object>,
}

const CONTACT_ITEM_HEIGHT = 40
const CONTACT_LIST_PADDING = CONTACT_ITEM_HEIGHT * 2
const MAX_SELECTABLE_CONTACTS = 5


export default class MessageReceiver extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    // redirect if required params are not available
    if ( ! props.messageData || ! props.messageData.title ) {
      props.onNavigate(null, '/create-message/title')
    }

    this.state = {
      error: null,
      isLoading: true,
      showPopup: false,

      contacts: [],
      currentContact: null,
      selectedContacts: props.messageData && props.messageData.selectedContacts && props.messageData.selectedContacts.length
        ? props.messageData.selectedContacts
        : [],
    }

    const MessageReceiver = (this: any)
    MessageReceiver.onContinue = this.onContinue.bind(this)
    MessageReceiver.onSelectContact = this.onSelectContact.bind(this)
  }

  componentDidMount() {
    api({
      url: 'userContacts',
      method: 'get',
      onSuccess: (response) => {
        // console.debug(response)
        if ( response
          && response.data
          && response.data.status === 'ok'
          && response.data.contacts != undefined
        ) {
          this.setState({
            contacts: response.data.contacts,
            error: null,
            isLoading: false,
          })
        } else {
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            isLoading: false,
          })
        }
      },
      onError: (error) => {
        // console.error(error.response)
        this.setState({
          error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
          isLoading: false,
        })
      }
    })
  }

  render() {
    const { messageData, onNavigate } = this.props
    const {
      error, isLoading, showPopup,
      contacts, selectedContacts,
    } = this.state

    return (
      <AppView id="MessageReceiver">
        <BackgroundView>
          <Text className="welcomeTitle">Botschaft zuweisen</Text>
          { isLoading ?
              <LoadingSpinner />
            : <>
                <View className="spreadWrapper">
                  <ElevatedView style={{ textAlign: 'left' }}>
                    <View className="titleWrapper">
                      <View>
                        <Text className="pagination">Schritt 3 von 5</Text>
                        <Text className="highlight">Empfänger zuweisen</Text>
                      </View>
                      <Icon
                        src={require('../../../assets/img/info.png')}
                        style={{ height: 28, width: 28, marginBottom: 10 }}
                        onPress={() => this.setState({ showPopup: true })}
                      />
                    </View>
                    <Text style={ error && ! selectedContacts.length
                      ? { color: Colors.error }
                      : undefined
                    }>
                      Wähle hier jene Empfänger aus Deinen Kontakten, die diese Botschaft erhalten sollen.
                    </Text>
                  </ElevatedView>
                </View>

                { contacts && contacts.length ?
                  <>
                    <FlatList
                      data={contacts}
                      renderItem={({ item, index }) => {
                        const selectedContacts = this.state.selectedContacts
                        const {
                          id, firstname, lastname,
                          documents_count, messages_count, is_verifier,
                          profile_picture,
                        } = item

                        const roleNames = []
                        if ( messages_count ) roleNames.push('Empfänger')
                        if ( is_verifier ) roleNames.push('Vertrauensperson')
                        if ( documents_count ) roleNames.push('Tresorzugriff')

                        const isContactSelected = (
                          selectedContacts.findIndex((selectedContact) => {
                            return selectedContact.id === id
                          })
                        )

                        return (
                          <View className="contactItem currentContactItem">
                            <TouchableOpacity
                              className="contactWrapper"
                              onPress={() => this.onSelectContact({ id, firstname, lastname }, isContactSelected)}
                            >
                              <Image
                                source={ profile_picture
                                  ? `${BASE_URL}/profilePicture/${profile_picture}`
                                  : require('../../../assets/img/profile-picture.jpg')
                                }
                                className="profilePicture"
                              />
                              <View>
                                <Text className="contactName">{firstname} {lastname}</Text>
                                { roleNames.length
                                  ? <Text className="contactRoles">{ roleNames.join(' · ') }</Text>
                                  : null
                                }
                              </View>
                            </TouchableOpacity>
                            <View>
                              <Checkbox
                                checked={ isContactSelected !== -1 }
                                onPress={() => this.onSelectContact({ id, firstname, lastname }, isContactSelected)}
                              />
                            </View>
                          </View>
                        )
                      }}
                    />
                    <View className="spreadWrapper">
                      <ArrowButtonPlainHighlight
                        style={{ marginTop: 0, marginBottom: 12 }}
                        onPress={() => onNavigate({
                          ...messageData,
                          selectedContacts,
                        }, '/create-contact')}
                      >Neuen Empfänger erstellen</ArrowButtonPlainHighlight>

                      <Button type="gradient" onPress={this.onContinue}>Weiter</Button>
                    </View>
                  </>
                : <>
                    { error
                      ? <View className="spreadWrapper">
                          <Text className="error">{error}</Text>
                        </View>
                      : <>
                          <View className="spreadWrapper">
                            <ElevatedView>
                              <Text style={{ textAlign: 'center' }}>
                                Noch keine Kontakte angelegt.
                              </Text>
                            </ElevatedView>
                          </View>
                          <ArrowButtonPlainHighlight
                            style={{ marginTop: 0, marginBottom: 12 }}
                            onPress={() => onNavigate({
                              ...messageData,
                              selectedContacts,
                            }, '/create-contact')}
                          >Neuen Empfänger erstellen</ArrowButtonPlainHighlight>
                        </>
                    }
                  </>
                }
              </>
          }

          <Popup
            visible={showPopup}
            title="Info - Empfänger zuweisen"
            onClose={() => this.setState({ showPopup: false })}
          >
            <Text>Lege hier fest, wer Deine Botschaft erhalten soll. Du kannst einen oder mehrere (maximal 5) Empfänger aus deinen Kontakten auswählen.</Text>
            <Text><span className="highlightBold">Wichtig:</span><br />Bitte überprüfe regelmäßig die E-Mail-Adresse und Mobilrufnummer Deiner Kontakte.</Text>
            <Text>Falls sich die Daten eines Kontaktes ändern, werden diese Änderungen automatisch in der Botschaft aktualisiert.</Text>
          </Popup>

          <BottomNavigation
            currentRoute="MessageReceiver"
            routes={[
              'MessageUpload', 'MessageTitle', 'MessageReceiver',
              'MessageDelivery', 'MessageSummary',
            ]}
            // onPress={(route: string) => this.props.navigation.navigate(route)}
          />
        </BackgroundView>
      </AppView>
    )
  }

  onContinue() {
    const { messageData, onNavigate } = this.props
    const selectedContacts = this.state.selectedContacts

    if ( selectedContacts && selectedContacts.length ) {
      this.setState({ error: null })
      onNavigate({
        ...messageData,
        selectedContacts,
      }, '/create-message/delivery')
    } else {
      this.setState({
        error: 'Bitte mindestens einen Empfänger auswählen.'
      })
    }
  }

  onSelectContact(contact: Object, isContactSelected: number) {
    const selectedContacts = this.state.selectedContacts

    if ( isContactSelected === -1 ) {
      if ( selectedContacts.length < MAX_SELECTABLE_CONTACTS ) {
        this.setState({
          selectedContacts: [
            ...selectedContacts,
            contact,
          ],
        })
      }
    } else {
      const newSelectedContacts = selectedContacts.filter((selectedContact) => {
        return selectedContact.id !== contact.id
      })
      this.setState({ selectedContacts: newSelectedContacts })
    }
  }
}
