/**
 * MessageExamples
 * @flow
 */
'use strict'

import React from 'react'

import { SERVER_URL } from '../../../helpers'
import { AppView, Button, Image, Preview, View } from '../../../components'
import Container from '@material-ui/core/Container'


type Props = {
  onNavigate: (messageData: ?Object, route: string) => void,
}

type State = {
  // ...
}


export default class MessageExamples extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)
  }

  render() {
    return (
      <AppView id="MessageExamples">
        <Container maxWidth="xs">
          <Image
            resizeMode="contain"
            source={require('../../../assets/img/tips.png')}
            className="tipsImage"
          />
          <View className="previewWrapper">
            <View className="previewRow">
              <Preview
                src={`${SERVER_URL}/assets/examples/example-media-5.mp4`}
                style={{ marginRight: 5, marginVertical: 5 }}
                type="video"
              />
              <Preview
                src={`${SERVER_URL}/assets/examples/example-media-6.mp4`}
                style={{ marginRight: 5, marginVertical: 5 }}
                type="video"
              />
            </View>
            <View className="previewRow">
              <Preview
                src={`${SERVER_URL}/assets/examples/example-media-7.mp4`}
                style={{ marginRight: 5, marginVertical: 5 }}
                type="video"
              />
              <Preview
                src={`${SERVER_URL}/assets/examples/example-media-1.jpg`}
                style={{ marginRight: 5, marginVertical: 5 }}
                type="image"
              />
            </View>
            <View className="previewRow">
              <Preview
                border={true}
                src={`${SERVER_URL}/assets/examples/example-media-9.jpg`}
                style={{ marginRight: 5, marginVertical: 5 }}
                type="image"
              />
              <Preview
                src={`${SERVER_URL}/assets/examples/example-media-10.pdf`}
                style={{ marginLeft: 5, marginVertical: 5 }}
                type="pdf"
              />
            </View>
          </View>
          <Button
            style={{ marginTop: 10, marginBottom: 30 }}
            onPress={() => this.props.onNavigate(null, '/create-message/upload')}
          >Zurück</Button>
        </Container>
      </AppView>
    )
  }
}
