/**
 * AppView
 * @flow
 */
'use strict'

import React from 'react'


type Props = {
  children: Object,
  style: Object,
}

type State = {
}


export default class ElevatedView extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
  }

  render() {
    const { className, style, children, ...props } = this.props

    return (
      <div className={`elevatedView ${className}`} style={style} {...props}>
        {children}
      </div>
    )
  }
}
