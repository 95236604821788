/**
 * PaymentStatus
 * @flow
 */
'use strict'

import React from 'react'

import { api } from '../../../helpers'
import { AppView, LoadingSpinner, View } from '../../../components'
import Container from '@material-ui/core/Container'


type Props = {
  location: Object,
  onNavigate: (orderData: ?Object, route: string) => void,
}

type State = {
  // ...
}


export default class PaymentStatus extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    // this.state = {}

    const PaymentStatus = (this: any)
    PaymentStatus.onGetPaymentStatus = this.onGetPaymentStatus.bind(this).onGetPaymentStatus = this.onGetPaymentStatus.bind(this)
  }

  componentDidMount() {
    const splittedUrl = this.props.location && this.props.location.search
      ? this.props.location.search.split('&resourcePath=')
      : null
    const encodedResourcePath = splittedUrl && splittedUrl.length && splittedUrl.length === 2
      ? splittedUrl[1]
      : null
    const decodedResourcePath = encodedResourcePath
      ? decodeURIComponent(encodedResourcePath)
      : null

    if ( decodedResourcePath ) {
      this.onGetPaymentStatus(decodedResourcePath)
    } else {
      this.props.onNavigate(null, '/order/packages')
    }
  }

  render() {
    return (
      <AppView
        id="PaymentStatus"
        background={2}
      >
        <Container maxWidth="xs">
          <View>
            <LoadingSpinner />
          </View>
        </Container>
      </AppView>
    )
  }

  onGetPaymentStatus(resourcePath: string, shoppingCart: Array<Object>) {
    const onNavigate = this.props.onNavigate

    api({
      url: 'paymentStatus',
      method: 'post',
      data: { resourcePath },
      onSuccess: (response) => {
        if ( response
          && response.data
          && response.data.status === 'ok'
        ) {
          onNavigate(null, '/order/success')
        } else if ( response
          && response.data
          && response.data.status === 'transaction-error'
        ) {
          onNavigate(null, '/order/packages', 'Die Zahlung konnte nicht durchgeführt werden. Bitte erneut versuchen.')
        } else {
          onNavigate(null, '/order/packages', 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.')
        }
      },
      onError: (error) => {
        onNavigate(null, '/order/packages', 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.')
      }
    })
  }
}
