/**
 * Contacts
 * @flow
 */
'use strict'

import React from 'react'
import { findIndex } from 'lodash'

import { BASE_URL, api } from '../../../helpers'
import {
  AppView, ArrowButtonGradient, BackgroundView, Colors, FlatList, ElevatedView,
  Icon, Image, LoadingSpinner, Popup, Text, TouchableOpacity, View,
} from '../../../components'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  error: ?string,
  isLoading: boolean,
  isSaving: boolean,
  showPopup: boolean,
  showVerifierAlert: boolean,
  // selected contact to be confirmed as verifier
  selectedContact: ?Object,

  contacts: Array<?Object>,
  isContactChooseMode: boolean,
}

const CONTACT_ITEM_HEIGHT = 40
const MAX_VERIFIERS = 2


export default class Contacts extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      isLoading: true,
      isSaving: false,
      showPopup: false,
      showVerifierAlert: false,
      selectedContact: null,

      contacts: [],
      isContactChooseMode: false,
    }

    const Contacts = (this: any)
    Contacts.onConfirmVerifier = this.onConfirmVerifier.bind(this)
    Contacts.onUpdateVerifier = this.onUpdateVerifier.bind(this)
  }

  componentDidMount() {
    api({
      url: 'userContacts',
      method: 'get',
      onSuccess: (response) => {
        // console.debug(response)
        if ( response
          && response.data
          && response.data.status === 'ok'
          && response.data.contacts != undefined
        ) {
          this.setState({
            contacts: response.data.contacts,
            error: null,
            isLoading: false,
          })
        } else {
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            isLoading: false,
          })
        }
      },
      onError: (error) => {
        // console.error(error.response)
        this.setState({
          error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
          isLoading: false,
        })
      }
    })
  }

  render() {
    const {
      error, isLoading, isSaving, showPopup, showVerifierAlert, selectedContact,
      contacts, isContactChooseMode,
    } = this.state

    const verifiers = contacts && contacts.length
      ? contacts.filter((contact) => !! contact.is_verifier)
      : null

    // two contacts have to be choosen as verifier
    const incompleteVerifiers = verifiers && verifiers.length < MAX_VERIFIERS

    return (
      <AppView id="Contacts">
        <BackgroundView hideLogo={true}>
          <Text className="welcomeTitle">Kontakte</Text>
          { isLoading ?
              <LoadingSpinner />
            : contacts && contacts.length ?
              <>
                { error
                  ? <Text className="error">{error}</Text>
                  : null
                }
                { ! isContactChooseMode
                  ? <ElevatedView style={{ padding: '10px 20px', marginBottom: 20 }}>
                      <View className="sectionWrapper">
                        <View className="sectionTitleWrapper">
                          <Text className="sectionTitleText">Meine Vertrauenspersonen</Text>
                          { isSaving
                            ? <Text className="savingInfo">Speichern...</Text>
                            : <Icon
                                src={require('../../../assets/img/info.png')}
                                style={{ height: 25, width: 25 }}
                                onPress={() => this.setState({ showPopup: true })}
                              />
                          }
                        </View>
                        <FlatList
                          data={ verifiers && verifiers.length ? verifiers : null }
                          renderItem={({ item, index }) => {
                            const {
                              id, firstname, lastname, roles, profile_picture,
                              verifier_request_at, verifier_request_accepted,
                            } = item

                            const status = verifier_request_accepted == undefined ?
                                <Text className="verifierStatus" style={{ color: '#B00500' }}>
                                  Antwort ausstehend
                                </Text>
                              : verifier_request_at && verifier_request_accepted === true ?
                                <Text className="verifierStatus" style={{ color: '#08167e' }}>
                                  Bestätigt
                                </Text>
                              : verifier_request_at && verifier_request_accepted === false ?
                                <Text className="verifierStatus" style={{ color: '#B00500' }}>
                                  Abgelehnt
                                </Text>
                              : null

                            return (
                              <View className="contactItem">
                                <View className="contactWrapper">
                                  <Image
                                    source={ profile_picture
                                      ? `${BASE_URL}/profilePicture/${profile_picture}`
                                      : require('../../../assets/img/profile-picture.jpg')
                                    }
                                    className="profilePicture"
                                  />
                                  <View>
                                    <Text className="contactName">{firstname} {lastname}</Text>
                                    { status ? status : null }
                                  </View>
                                </View>
                                <View>
                                  <Icon
                                    disabled={isSaving}
                                    src={require('../../../assets/img/minus.png')}
                                    style={{ height: 15, width: 15}}
                                    onPress={() => this.onConfirmVerifier(id, false)}
                                  />
                                </View>
                              </View>
                            )
                          }}
                          style={{ maxHeight: 95, width: '100%', marginTop: 10 }}
                        />
                        { incompleteVerifiers ?
                            <Text className="verifierText" style={{ color: Colors.error }}>
                              Bitte lege hier 2 Vertrauenspersonen<br />aus Deinen Kontakten fest
                            </Text>
                          : null
                        }
                        { verifiers && verifiers.length < MAX_VERIFIERS
                          ? <ArrowButtonGradient
                              style={{ marginBottom: 20 }}
                              onPress={() => this.setState({ isContactChooseMode: true })}
                            >VERTRAUENSPERSON ANLEGEN</ArrowButtonGradient>
                          : null
                        }
                      </View>
                    </ElevatedView>
                  : null
                }
                <ElevatedView style={{ padding: '10px 20px' }}>
                  <View className="sectionWrapper" style={{ flex: 1 }}>
                    { isContactChooseMode
                      ? <View className="sectionTitleWrapper">
                          <Text className="sectionTitleText">Kontakt auswählen</Text>
                          { isSaving
                            ? <Text className="savingInfo">Speichern...</Text>
                            : <Icon
                                disabled={isSaving}
                                src={require('../../../assets/img/close-white.png')}
                                style={{ height: 13, width: 13 }}
                                onPress={() => this.setState({ isContactChooseMode: false })}
                              />
                          }
                        </View>
                      : <View className="sectionTitleWrapper">
                          <Text className="sectionTitleText">Meine Kontakte</Text>
                        </View>
                    }
                    <FlatList
                      className="contactsList"
                      data={contacts}
                      renderItem={({ item, index }) => {
                        const {
                          id, firstname, lastname, profile_picture,
                          documents_count, messages_count,
                        } = item

                        // do not show contact that already is verifier
                        // when choosing verifier
                        const is_verifier = findIndex(verifiers, { id: id })
                        if ( is_verifier !== -1 && isContactChooseMode ) {
                          return null
                        }

                        const countsText = []
                        const messagesCountText = messages_count === 1 ?
                            `${messages_count} Botschaft`
                          : messages_count > 1 ?
                            `${messages_count} Botschaften`
                          : ''
                        if ( messagesCountText ) {
                          countsText.push(`${messagesCountText}`)
                        }
                        if ( documents_count === 1 ) {
                          countsText.push(`${documents_count} Tresorinhalt`)
                        } else if ( documents_count > 1 ) {
                          countsText.push(`${documents_count} Tresorinhalte`)
                        }

                        return (
                          <TouchableOpacity
                            disabled={isSaving}
                            onPress={() => {
                              if ( isContactChooseMode ) {
                                this.onConfirmVerifier(id, true)
                              } else {
                                this.props.history.push({
                                  pathname: '/contact',
                                  state: { contactId: id }
                                })
                              }
                            }}
                            className="contactItem"
                          >
                            <View className="contactWrapper">
                              <Image
                                source={ profile_picture
                                  ? `${BASE_URL}/profilePicture/${profile_picture}`
                                  : require('../../../assets/img/profile-picture.jpg')
                                }
                                className="profilePicture"
                              />
                              <View>
                                <Text className="contactName">{firstname} {lastname}</Text>
                                { countsText && countsText.length
                                  ? <Text className="contactCounts">
                                      { countsText.join(', ') }
                                    </Text>
                                  : null
                                }
                              </View>
                            </View>
                          </TouchableOpacity>
                        )
                      }}
                      style={{ width: '100%', marginTop: 10 }}
                    />
                  </View>
                </ElevatedView>
              </>
            : <>
                <ElevatedView>
                  <View className="wrapper">
                    { error
                      ? <Text className="error">{error}</Text>
                      : <Text>Noch keine Kontakte angelegt.</Text>
                    }
                  </View>
                </ElevatedView>
              </>
          }

          <ArrowButtonGradient
            style={{ marginTop: 20 }}
            onPress={() => this.props.history.push('/create-contact')}
          >NEUER KONTAKT</ArrowButtonGradient>

          <Popup
            visible={showPopup}
            title="Vertrauenspersonen - Info"
            subtitle={<Text style={{ margin: 0 }}>(siehe auch <a href="https://www.alm-app.eu/faqs#FAQ4" target="_blank">Website</a>)</Text>}
            onClose={() => this.setState({ showPopup: false })}
          >
            <Text>Um das After Life Message - System zu aktivieren und damit zu gewährleisten, dass der Versand Deiner Botschaften und Dokumente im traurigen Fall Deines Ablebens sichergestellt wird, ist es unbedingt notwendig, <span className="bold">zwei Vertrauenspersonen</span> aus Deinen Kontakten auszuwählen.</Text>
            <Text>Als Vertrauenspersonen sollten vorzugsweise engste Familienmitglieder oder Freunde gewählt werden, die bestenfalls schon im Vorfeld über ihre Aufgabe persönlich von Dir informiert wurden.</Text>
            <Text>Diese müssen ihre angedachte Rolle bestätigen, können diese aber auch ablehnen. Die Bereitschaft dafür wird in Form einer E-Mail-Nachricht abgefragt, welches die Vertrauenspersonen – nachdem Du sie angelegt hast - erhalten. In dieser Nachricht wird den Vertrauenspersonen auch mitgeteilt, dass <span className="bold">Du</span> die Rolle für sie vorgesehen hast.</Text>
            <Text><span className="highlightBold">WICHTIG:</span><br />Nur wenn 2 Vertrauenspersonen ihre Rolle "bestätigt" haben, wird das System aktiv (siehe Statusanzeige)!</Text>
          </Popup>

          <Popup
            visible={showVerifierAlert}
            title={ selectedContact && selectedContact.is_verifier ? 'Bist Du sicher?' : 'Wirklich löschen?' }
            onConfirm={() => this.onUpdateVerifier(selectedContact.id, selectedContact.is_verifier)}
            onClose={() => this.setState({ showVerifierAlert: false, selectedContact: null })}
          >
            <Text style={{ paddingRight: 35 }}>{ selectedContact && selectedContact.is_verifier
              ? 'Die ausgewählte Vertrauensperson wird jetzt per E-Mail-Nachricht verständigt.'
              : 'Die ausgewählte Person wird als Vertrauensperson entfernt.'
            }</Text>
          </Popup>
        </BackgroundView>
      </AppView>
    )
  }

  onConfirmVerifier(id: number, is_verifier: boolean) {
    this.setState({
      selectedContact: { id, is_verifier },
      showVerifierAlert: true,
    })
  }

  onUpdateVerifier(id: number, is_verifier: boolean) {
    if ( ! this.state.isSaving ) {
      this.setState({
        isSaving: true, showVerifierAlert: false, selectedContact: null,
      }, () => {
        api({
          url: 'updateContact',
          method: 'post',
          data: {
            contact_id: id,
            contact_is_verifier: is_verifier,
          },
          onSuccess: (response) => {
            // console.debug(response)
            if ( response
              && response.data
              && response.data.status === 'ok'
            ) {
              // show immediate feedback on client
              const updatedContacts = this.state.contacts.map((contact, index) => {
                if ( contact.id === id ) {
                  return {
                    ...contact,
                    // reset verifier request
                    verifier_request_at: null,
                    verifier_request_accepted: null,
                    is_verifier: is_verifier,
                  }
                }

                return contact
              })

              if ( this.state.isContactChooseMode ) {
                this.setState({
                  error: null,
                  isSaving: false,
                  contacts: updatedContacts,
                  isContactChooseMode: false,
                })
              } else {
                this.setState({
                  error: null,
                  isSaving: false,
                  contacts: updatedContacts,
                })
              }
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isSaving: false,
              })
            }
          },
          onError: (error) => {
            // console.error(error.response)
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
              isSaving: false,
            })
          }
        })
      })
    }
  }
}
