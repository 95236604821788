/**
 * ArrowButtonColored
 * @flow
 */
'use strict'

import React from 'react'

import { Colors, Image } from '.'
import { makeStyles } from '@material-ui/core/styles'
import { Button as MuiButton } from '@material-ui/core'

import nextWhite from '../assets/img/next-white.png'


type Icon = {
  src: number,
  style?: Object
}

type Props = {
  children: Object,
  className?: string,
  iconLeft?: Icon,
  iconRight?: ?Icon,
  onPress: () => void,
}

type State = {
  // ...
}


const useStyles = makeStyles({
  root: {
    fontSize: 13,
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: '#08167e',
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    height: 40,
    '&:hover': {
      backgroundColor: '#08167e',
      borderColor: '#08167e',
    },
  },
})


export default function ArrowButtonColored(props) {
  const {
    children, className, iconLeft, iconRight, onPress, ...other
  } = props
  const classes = useStyles()

  const iconRightSrc = iconRight && iconRight.src
    ? iconRight.src
    : nextWhite

  return (
    <MuiButton
      className={classes.root}
      color="primary"
      disableRipple={true}
      endIcon={ iconRightSrc
        ? <Image
            className="arrowButtonColoredEndIcon"
            source={iconRightSrc}
            style={ iconRight && iconRight.style ? iconRight.style : undefined }
          />
        : undefined
      }
      fullWidth={true}
      size="medium"
      startIcon={ iconLeft
        ? <Image
            className="arrowButtonColoredStartIcon"
            source={iconLeft.src}
            style={iconLeft.style}
          />
        : undefined
      }
      variant="contained"
      onClick={onPress}
      {...other}
    >{children}</MuiButton>
  )
}
