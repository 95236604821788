/**
 * ArrowButton
 * @flow
 */
'use strict'

import React from 'react'

import { Colors, Image } from '.'
import { makeStyles } from '@material-ui/core/styles'
import { Button as MuiButton } from '@material-ui/core'

import next from '../assets/img/next.png'


type Icon = {
  src: number,
  style?: Object
}

type Props = {
  children: Object,
  className?: string,
  iconLeft?: Icon,
  iconExtra?: Icon,
  iconRight?: ?Icon,
  onPress: () => void,
}

type State = {
  // ...
}


const useStyles = makeStyles({
  root: {
    fontSize: 13,
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: '#fff',
    color: '#808080',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    height: 55,
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: '#fff',
    },
  },
})


export default function ArrowButton(props) {
  const {
    children, className, iconLeft, iconExtra, iconRight, onPress, ...other
  } = props
  const classes = useStyles()

  const iconRightSrc = iconRight && iconRight.src
    ? iconRight.src
    : next

  return (
    <MuiButton
      className={ className
        ? [ classes.root, className ]
        : classes.root
      }
      color="primary"
      disableRipple={true}
      endIcon={ iconRightSrc
        ? <Image
            className="arrowButtonEndIcon"
            source={iconRightSrc}
            style={ iconRight && iconRight.style ? iconRight.style : undefined }
          />
        : undefined
      }
      fullWidth={true}
      size="medium"
      startIcon={ iconLeft
        ? <Image
            className="arrowButtonStartIcon"
            source={iconLeft.src}
            style={iconLeft.style}
          />
        : undefined
      }
      variant="contained"
      onClick={onPress}
      {...other}
    >
      {children}
      { iconExtra
        ? <Image
            className="arrowButtonExtraIcon"
            source={iconExtra.src}
          />
        : null
      }
    </MuiButton>
  )
}
