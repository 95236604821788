/**
 * SignupCredentials
 * @flow
 */
'use strict'

import React from 'react'

import { api } from '../../../helpers'
import {
  AppView, BackgroundView, Button, Colors, Checkbox, ElevatedView, Input, Popup, Text, View,
} from '../../../components'


type Props = {
  data: Object,
  onContinue: (data: Object) => void,
}

type State = {
  error: ?string,
  validationErrors: ?Object,
  isSaving: boolean,
  showAlert: boolean,

  email: ?string,
  phone: ?string,
  password: ?string,
  showPassword: boolean,
  terms_confirmed: boolean,
}


export default class SignupCredentials extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      validationErrors: null,
      isSaving: false,
      showAlert: false,

      email: null,
      phone: null,
      password: null,
      showPassword: false,
      terms_confirmed: false,
    }

    const SignupCredentials = (this: any)
    SignupCredentials.onConfirmData = this.onConfirmData.bind(this)
    SignupCredentials.onRegister = this.onRegister.bind(this)
  }

  render() {
    const {
      error, validationErrors, isSaving, showAlert,
      email, phone, password, showPassword, terms_confirmed,
    } = this.state

    return (
      <AppView
        id="SignupCredentials"
        hideMenu={true}
      >
        <BackgroundView>
          <Text className="welcomeTitle">Registrieren</Text>
          <ElevatedView>
            <Input
              error={ error && ! email }
              validationError={ validationErrors && validationErrors['email']
                ? validationErrors['email'][0]
                : null
              }
              type="email"
              placeholder="E-Mail*"
              value={email}
              onChangeText={(value: string) => this.setState({ email: value })}
            />
            <Input
              error={ error && ! phone }
              validationError={ validationErrors && validationErrors['phone']
                ? validationErrors['phone'][0]
                : null
              }
              type="text"
              infoText={ validationErrors && validationErrors['phone']
                ? null
                : 'Bitte mit internationaler Vorwahl +43, +44 oder +41 angeben: z.B. +43660xxx'
              }
              placeholder="Mobilrufnummer*"
              value={phone}
              onChangeText={(value: string) => this.setState({ phone: value })}
            />
            <Input
              error={ error && ! password }
              icon={ password && ! showPassword ?
                  {
                    src: require('../../../assets/img/show-password.png'),
                    style: { height: 18, width: 18, top: 16 },
                    onPress: () => this.setState({ showPassword: ! showPassword }),
                  }
                : password && showPassword ?
                  {
                    src: require('../../../assets/img/hide-password.png'),
                    style: { height: 18, width: 18, top: 16 },
                    onPress: () => this.setState({ showPassword: ! showPassword }),
                  }
                : undefined
              }
              validationError={ validationErrors && validationErrors['password']
                ? validationErrors['password'][0]
                : null
              }
              placeholder="Passwort (min. 8 Zeichen)"
              type={ ! showPassword ? 'password' : 'text' }
              value={password}
              onChangeText={(value: string) => this.setState({ password: value })}
            />

            <Checkbox
              className="gray"
              checked={terms_confirmed}
              onPress={() => this.setState({ terms_confirmed: ! terms_confirmed })}
            >
              <Text className={ error && ! terms_confirmed ? 'error checkboxText' : 'checkboxText' }>
                Ich habe die <a href="https://www.alm-app.eu/agb" target="_blank" className={ error && ! terms_confirmed ? 'error underline checkboxText' : 'checkboxText' }>Allgemeinen Geschäftsbedingungen (AGB)</a> gelesen&nbsp;und&nbsp;akzeptiert.
              </Text>
            </Checkbox>
          </ElevatedView>

          { error
            ? <Text className="error">{error}</Text>
            : null
          }

          <Button
            disabled={isSaving}
            type="gradient"
            onPress={this.onConfirmData}
          >Registrieren{ isSaving ? '...' : null }</Button>

          <Popup
            visible={showAlert}
            title="Stimmen die Daten?"
            onConfirm={this.onRegister}
            onClose={() => this.setState({ showAlert: false })}
          >
            <Text>Telefonnummer:<br />{phone}</Text>
            <Text>E-Mail-Adresse:<br />{email}</Text>
          </Popup>
        </BackgroundView>
      </AppView>
    )
  }

  onConfirmData() {
    const { phone, email, password, terms_confirmed } = this.state

    if ( phone && email && password && terms_confirmed ) {
      this.setState({ error: null, validationErrors: null, showAlert: true })
    } else {
      this.setState({ error: 'Bitte alle Felder ausfüllen.', showAlert: false })
    }
  }

  onRegister() {
    const { isSaving, phone, email, password, terms_confirmed } = this.state

    this.setState({ error: null, validationErrors: null, showAlert: false })

    if ( ! isSaving && phone && email && password && terms_confirmed ) {
      this.setState({ isSaving: true }, () => {
        const registerData = this.props.data.registerData

        api({
          url: 'register',
          method: 'post',
          data: { ...registerData, phone, email, password, terms_confirmed },
          onSuccess: (response) => {
            // console.debug(response)
            // console.debug(typeof response.data.has_phone_verification_code);
            if ( response
              && response.data
              && response.data.status === 'ok'
              && typeof response.data.has_phone_verification_code === 'boolean'
            ) {
              this.props.onContinue({
                email,
                has_phone_verification_code: response.data.has_phone_verification_code,
              })
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isSaving: false,
              })
            }
          },
          onError: (error) => {
            // console.debug(error.response)
            if ( error
              && error.response
              && error.response.status == 422
              && error.response.data
              && error.response.data.errors
            ) {
              const errorInputNames = []
              Object.keys(error.response.data.errors).map((key) => {
                errorInputNames.push(key)
              })
              this.setState({
                error: 'Bitte Eingaben überprüfen.',
                validationErrors: error.response.data.errors,
                isSaving: false,
              })
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isSaving: false,
              })
            }
          }
        })
      })
    } else {
      this.setState({ error: 'Bitte alle Felder ausfüllen.' })
    }
  }
}
