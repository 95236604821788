/**
 * ForgotPassword
 * @flow
 */
'use strict'

import React from 'react'

import { api } from '../../../helpers'
import {
  AppView, BackgroundView, Button, Colors, ElevatedView, Input, Text,
} from '../../../components'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  email: ?string,
  error: ?string,
  success: ?string,
  isLoading: boolean,
}


export default class ForgotPassword extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      email: null,
      error: null,
      success: null,
      isLoading: false,
    }

    const ForgotPassword = (this: any)
    ForgotPassword.onSendResetLinkEmail = this.onSendResetLinkEmail.bind(this)
  }

  render() {
    const { email, error, success, isLoading } = this.state

    return (
      <AppView
        id="ForgotPassword"
        hideMenu={true}
      >
        <BackgroundView>
          <Text className="welcomeTitle">Passwort vergessen</Text>
          <ElevatedView>
            <Text className="text">
              Bitte gib Deine E-Mail-Adresse ein, um Dein Passwort zurückzusetzen.
            </Text>
            <Input
              type="email"
              placeholder="E-Mail"
              value={email}
              onChangeText={(value: string) => this.setState({ email: value })}
            />
          </ElevatedView>
          { error
            ? <Text className="error">{error}</Text>
            : success
              ? <Text className="successText">{success}</Text>
              : null
          }
          <Button
            disabled={isLoading}
            type="gradient"
            containerStyle={{ marginTop: 'auto' }}
            onPress={this.onSendResetLinkEmail}
          >{ isLoading ? 'Bitte warten...' : 'Passwort vergessen' }</Button>
        </BackgroundView>
      </AppView>
    )
  }

  onSendResetLinkEmail() {
    const { email, isLoading } = this.state

    if ( ! isLoading && email ) {
      this.setState({ error: null, success: null, isLoading: true }, () => {
        api({
          url: 'password/email',
          method: 'post',
          data: { email },
          onSuccess: (response) => {
            // console.debug(response)
            if ( response
              && response.data
              && response.data.status === 'ok'
            ) {
              this.setState({
                error: null,
                success: 'Eine E-Mail mit den Anweisungen zum Zurückzusetzen des Passworts ist auf dem Weg, wenn diese E-Mail-Adresse bei uns registriert ist.',
                isLoading: false,
              })
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                success: null,
                isLoading: false,
              })
            }
          },
          onError: (error) => {
            // console.error(error.response)
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
              success: null,
              isLoading: false,
            })
          }
        })
      })
    } else {
      this.setState({
        error: 'Bitte alle Felder ausfüllen.',
        success: null,
        isLoading: false,
      })
    }
  }
}
