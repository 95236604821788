/**
 * Popup
 * @flow
 */
'use strict'

import React from 'react'

import { Button, Icon, View } from '.'

import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'


type Props = {
  children: Object,
  title?: string,
  subtitle?: any,
  visible: boolean,
  onConfirm?: () => void,
  onClose: () => void,
}

type State = {
  // ...
}


export default class Popup extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
  }

  render() {
    const {
      children, title, subtitle, visible, onConfirm, onClose, ...other
    } = this.props

    return (
      <Dialog
        className="Popup"
        disableTypography
        fullWidth={onConfirm}
        PaperProps={ onConfirm ? { style: { maxWidth: 380 } } : undefined }
        open={visible}
        onClose={onClose}
        {...other}
      >
        { title
          ? <DialogTitle
              className="PopupTitle highlight"
              style={ subtitle ? { paddingBottom: 0 } : undefined }
              onClose={onClose}
            >
              {title}
              { ! onConfirm
                ? <Icon
                    src={require('../assets/img/close.png')}
                    style={{ height: 18, width: 18 }}
                    onPress={onClose}
                  />
                : null
              }
            </DialogTitle>
          : null
        }
        { subtitle
          ? <View style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 16 }}>
              {subtitle}
            </View>
          : null
        }
        <DialogContent dividers>
          {children}
        </DialogContent>
        { onConfirm
          ? <DialogActions>
              <Button
                fullWidth={false}
                type="outlined"
                style={{ marginTop: 0, marginBottom: 0 }}
                onClick={onClose}
              >Nein</Button>
              <Button
                className="plain"
                fullWidth={false}
                type="gradient"
                style={{ marginTop: 0, marginBottom: 0 }}
                onClick={onConfirm}
                autoFocus
              >Ja</Button>
            </DialogActions>
          : null
        }
      </Dialog>
    )
  }
}
