import {
  ADD_DOCUMENT_DATA,
  REMOVE_DOCUMENT_DATA,
  ADD_MESSAGE_DATA,
  REMOVE_MESSAGE_DATA,
  ADD_ORDER_DATA,
  REMOVE_ORDER_DATA,
} from './actionTypes'

export const addDocumentData = (documentData) => ({
  type: ADD_DOCUMENT_DATA,
  payload: { documentData },
})

export const removeDocumentData = (documentData) => ({
  type: REMOVE_DOCUMENT_DATA,
  payload: { documentData },
})

export const addMessageData = (messageData) => ({
  type: ADD_MESSAGE_DATA,
  payload: { messageData },
})

export const removeMessageData = (messageData) => ({
  type: REMOVE_MESSAGE_DATA,
  payload: { messageData },
})

export const addOrderData = (orderData) => ({
  type: ADD_ORDER_DATA,
  payload: { orderData },
})

export const removeOrderData = (orderData) => ({
  type: REMOVE_ORDER_DATA,
  payload: { orderData },
})
