/**
 * Signup
 * @flow
 */
'use strict'

import React from 'react'

import { SignupData, SignupCredentials, SignupInfo } from '.'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  data: Object,
  step: 0 | 1 | 2,
}


export default class Signup extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    const data = props.location.state
      ? props.location.state
      : {}
    const currentStep = data && data.step != undefined
      ? data.step
      : 0

    this.state = {
      data: data,
      step: currentStep,
    }

    const Signup = (this: any)
    Signup.onContinue = this.onContinue.bind(this)
    Signup.onNavigate = this.onNavigate.bind(this)
  }

  render() {
    const { data, step } = this.state

    return (
      step === 0 ?
        <SignupData
          data={data}
          onContinue={this.onContinue}
        />
      : step === 1 ?
        <SignupCredentials
          data={data}
          onContinue={this.onContinue}
        />
      : step === 2 ?
        <SignupInfo
          data={data}
          onContinue={this.onContinue}
          onNavigate={this.onNavigate}
        />
      : null
    )
  }

  onContinue(data: Object) {
    this.setState({
      data: data,
      step: this.state.step + 1,
    })
  }

  onNavigate(path: string) {
    this.props.history.push(path)
  }
}
