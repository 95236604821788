/**
 * AuthLoading
 * @flow
 */
'use strict'

import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { api } from '../../helpers'


type Props = {
  children: Object,
}

type State = {
  isLoading: boolean,
  hasValidApiToken: boolean,
}


export default class AuthLoading extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      isLoading: true,
      hasValidApiToken: false,
    }

    const AuthLoading = (this: any)
    AuthLoading.onCheckValidApiToken = this.onCheckValidApiToken.bind(this)
  }

  componentDidMount() {
    this.onCheckValidApiToken();
  }

  render() {
    const { children, ...other } = this.props
    const { isLoading, hasValidApiToken } = this.state

    return (
      <Route
        {...other}
        children={(props) => (
          hasValidApiToken
          ? React.cloneElement(children, props)
          : ( isLoading
              ? null
              : <Redirect to={{ pathname: '/login' }} />
            )
        )}
      />
    )
  }

  onCheckValidApiToken() {
    api({
      url: 'checkValidApiToken',
      method: 'get',
      onSuccess: (response) => {
        // console.debug(response)
        if ( response
          && response.data
          && response.data.status === 'ok'
        ) {
          this.setState({
            isLoading: false,
            hasValidApiToken: true,
          })
        } else {
          this.setState({
            isLoading: false,
            hasValidApiToken: false,
          })
        }
      },
      onError: (error) => {
        // console.error(error.response)
        this.setState({
          isLoading: false,
          hasValidApiToken: false,
        })
      }
    })
  }
}
