/**
 * CreateNewDocument
 * @flow
 */
'use strict'

import React from 'react'
import { connect } from 'react-redux'
import { addDocumentData } from '../../../redux/actions'
import mime from 'mime-types'

import { BASE_URL, api } from '../../../helpers'
import {
  AppView, ActionButton, ArrowButtonPlainHighlight, BackgroundView, Button, Checkbox, Colors,
  FlatList, ElevatedView, Icon, Input, Image, LoadingSpinner, LoadingOverlay, Text, TouchableOpacity,
  Popup, View,
} from '../../../components'


type Props = {
  history: Object,
  location: Object,
  match: Object,
  documentData: ?Object,
}

type State = {
  error: ?string,
  validationErrors: ?Object,
  isLoading: boolean,
  isSaving: boolean,
  showDocumentPopup: boolean,
  showInfoPopup: boolean,
  showLoadingOverlay: boolean,

  title: ?string,
  selectedContacts: Array<?Object>,
  uploaded_file: ?Object,
  uploaded_file_name: ?string,
  terms_confirmed: boolean,

  contacts: Array<?Object>,
  isContactChooseMode: boolean,
}

const CONTACT_ITEM_HEIGHT = 40
const MAX_FILE_SIZE = 50000000 // bytes = 50MB
const MAX_SELECTABLE_CONTACTS = 5


class CreateNewDocument extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    const documentData = props.documentData

    this.state = {
      error: null,
      validationErrors: null,
      isLoading: true,
      isSaving: false,
      showDocumentPopup: false,
      showInfoPopup: false,
      showLoadingOverlay: false,

      title: documentData && documentData.title
        ? documentData.title
        : null,
      selectedContacts: documentData && documentData.selectedContacts
        ? documentData.selectedContacts
        : [],
      uploaded_file: documentData && documentData.uploaded_file
        ? documentData.uploaded_file
        : null,
      uploaded_file_name: documentData && documentData.uploaded_file_name
        ? documentData.uploaded_file_name
        : null,
      terms_confirmed: false,

      contacts: [],
      isContactChooseMode: documentData && documentData.isContactChooseMode && documentData.isContactChooseMode === true
        ? true
        : false,
    }

    const CreateNewDocument = (this: any)
    CreateNewDocument.onChooseFile = this.onChooseFile.bind(this)
    CreateNewDocument.onSave = this.onSave.bind(this)
    CreateNewDocument.onSelectContact = this.onSelectContact.bind(this)
    CreateNewDocument.onShowDocumentPicker = this.onShowDocumentPicker.bind(this)
  }

  componentDidMount() {
    api({
      url: 'userContacts',
      method: 'get',
      onSuccess: (response) => {
        // console.debug(response)
        if ( response
          && response.data
          && response.data.status === 'ok'
          && response.data.contacts != undefined
        ) {
          this.setState({
            contacts: response.data.contacts,
            error: null,
            isLoading: false,
          })
        } else {
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            isLoading: false,
          })
        }
      },
      onError: (error) => {
        // console.error(error.response)
        this.setState({
          error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
          isLoading: false,
        })
      }
    })
  }

  render() {
    const {
      error, validationErrors, isLoading, isSaving,
      showDocumentPopup, showInfoPopup, showLoadingOverlay,
      title, selectedContacts, uploaded_file, uploaded_file_name,
      terms_confirmed, contacts, isContactChooseMode,
    } = this.state

    return (
      <AppView id="CreateNewDocument">
        <BackgroundView>
          <Text className="welcomeTitle">Neuer Tresorinhalt</Text>
          { isLoading ?
              <LoadingSpinner />
            : isContactChooseMode ?
              <View className="sectionWrapper" style={{ flex: 1 }}>
                <ElevatedView>
                  <View className={ selectedContacts && selectedContacts.length
                    ? 'sectionTitleWrapperPrimary sectionTitleWrapper'
                    : 'sectionTitleWrapper'
                  }>
                    <Text
                      className="sectionTitleText"
                      style={{
                        color: selectedContacts && selectedContacts.length ? '#fff' : '#08167e',
                      }}
                    >Empfänger zuweisen</Text>
                    { selectedContacts && selectedContacts.length
                      ? <TouchableOpacity
                          onPress={() => this.setState({ isContactChooseMode: false })}
                        >
                          <Text className="savingInfo">Speichern</Text>
                        </TouchableOpacity>
                      : <Icon
                          src={require('../../../assets/img/close.png')}
                          style={{ height: 13, width: 13 }}
                          onPress={() => this.setState({ isContactChooseMode: false })}
                        />
                    }
                  </View>
                  { contacts && contacts.length
                    ? <FlatList
                        data={contacts}
                        renderItem={({ item, index }) => {
                          const {
                            id, firstname, lastname,
                            documents_count, messages_count, is_verifier,
                            profile_picture,
                          } = item

                          const roleNames = []
                          if ( messages_count ) roleNames.push('Empfänger')
                          if ( is_verifier ) roleNames.push('Vertrauensperson')
                          if ( documents_count ) roleNames.push('Tresorzugriff')

                          const isContactSelected = (
                            selectedContacts.findIndex((selectedContact) => {
                              return selectedContact.id === id
                            })
                          )

                          return (
                            <View className="contactItem">
                              <TouchableOpacity
                                onPress={() => this.onSelectContact({ id, firstname, lastname }, isContactSelected)}
                              >
                                <View className="contactWrapper">
                                  <Image
                                    source={ profile_picture
                                      ? `${BASE_URL}/profilePicture/${profile_picture}`
                                      : require('../../../assets/img/profile-picture.jpg')
                                    }
                                    className="profilePicture"
                                  />
                                  <View>
                                    <Text className="contactName">{firstname} {lastname}</Text>
                                    { roleNames.length
                                      ? <Text className="contactRoles">{ roleNames.join(' · ') }</Text>
                                      : null
                                    }
                                  </View>
                                </View>
                              </TouchableOpacity>
                              <View>
                                <Checkbox
                                  checked={ isContactSelected !== -1 }
                                  onPress={() => this.onSelectContact({ id, firstname, lastname }, isContactSelected)}
                                />
                              </View>
                            </View>
                          )
                        }}
                        style={{ maxHeight: 340, width: '100%', marginTop: 10 }}
                      />
                    : <Text className="noContactsText">Noch keine Kontakte vorhanden.</Text>
                  }
                </ElevatedView>
                <ArrowButtonPlainHighlight
                  style={{ marginTop: 20 }}
                  onPress={() => {
                    this.props.addDocumentData({
                      ...this.props.documentData,
                      title, selectedContacts,
                      uploaded_file, uploaded_file_name,
                      isContactChooseMode,
                    })
                    this.props.history.push('/create-contact')
                  }}
                >Neuen Empfänger erstellen</ArrowButtonPlainHighlight>
              </View>
            : <>
                <ElevatedView>
                  <Text
                    className="infoText"
                  >Benenne das Dokument, weise es Personen aus Deinen Kontakten zu und lade das gewünschte Dokument hoch.</Text>

                  <Input
                    error={ error && ! title }
                    validationError={ validationErrors && validationErrors['title']
                      ? validationErrors['title'][0]
                      : null
                    }
                    placeholder="Dokument benennen"
                    maxLength={50}
                    className="field"
                    value={title}
                    onChangeText={(value: string) => this.setState({ title: value })}
                  />
                  <ActionButton
                    error={ error && selectedContacts && ! selectedContacts.length }
                    validationError={ validationErrors && validationErrors['contacts']
                      ? validationErrors['contacts'][0]
                      : null
                    }
                    className="field"
                    textStyle={ selectedContacts && selectedContacts.length
                      ? { color: Colors.primary }
                      : undefined
                    }
                    onPress={() => {
                      this.setState({ isContactChooseMode: true })
                    }}
                  >
                    { selectedContacts && selectedContacts.length
                      ? selectedContacts.map((selectedContact, index) => {
                          const { firstname, lastname } = selectedContact
                          const selectedContactsLength = selectedContacts.length
                          const isLastItem = index === selectedContactsLength - 1

                          return (
                            ! isLastItem
                            ? `${firstname} ${lastname}, `
                            : `${firstname} ${lastname}`
                          )
                        })
                      : 'Empfänger zuweisen'
                    }
                  </ActionButton>
                  <ActionButton
                    error={ error && ! uploaded_file }
                    validationError={ validationErrors && validationErrors['uploaded_file']
                      ? validationErrors['uploaded_file'][0]
                      : null
                    }
                    className="field"
                    textStyle={ uploaded_file
                      ? { color: Colors.primary }
                      : undefined
                    }
                    onPress={this.onShowDocumentPicker}
                  >{ uploaded_file && uploaded_file_name
                    ? uploaded_file_name
                    : uploaded_file && ! uploaded_file_name
                    ? 'Dokument ausgewählt'
                    : 'Dokument hochladen'
                  }</ActionButton>
                  <input
                    ref={(input) => this.DocumentPicker = input}
                    id="DocumentPicker"
                    type="file"
                    accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .rtf"
                    onChange={this.onChooseFile}
                  />

                  <Text
                    className="documentInfo"
                    onClick={() => this.setState({ showDocumentPopup: true })}
                  >Welche Dateiformate sind möglich?</Text>

                  <View className="termsWrapper">
                    <Checkbox
                      checked={terms_confirmed}
                      className="termsCheckbox"
                      onPress={() => this.setState({ terms_confirmed : ! terms_confirmed })}
                    >
                      <Text className={ error && ! terms_confirmed
                        ? 'termsErrorText termsText small'
                        : 'termsText small'
                      }>
                        Ich bin damit einverstanden, dass die angegebenen Empfänger nach meinem Ableben Zugriff auf dieses Dokument erhalten.
                      </Text>
                    </Checkbox>
                    <Icon
                      src={require('../../../assets/img/info.png')}
                      style={{ height: 29, width: 29 }}
                      onPress={() => this.setState({ showInfoPopup: true })}
                    />
                  </View>
                </ElevatedView>

                { error
                  ? <Text className="error">{error}</Text>
                  : null
                }

                <View style={{ marginTop: 'auto' }}>
                  <Button
                    disabled={isSaving}
                    type="gradient"
                    style={{ marginBottom: 0 }}
                    onPress={this.onSave}
                  >Speichern{ isSaving ? '...' : null }</Button>
                  <Button
                    type="outlined"
                    onPress={() => {
                      this.props.addDocumentData({
                        ...this.props.documentData,
                        title, selectedContacts,
                        uploaded_file, uploaded_file_name,
                        isContactChooseMode,
                      })
                      this.props.history.push('/credit')
                    }}
                  >Guthabenstand</Button>
                </View>

                <Popup
                  visible={showInfoPopup}
                  title="Info - Tresorinhalt Speichern"
                  onClose={() => this.setState({ showInfoPopup: false })}
                >
                  <Text>Die Empfänger dieses Tresorinhalts erhalten nach dem „Speichern“ von „service@alm-app.eu“ eine kurze, anonyme Information an die angegebene E-Mail-Adresse mit folgendem Inhalt:</Text>
                  <Text style={{ margin: 0 }}>--------------------</Text>
                  <View className="italic">
                    <Text style={{ fontSize: 12 }}>Sehr geehrte(r) …,</Text>
                    <Text style={{ fontSize: 12 }}>ein Anwender von <a href="https://www.alm-app.eu" target="_blank">After Life Message</a> hat ein Dokument gespeichert, auf welches Sie nach seinem Ableben Zugriff erhalten.</Text>
                    <Text style={{ fontSize: 12 }}>Mit freundlichen Grüßen,<br />Ihr Team von After Life Message<br /><a href="https://www.alm-app.eu" target="_blank">www.alm-app.eu</a></Text>
                  </View>
                  <Text style={{ margin: 0 }}>--------------------</Text>
                  <Text>Erst im traurigen Fall Deines Ablebens, welches von Deinen beiden Vertrauenspersonen bestätigt wurde, erhalten die Empfänger Zugriff auf dieses Dokument. Die Empfänger erhalten bis zu Deinem bestätigten Ableben keine weiteren Informationen.</Text>
                </Popup>

                <Popup
                  visible={showDocumentPopup}
                  title="Info - Dokument hochladen"
                  onClose={() => this.setState({ showDocumentPopup: false })}
                >
                  <Text>Hier hast Du die Möglichkeit, Dateien, die auf Deinem Gerät oder in Deiner Cloud gespeichert sind, hochzuladen.</Text>
                  <Text>
                    <span className="highlightBold">Welche Dateiformate sind möglich?</span><br />
                    pdf, doc(x), ppt(x), xls(x), txt, rtf
                  </Text>
                  <Text>Bitte beachte, dass die Dateigröße 50 MB nicht überschreiten darf.</Text>
                </Popup>

                { showLoadingOverlay
                  ? <LoadingOverlay
                      onClose={() => this.setState({ showLoadingOverlay: false })}
                    >
                      <Text className="highlight" style={{ textAlign: 'center', marginTop: 20 }}>
                        Die Datei wird gerade hochgeladen, dies kann je nach Größe einige Zeit in Anspruch nehmen.<br />
                        Bitte die Anwendung in der Zwischenzeit nicht schließen.
                      </Text>
                    </LoadingOverlay>
                  : null
                }
              </>
          }
        </BackgroundView>
      </AppView>
    )
  }

  onChooseFile(event) {
    const file = event.target.files[0]

    this.setState({ isLoading: true }, () => {
      if ( file ) {
        if ( file.size && file.size > MAX_FILE_SIZE ) {
          this.setState({
            error: 'Die ausgewählte Datei ist zu groß.',
            isLoading: false,
            uploaded_file: null,
            uploaded_file_name: null,
          })
        } else {
          const reader = new FileReader()

          reader.onload = (e) => {
            const mimeType = mime.lookup(file.name)

            if ( mimeType && mimeType === 'application/pdf'
              || mimeType && mimeType === 'application/msword' // doc
              || mimeType && mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // docx
              || mimeType && mimeType === 'application/vnd.ms-excel' // xls
              || mimeType && mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // xlsx
              || mimeType && mimeType === 'application/vnd.ms-powerpoint' // ppt
              || mimeType && mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' // pptx
              || mimeType && mimeType === 'text/plain' // txt
              || mimeType && mimeType === 'text/rtf' // rtf
              || mimeType && mimeType === 'application/rtf' // rtf
            ) {
              const source = {
                uri: file,
                // display the file temporarily
                tmp: e.target.result,
                type: mimeType === 'application/pdf'
                  ? 'pdf'
                  : 'document',
                mime: mimeType,
              }
              this.setState({
                error: null,
                isLoading: false,
                uploaded_file: source,
                uploaded_file_name: file.name,
              })
            } else {
              this.setState({
                error: 'Das Dateiformat ist nicht erlaubt.',
                isLoading: false,
                uploaded_file: null,
                uploaded_file_name: null,
              })
            }
          }

          reader.onerror = (e) => {
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
              isLoading: false,
              uploaded_file: null,
              uploaded_file_name: null,
            })
          }

          reader.onabort = (e) => {
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
              isLoading: false,
              uploaded_file: null,
              uploaded_file_name: null,
            })
          }

          reader.readAsDataURL(file)
        }
      } else {
        this.setState({ isLoading: false })
      }
    })
  }

  onSave() {
    const {
      isSaving, title, selectedContacts, uploaded_file, terms_confirmed,
    } = this.state

    if ( ! isSaving
      && title
      && selectedContacts
      && selectedContacts.length
      && uploaded_file
      && terms_confirmed
    ) {
      this.setState({ isSaving: true, showLoadingOverlay: true }, () => {
        const selectedContactIds = selectedContacts.map(
          (selectedContact) => selectedContact.id
        )

        let formData = new FormData()

        // uploaded_file.uri contains the file from file chooser
        // we dont need to add type or name property
        formData.append('uploaded_file', uploaded_file.uri)

        const formDataSerialized = JSON.stringify({
          title: title,
          contacts: selectedContactIds,
          terms_confirmed: terms_confirmed,
          w: true,
        })

        formData.append('formDataSerialized', formDataSerialized)

        api({
          url: 'createDocument',
          method: 'post',
          data: formData,
          formData: true,
          // headers: { 'Content-Type': 'multipart/form-data' },
          onSuccess: (response) => {
            // console.debug(response)
            if ( response
              && response.data
              && response.data.status === 'ok'
            ) {
              this.setState({
                error: null,
                validationErrors: null,
                // isSaving stays true so that the button stays disabled
                // isSaving: false,
                showLoadingOverlay: false,
              })
              this.props.history.push('/document-success')
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isSaving: false,
                showLoadingOverlay: false,
              })
            }
          },
          onError: (error) => {
            // console.error(error.response)
            if ( error
              && error.response
              && error.response.status == 422
              && error.response.data
              && error.response.data.errors
            ) {
              const errorInputNames = []
              Object.keys(error.response.data.errors).map((key) => {
                errorInputNames.push(key)
              })
              this.setState({
                error: 'Bitte Eingaben überprüfen.',
                validationErrors: error.response.data.errors,
                isSaving: false,
                showLoadingOverlay: false,
              })
            } else if ( error
              && error.response
              && error.response.status == 403
            ) {
              this.setState({
                error: 'Es ist nicht genügend Guthaben vorhanden, bitte ein neues Datenpaket erwerben.',
                validationErrors: null,
                isSaving: false,
                showLoadingOverlay: false,
              })
            } else if ( error
              && error.response
              && error.response.status == 413
            ) {
              this.setState({
                error: 'Die hochgeladene Datei ist zu groß.',
                validationErrors: null,
                isSaving: false,
                showLoadingOverlay: false,
              })
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isSaving: false,
                showLoadingOverlay: false,
              })
            }
          }
        })
      })
    } else {
      this.setState({ error: 'Bitte alle Felder ausfüllen.' })
    }
  }

  onSelectContact(contact: Object, isContactSelected: number) {
    const selectedContacts = this.state.selectedContacts

    if ( isContactSelected === -1 ) {
      if ( selectedContacts.length < MAX_SELECTABLE_CONTACTS ) {
        this.setState({
          selectedContacts: [
            ...selectedContacts,
            contact,
          ],
        })
      }
    } else {
      const newSelectedContacts = selectedContacts.filter((selectedContact) => {
        return selectedContact.id !== contact.id
      })
      this.setState({ selectedContacts: newSelectedContacts })
    }
  }

  onShowDocumentPicker() {
    this.DocumentPicker.click()
  }
}


const mapStateToProps = (state) => ({
  documentData: state.data.documentData
})
const mapDispatchToProps = (dispatch) => ({
  addDocumentData: (documentData) => dispatch(addDocumentData(documentData))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewDocument)
