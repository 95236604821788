/**
 * MessageTitle
 * @flow
 */
'use strict'

import React from 'react'

import {
  AppView, BackgroundView, BottomNavigation, Button, Content, ElevatedView,
  Icon, Input, Text, View,
} from '../../../components'
import Container from '@material-ui/core/Container'


type Props = {
  messageData: ?Object,
  onNavigate: (messageData: ?Object, route: string) => void,
}

type State = {
  error: ?string,
  title: ?string,
}


export default class MessageTitle extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    // redirect if required params are not available
    if ( ! props.messageData
      || ( ! props.messageData.uploaded_file && ! props.messageData.uploaded_text )
    ) {
      props.onNavigate(null, '/create-message/upload')
    }

    this.state = {
      error: null,
      title: props.messageData && props.messageData.title
        ? props.messageData.title
        : null,
    }

    const MessageTitle = (this: any)
    MessageTitle.onContinue = this.onContinue.bind(this)
  }

  render() {
    const { error, title } = this.state

    return (
      <AppView id="MessageTitle">
        <BackgroundView>
          <Text className="welcomeTitle">Botschaft benennen</Text>
          <ElevatedView>
            <View>
              <Content>
                <Text className="pagination">Schritt 2 von 5</Text>
                <Text className="highlight">Botschaft benennen</Text>
                <Text>
                  Gib deiner Botschaft einen aussagekräftigen Titel, damit du diese später leicht wieder findest (z.B. „Für Klara zum 40. Geburtstag“)
                </Text>
              </Content>
            </View>

            <View>
              <Input
                error={error}
                maxLength={50}
                placeholder="Titel eingeben"
                style={{ fontWeight: 400, marginTop: 20 }}
                value={title}
                onChangeText={(value: string) => this.setState({ title: value })}
              />
              <Text className="infoGrey" style={{ textAlign: 'left' }}>Dieser Titel wird nicht mit der Botschaft an die Empfänger übermittelt, er dient Dir lediglich als Information.</Text>
            </View>
          </ElevatedView>

          <Button
            containerStyle={{ marginTop: 'auto' }}
            style={{ marginTop: 20 }}
            type="gradient"
            onPress={this.onContinue}
          >Weiter</Button>

          <BottomNavigation
            currentRoute="MessageTitle"
            routes={[
              'MessageUpload', 'MessageTitle', 'MessageReceiver',
              'MessageDelivery', 'MessageSummary',
            ]}
            // onPress={(route: string) => this.props.navigation.navigate(route)}
          />
        </BackgroundView>
      </AppView>
    )
  }

  onContinue() {
    const { messageData, onNavigate } = this.props
    const title = this.state.title

    if ( title ) {
      this.setState({ error: null })
      onNavigate({
        ...messageData,
        title,
      }, '/create-message/receiver')
    } else {
      this.setState({ error: 'Bitte Titel eingeben.' })
    }
  }
}
