/**
 * ClientDetails
 * @flow
 */
'use strict'

import React from 'react'
import moment from 'moment'

import { api } from '../../helpers'

import {
  AppView, Button, LoadingSpinner, Popup, Text, View,
} from '../../components'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  error: ?string,
  isLoading: boolean,
  verifierSentMailError: ?string,
  verifierSentMailCount: ?number,
  showConfirmSendVerifierAliveRequest: boolean,

  client: ?Object,
  client_id: ?number,
}


export default class ClientDetails extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      isLoading: true,
      verifierSentMailError: null,
      verifierSentMailCount: null,
      showConfirmSendVerifierAliveRequest: false,

      client: null,
      client_id: props.history && props.history.location && props.history.location.state && props.history.location.state.id
        ? props.history.location.state.id
        : null
    }

    const ClientDetails = (this: any)
    ClientDetails.onSendVerifierAliveRequest = this.onSendVerifierAliveRequest.bind(this)
  }

  componentDidMount() {
    const client_id = this.state.client_id

    if ( client_id ) {
      api({
        url: 'client',
        method: 'post',
        data: { client_id },
        onSuccess: (response) => {
          // console.debug(response)
          if ( response
            && response.data
            && response.data.status === 'ok'
            && response.data.client
          ) {
            this.setState({
              error: null,
              isLoading: false,
              verifierSentMailCount: null,
              verifierSentMailError: null,
              client: response.data.client,
            })
          } else {
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
              isLoading: false,
              verifierSentMailCount: null,
              verifierSentMailError: null,
            })
          }
        },
        onError: (error) => {
          // console.error(error.response)
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            isLoading: false,
            verifierSentMailCount: null,
            verifierSentMailError: null,
          })
        }
      })
    } else {
      this.setState({
        error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
        isLoading: false,
        verifierSentMailCount: null,
        verifierSentMailError: null,
      })
    }
  }

  render() {
    const {
      error, isLoading, verifierSentMailCount, verifierSentMailError,
      showConfirmSendVerifierAliveRequest, client,
    } = this.state

    let ordersCount1 = 0
    let ordersCount2 = 0
    let ordersCount3 = 0
    if ( client && client.orders && client.orders.length ) {
      client.orders.forEach((order, index) => {
        const parsedShoppingCart = JSON.parse(order.shopping_cart)
        parsedShoppingCart.forEach((item, index) => {
          const itemId = Number(item.id)
          const itemAmount = Number(item.amount)
          if ( itemId === 1 ) {
            ordersCount1 += itemAmount
          } else if ( itemId === 2 ) {
            ordersCount2 += itemAmount
          } else if ( itemId === 3 ) {
            ordersCount3 += itemAmount
          }
        })
      })
    }

    return (
      <AppView
        id="ClientDetails"
        hideLogo={true}
      >
        <Container maxWidth="sm">
          <Text className="welcomeTitle">Benutzerdetails</Text>
          { isLoading ?
              <LoadingSpinner />
            : error ?
              <Text className="error">{error}</Text>
            : <View>
                <Paper className="detailsWrapper">
                  <View>
                    <Text>Anrede: { client.salutation && client.salutation === 1 ? 'Frau' : client.salutation && client.salutation === 2 ? 'Herr' : '–' }</Text>
                    <Text>Vorname: {client.firstname}</Text>
                    <Text>Nachname: {client.lastname}</Text>
                    <Text>Geburtsdatum: { moment(client.birthdate).format('DD.MM.YYYY') }</Text>
                    <Text>E-Mail: {client.email}</Text>
                  </View>
                  <View>
                    <Text>Adresse: { client.address ? client.address : '–' }</Text>
                    <Text>PLZ: { client.zipcode ? client.zipcode : '–' }</Text>
                    <Text>Ort: { client.city ? client.city : '–' }</Text>
                    <Text>Land: {client.country}</Text>
                    <Text>Telefon: {client.phone}</Text>
                  </View>
                </Paper>
                <View className="infosWrapper">
                  <View className="infosRow">
                    <Text className="highlightTitle">Botschaften</Text>
                    <Text>Erstellt: {client.messages_count}</Text>
                    <Text>Guthaben: {client.credit_messages}</Text>
                    <Text className="highlightTitle">Erworbene Pakete</Text>
                    <Text>Paket 1: {ordersCount1}x</Text>
                    <Text>Paket 2: {ordersCount2}x</Text>
                    <Text>Paket 3: {ordersCount3}x</Text>
                    <Text className="highlightTitle">Nachricht an Verifizierer</Text>
                    <Button
                      fullWidth={false}
                      onPress={() => this.setState({ showConfirmSendVerifierAliveRequest: true })}
                    >Manuell versenden</Button>
                    { verifierSentMailError
                      ? <Text className="error">{verifierSentMailError}</Text>
                      : null
                    }
                    { verifierSentMailCount != undefined
                      ? ( verifierSentMailCount === 0
                        ? <Text>Es wurde keine Nachricht verschickt, da beide Verifizierer bereits auf eine Erinnerungsnachricht geantwortet haben.</Text>
                        : verifierSentMailCount === 1
                        ? <Text>Die Nachricht wurde nur an einen Verifizierer geschickt, da der zweite Verifizierer bereits auf eine Erinnerungsnachricht geantwortet hat.</Text>
                        : <Text>Die Nachrichten wurden an die Verifizierer geschickt.</Text>
                      )
                      : null
                    }
                  </View>
                  <View className="infosRow">
                    <Text className="highlightTitle">Tresorinhalte</Text>
                    <Text>Erstellt: {client.documents_count}</Text>
                    <Text>Guthaben: {client.credit_documents}</Text>
                    <Text className="highlightTitle">Kontakte</Text>
                    <Text>Gesamt: {client.contacts_count}</Text>
                    <Text>Empfänger: {client.message_receivers_count}</Text>
                    <Text>Verifizierer: {client.verifiers_count}</Text>
                    <Text>Tresor-Zugriffsberechtigte: {client.document_receivers_count}</Text>
                  </View>
                </View>

                <Popup
                  visible={showConfirmSendVerifierAliveRequest}
                  title="Bist Du sicher?"
                  onConfirm={this.onSendVerifierAliveRequest}
                  onClose={() => this.setState({ showConfirmSendVerifierAliveRequest: false })}
                >
                  <Text>Möchtest Du wirklich Erinnerungsnachrichten an die Verifizierer schicken?</Text>
                </Popup>
              </View>
          }
        </Container>
      </AppView>
    )
  }

  onSendVerifierAliveRequest()
  {
    this.setState({
      isLoading: true,
      showConfirmSendVerifierAliveRequest: false,
    }, () => {
      api({
        url: 'sendVerifierAliveRequest',
        method: 'post',
        data: {
          client_id: this.state.client_id,
        },
        onSuccess: (response) => {
          // console.debug(response)
          if ( response
            && response.data
            && response.data.status === 'ok'
            && response.data.count
          ) {
            this.setState({
              error: null,
              isLoading: false,
              verifierSentMailCount: response.data.count,
              verifierSentMailError: null,
            })
          } else if (
               response
            && response.data
            && response.data.status === 'error'
            && response.data.msg
          ) {
            this.setState({
              isLoading: false,
              verifierSentMailCount: null,
              verifierSentMailError: response.data.msg,
            })
          } else {
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
              isLoading: false,
              verifierSentMailCount: null,
              verifierSentMailError: null,
            })
          }
        },
        onError: (error) => {
          // console.error(error.response)
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            isLoading: false,
            verifierSentMailCount: null,
            verifierSentMailError: null,
          })
        }
      })
    })
  }
}
