/**
 * BottomNavigation
 * @flow
 */
'use strict'

import React from 'react'


type Props = {
  children: Object,
}

type State = {
  // ...
}


export default class BottomNavigation extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
  }

  render() {
    const { children, ...other } = this.props

    return (
      <div {...other}>{children}</div>
    )
  }
}
