/**
 * ActionButton
 * @flow
 */
'use strict'

import React from 'react'

import { Image, Text, View } from '.'


type Props = {
  error?: boolean,
  validationError?: string,
  children: Object,
  className?: string,
  disabled?: boolean,
  style?: Object,
  textStyle?: Object,
  onPress: () => void,
}

type State = {
  // ...
}


export default class ActionButton extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
  }

  render() {
    const {
      error, validationError, children, className,
      disabled, style, textStyle, onPress,
    } = this.props

    return (
      <>
        <View
          className={ error || validationError
            ? `ActionButton button buttonError ${ className ? className : ''}`
            : `ActionButton button ${ className ? className : '' }`
          }
          style={style}
        >
          <Text
            numberOfLines={1}
            className={ error || validationError
              ? 'buttonText buttonTextError'
              : 'buttonText'
            }
            style={textStyle}
          >
            {children}
          </Text>
          <View
            disabled={disabled}
            onPress={ ! disabled ? onPress : undefined }
          >
            <View
              className={ error || validationError
                ? 'iconWrapper iconWrapperError'
                : 'iconWrapper'
              }
            >
              <Image
                source={require('../assets/img/plus.png')}
                className="icon"
              />
            </View>
          </View>
        </View>
        { validationError
          ? <Text className="buttonValidationError error">
              {validationError}
            </Text>
          : null
        }
      </>
    )
  }
}
