/**
 * Icon
 * @flow
 */
'use strict'

import React from 'react'


type Props = {
  className?: string,
  source: Object,
  onPress: () => void,
}

type State = {
  // ...
}


export default class Icon extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
  }

  render() {
    const { className, source, onPress, ...other } = this.props

    return (
      <div
        className={ className ? `Icon ${className}` : 'Icon'}
        onClick={onPress}
      >
        <img src={source} {...other} />
      </div>
    )
  }
}
