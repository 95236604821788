/**
 * AppView
 * @flow
 */
'use strict'

import React from 'react'
import Container from '@material-ui/core/Container'

import Image from './Image'

import logo from '../assets/img/logo.png'

type Props = {
  children: Object,
  hideLogo: boolean
}

type State = {
}

export default class BackgroundView extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
  }

  render() {
    const { children, hideLogo } = this.props

    return (
      <div className="backgroundView">
        <div className="topGradient" />
        { ! hideLogo
          ? <Image
              alt="ALM"
              className="logo"
              source={logo}
            />
          : null
        }
        <Container
          className="innerContainer"
          maxWidth="xs"
          style={{ marginTop: hideLogo ? '-103px' : undefined }}
        >{children}</Container>
      </div>
    )
  }
}
