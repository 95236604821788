/**
 * CreateMessage
 * @flow
 */
'use strict'

import React from 'react'
import { connect } from 'react-redux'
import { addMessageData, removeMessageData } from '../../../redux/actions'

import {
  MessageUpload, MessageExamples, MessageTitle, MessageReceiver,
  MessageDelivery, MessageSummary, MessageSuccess,
} from '.'


type Props = {
  history: Object,
  location: Object,
  match: Object,
  messageData: ?Object,
}

type State = {
  // ...
}


class CreateMessage extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      messageData: props.messageData,
    }

    const CreateMessage = (this: any)
    CreateMessage.onNavigate = this.onNavigate.bind(this)
  }

  render() {
    const messageData = this.props.messageData

    // NOTE: if no route param is available, render first route
    const route = this.props.match && this.props.match.params && this.props.match.params.route
      ? this.props.match.params.route
      : 'upload'

    if ( route === 'success' ) {
      // remove message data from redux store
      this.props.removeMessageData()
    }

    return (
      route === 'upload' ?
        <MessageUpload
          messageData={messageData}
          onNavigate={this.onNavigate}
        />
      // : route === 'examples' ?
      //   <MessageExamples
      //     onNavigate={this.onNavigate}
      //   />
      : route === 'title' ?
        <MessageTitle
          messageData={messageData}
          onNavigate={this.onNavigate}
        />
      : route === 'receiver' ?
        <MessageReceiver
          messageData={messageData}
          onNavigate={this.onNavigate}
        />
      : route === 'delivery' ?
        <MessageDelivery
          messageData={messageData}
          onNavigate={this.onNavigate}
        />
      : route === 'summary' ?
        <MessageSummary
          messageData={messageData}
          onNavigate={this.onNavigate}
        />
      : route === 'success' ?
        <MessageSuccess
          onNavigate={this.onNavigate}
        />
      : null
    )
  }

  onNavigate(messageData: ?Object, route: string) {
    if ( messageData ) {
      this.props.addMessageData(messageData)
      this.props.history.push(route)
    } else {
      this.props.history.push(route)
    }
  }
}


const mapStateToProps = (state) => ({
  messageData: state.data.messageData
})
const mapDispatchToProps = (dispatch) => ({
  addMessageData: (messageData) => dispatch(addMessageData(messageData)),
  removeMessageData: () => dispatch(removeMessageData()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMessage)
