/**
 * LoadingOverlay
 * @flow
 */
'use strict'

import React from 'react'

import { Icon, View } from '.'

import CircularProgress from '@material-ui/core/CircularProgress'


type Props = {
  children?: Object,
  onClose: () => void,
}

type State = {
  // ...
}


export default class LoadingOverlay extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
  }

  render() {
    const { children, onClose } = this.props

    return (
      <View className="LoadingOverlay Preview">
        <View className="PreviewWrapper">
          <View className="PreviewModal">
            <View className="closeIconWrapper">
              <Icon
                src={require('../assets/img/close.png')}
                className="closeIcon"
                onPress={onClose}
              />
            </View>
            <View className="wrapper">
              <CircularProgress />
              {children}
            </View>
          </View>
        </View>
      </View>
    )
  }
}
