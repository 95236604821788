/**
 * Home
 * @flow
 */
'use strict'

import React from 'react'
import { connect } from 'react-redux'
import {
  removeDocumentData, removeMessageData, removeOrderData,
} from '../../redux/actions'

import { api } from '../../helpers'
import {
  AppView, ArrowButton, BackgroundView, Button, Colors, Content,
  ElevatedView, Icon, Image, LoadingSpinner, Text, View
} from '../../components'
import Card from '@material-ui/core/Card'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  error: ?string,
  isLoading: boolean,
  firstname: ?string,
  messages_count: ?number,
  documents_count: ?number,
  accepted_verifier_count: ?number,
}


class Home extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      isLoading: true,
      firstname: null,
      messages_count: null,
      documents_count: null,
      accepted_verifier_count: null,
    }
  }

  componentDidMount() {
    // remove all data from redux store
    this.props.removeDocumentData()
    this.props.removeMessageData()
    this.props.removeOrderData()

    api({
      url: 'userHomeData',
      method: 'get',
      onSuccess: (response) => {
        // console.debug(response)
        if ( response
          && response.data
          && response.data.status === 'ok'
          && response.data.firstname
          && response.data.messages_count != undefined
          && response.data.documents_count != undefined
          && response.data.accepted_verifier_count != undefined
        ) {
          this.setState({
            firstname: response.data.firstname,
            messages_count: response.data.messages_count,
            documents_count: response.data.documents_count,
            accepted_verifier_count: response.data.accepted_verifier_count,
            error: null,
            isLoading: false,
          })
        } else {
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            isLoading: false,
          })
        }
      },
      onError: (error) => {
        // console.error(error.response)
        this.setState({
          error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
          isLoading: false,
        })
      }
    })
  }

  render() {
    const history = this.props.history
    const {
      error, isLoading, firstname, messages_count,
      documents_count, accepted_verifier_count,
    } = this.state

    return (
      isLoading
      ? <LoadingSpinner />
      : <AppView id="Home">
          <BackgroundView>
            { error
              ? <Text className="error">{error}</Text>
              : <>
                  <ElevatedView>
                    <Text
                      className="welcomeTitle"
                      style={{ textAlign: 'center' }}
                    >Willkommen, {firstname}</Text>
                    <Content style={{ alignItems: 'center' }}>
                      <Text style={{ textAlign: 'center' }}>
                        <Text style={{ fontWeight: 600 }}>Schön, dass du da bist!</Text>
                        Die neuesten Tipps und Infos<br />
                        findest du auf unserer Website<br />
                        <Text style={{ fontSize: 15, marginTop: 0 }}>
                          <a href="https://www.alm-app.eu" target="_blank">www.alm-app.eu</a>
                        </Text>
                      </Text>
                      <Button
                        style={{ width: '100%', marginTop: 5, marginBottom: 5 }}
                        type="gradient"
                        onPress={() => history.push('/create-message')}
                      >Botschaft erstellen</Button>
                    </Content>
                  </ElevatedView>

                  <View className="cardsWrapper">
                    <Card
                      className="card"
                      style={{ marginRight: 5 }}
                      onClick={() => history.push('/messages')}
                    >
                      <Image
                        source={require('../../assets/img/messages.png')}
                        style={{ width: 28, height: 28 }}
                      />
                      <Text className="cardCount">{messages_count}</Text>
                      <Text className="cardTitle">
                        <span className="cardTitleSmall">Erstellte</span><br />
                        Botschaft{ messages_count !== 1 ? 'en' : null }
                      </Text>
                    </Card>
                    <Card
                      className="card"
                      style={{ marginLeft: 5 }}
                      onClick={() => history.push('/vault')}
                    >
                      <Image
                        source={require('../../assets/img/vault.png')}
                        style={{ width: 28, height: 28 }}
                      />
                      <Text className="cardCount">{documents_count}</Text>
                      <Text className="cardTitle">
                        <span className="cardTitleSmall">Erstellte{ documents_count === 1 ? 'r' : null }</span><br />
                        Tresorinhalt{ documents_count !== 1 ? 'e' : null }
                      </Text>
                    </Card>
                  </View>

                  <Button
                    type="gradient"
                    style={{ width: '100%', marginTop: 10, marginBottom: 10 }}
                    onPress={() => history.push('/create-document')}
                  >Tresorinhalt erstellen</Button>
                  <ArrowButton
                    iconLeft={{ src: require('../../assets/img/contacts.png') }}
                    iconExtra={ accepted_verifier_count < 2
                      ? { src: require('../../assets/img/warning.png') }
                      : undefined
                    }
                    onPress={() => history.push('/contacts')}
                  >Kontakte</ArrowButton>
                </>
            }
          </BackgroundView>
        </AppView>
    )
  }
}


const mapDispatchToProps = (dispatch) => ({
  removeDocumentData: () => dispatch(removeDocumentData()),
  removeMessageData: () => dispatch(removeMessageData()),
  removeOrderData: () => dispatch(removeOrderData()),
})

export default connect(
  null,
  mapDispatchToProps
)(Home)
