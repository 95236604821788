/* @flow */
'use strict'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
// import * as serviceWorker from './serviceWorker'

import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import './index.css'

import {
  Contacts, ContactDetails, CreateNewContact, CreateMessage, MessageExamples,
  Home, Messages, MessageDetails, Credit, Order, Profile,
  CreateNewDocument, DocumentDetails, DocumentSuccess, Vault,
} from './containers/App'
import {
  AuthLoading, SignIn, Signup, ForgotPassword,
} from './containers/Auth'
import {
  ManagementAuthLoading, Clients, ClientDetails, ClientStatistics,
} from './containers/Management'

const theme = createMuiTheme({
  palette: {
    primary: { main: '#08167e' },
    secondary: { main: '#808080' },
    error: { main: '#B00500' },
  },
})


function App() {
  return (
    <>
      <Switch>
        <AuthLoading path="/" exact>
          <Home />
        </AuthLoading>

        <Route
          path="/login"
          children={(props) => (
            <SignIn {...props} />
          )}
        />
        <Route
          path="/forgot-password"
          children={(props) => (
            <ForgotPassword {...props} />
          )}
        />
        <Route
          path="/signup"
          children={(props) => (
            <Signup {...props} />
          )}
        />

        <AuthLoading path="/contacts">
          <Contacts />
        </AuthLoading>
        <AuthLoading path="/contact">
          <ContactDetails />
        </AuthLoading>
        <AuthLoading path="/create-contact">
          <CreateNewContact />
        </AuthLoading>

        <AuthLoading path="/messages">
          <Messages />
        </AuthLoading>
        <AuthLoading path="/message">
          <MessageDetails />
        </AuthLoading>
        <AuthLoading path="/create-message/:route">
          <CreateMessage />
        </AuthLoading>
        {/* NOTE: if route param in url is deleted
            the route below ensures that the
            create-message route is rendered anyway */}
        <AuthLoading path="/create-message">
          <CreateMessage />
        </AuthLoading>

        <AuthLoading path="/vault">
          <Vault />
        </AuthLoading>
        <AuthLoading path="/document">
          <DocumentDetails />
        </AuthLoading>
        <AuthLoading path="/create-document">
          <CreateNewDocument />
        </AuthLoading>
        <AuthLoading path="/document-success">
          <DocumentSuccess />
        </AuthLoading>

        <AuthLoading path="/credit">
          <Credit />
        </AuthLoading>
        <AuthLoading path="/order/:route">
          <Order />
        </AuthLoading>
        {/* NOTE: if route param in url is deleted
            the route below ensures that the
            order route is rendered anyway */}
        <AuthLoading path="/order">
          <Order />
        </AuthLoading>

        <AuthLoading path="/profile">
          <Profile />
        </AuthLoading>

        <ManagementAuthLoading path="/management/client">
          <ClientDetails />
        </ManagementAuthLoading>
        <ManagementAuthLoading path="/management/statistics">
          <ClientStatistics />
        </ManagementAuthLoading>
        <ManagementAuthLoading path="/management">
          <Clients />
        </ManagementAuthLoading>

        {/* If none of the previous routes render anything,
            this route acts as a fallback. */}
        <AuthLoading path="/">
          <Home />
        </AuthLoading>
      </Switch>
    </>
  )
}


ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
