/**
 * Vault
 * @flow
 */
'use strict'

import React from 'react'
import { connect } from 'react-redux'
import { removeDocumentData } from '../../../redux/actions'
import { filter } from 'lodash'

import { BASE_URL, api } from '../../../helpers'
import {
  AppView, BackgroundView, Button, Colors, ElevatedView, FlatList, Icon, Image,
  Input, LoadingSpinner, Popup, Text, TouchableOpacity, View,
} from '../../../components'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  error: ?string,
  isLoading: boolean,
  showPopup: boolean,

  allDocuments: Array<Object>,
  searchResults: Array<Object>,
  searchQuery: string,
}

const DOCUMENT_ITEM_HEIGHT = 70


class Vault extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      isLoading: true,
      showPopup: false,

      allDocuments: [],
      searchResults: [],
      searchQuery: '',
    }

    const Vault = (this: any)
    Vault.onSearchDocuments = this.onSearchDocuments.bind(this)
  }

  componentDidMount() {
    // remove document data from redux store
    this.props.removeDocumentData()

    api({
      url: 'userDocuments',
      method: 'get',
      onSuccess: (response) => {
        // console.debug(response)
        if ( response
          && response.data
          && response.data.status === 'ok'
          && response.data.documents != undefined
        ) {
          const documents = response.data.documents

          this.setState({
            error: null,
            isLoading: false,
            allDocuments: documents,
          })
        } else {
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            isLoading: false,
          })
        }
      },
      onError: (error) => {
        // console.error(error.response)
        this.setState({
          error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
          isLoading: false,
        })
      }
    })
  }

  render() {
    const {
      error, isLoading, showPopup,
      allDocuments, searchResults, searchQuery,
    } = this.state

    return (
      <AppView id="Vault">
        <BackgroundView hideLogo={true}>
          <Text className="welcomeTitle">Meine Tresorinhalte</Text>
          <ElevatedView style={{ padding: 0, marginTop: 10 }}>
            <View className="sectionTitleWrapper">
              <Text className="sectionTitleText">TRESORINHALTE</Text>
              <Icon
                src={require('../../../assets/img/info.png')}
                style={{ height: 24, width: 24 }}
                onPress={() => this.setState({ showPopup: true })}
              />
            </View>
          </ElevatedView>
          { isLoading ?
              <LoadingSpinner />
            : allDocuments && allDocuments.length ?
              <>
                <Input
                  icon={{
                    src: require('../../../assets/img/search.png'),
                    style: { height: 14, width: 14 }
                  }}
                  placeholder="Suchen"
                  placeholderTextColor={Colors.lightGray}
                  className="searchField"
                  value={searchQuery}
                  onChangeText={(value: string) => this.onSearchDocuments(value)}
                />

                <FlatList
                  data={ searchQuery ? searchResults : allDocuments }
                  renderItem={({ item, index }) => {
                    const {
                      id, contacts, uploaded_file, title,
                    } = item

                    return (
                      <TouchableOpacity
                        onPress={() => {
                          this.props.history.push({
                            pathname: '/document',
                            state: {
                              document: item
                            }
                          })
                        }}
                        className="documentItem"
                      >
                        <View className="documentWrapper">
                          <Image
                            source={ contacts && contacts.length === 1 && contacts[0] && contacts[0].profile_picture
                              ? `${BASE_URL}/profilePicture/${contacts[0].profile_picture}`
                              : require('../../../assets/img/profile-picture.jpg')
                            }
                            className="profilePicture"
                          />
                          <View className="documentInfoWrapper">
                            <View className="documentFirstRow">
                              <View className="documentReceiversWrapper">
                                <Text
                                  ellipsizeMode="tail"
                                  numberOfLines={1}
                                  className="documentReceivers"
                                >
                                  { contacts && contacts.length
                                    ? contacts.map((contact, index) => {
                                        if ( index + 1 !== contacts.length ) {
                                          return `${contact.firstname} ${contact.lastname}, `
                                        } else {
                                          return `${contact.firstname} ${contact.lastname}`
                                        }
                                      })
                                    : null
                                  }
                                </Text>
                              </View>
                            </View>
                            <Text
                              ellipsizeMode="tail"
                              numberOfLines={1}
                              className="documentTitle"
                            >{title}</Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    )
                  }}
                  style={{ width: '100%', marginTop: 10 }}
                />
              </>
            : <>
                <View className="wrapper">
                  { error
                    ? <Text className="error">{error}</Text>
                    : <Text style={{ textAlign: 'center' }}>Noch keine Tresorinhalte vorhanden.</Text>
                  }
                </View>
              </>
          }

          <Button
            containerStyle={{ marginTop: 'auto' }}
            type="gradient"
            onPress={() => this.props.history.push('/create-document')}
          >Neuer Tresorinhalt</Button>

          <Popup
            visible={showPopup}
            title="Info - Tresorinhalte"
            onClose={() => this.setState({ showPopup: false })}
          >
            <Text>Im Tresor kannst Du wichtige Dokumente wie z.B. Verträge oder Zugangsdaten abspeichern.</Text>
            <Text>Die von Dir dafür vorgesehen Empfänger erhalten Zugriff auf diese Dokumente, sobald wir die Bestätigung über Dein Ableben von Deinen beiden Vertrauenspersonen bekommen haben.</Text>
            <Text>Du kannst diesen Tresor aber auch als „digitale Brieftasche“ nutzen, um wichtige Daten immer bei der Hand zu haben (abhängig vom Dateiformat).</Text>
          </Popup>
        </BackgroundView>
      </AppView>
    )
  }

  onSearchDocuments(searchQuery: string) {
    const { allDocuments, filter } = this.state

    if ( searchQuery ) {
      const searchResults = allDocuments.filter((document) => {
        const cleanSearchQuery = searchQuery.trim().toLowerCase()
        const cleanMessageTitle = document.title.toLowerCase()

        const filteredContactIds = document.contacts.filter((contact) => {
          if (
            contact.firstname.toLowerCase().indexOf(cleanSearchQuery) !== -1
            || contact.lastname.toLowerCase().indexOf(cleanSearchQuery) !== -1
          ) {
            return contact.id
          }
        });

        if (
          ( document.title && cleanMessageTitle.indexOf(cleanSearchQuery) !== -1 )
          || ( filteredContactIds && filteredContactIds.length )
        ) {
          return document
        }
      })
      this.setState({
        searchResults: searchResults,
        searchQuery: searchQuery,
      })
    } else {
      this.setState({ searchResults: [], searchQuery: '' })
    }
  }
}


const mapDispatchToProps = (dispatch) => ({
  removeDocumentData: () => dispatch(removeDocumentData()),
})

export default connect(
  null,
  mapDispatchToProps
)(Vault)
