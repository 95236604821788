/**
 * CreateNewContact
 * @flow
 */
'use strict'

import React from 'react'
import mime from 'mime-types'

import { api } from '../../../helpers'
import {
  AppView, BackgroundView, Button, Colors, Dropdown, ElevatedView, Image,
  Input, Text, TouchableOpacity, View,
} from '../../../components'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  error: ?string,
  validationErrors: ?Object,
  isSaving: boolean,

  profile_picture: ?string,
  salutation: ?string,
  firstname: ?string,
  lastname: ?string,
  email: ?string,
  phone: ?string,
}

const MAX_FILE_SIZE = 10000000 // bytes = 10MB


export default class CreateNewContact extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      validationErrors: null,
      isSaving: false,

      profile_picture: null,
      salutation: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
    }

    const CreateNewContact = (this: any)
    CreateNewContact.onChooseFile = this.onChooseFile.bind(this)
    CreateNewContact.onCreate = this.onCreate.bind(this)
    CreateNewContact.onShowImagePicker = this.onShowImagePicker.bind(this)
  }

  render() {
    const {
      error, validationErrors, isSaving,
      profile_picture, salutation, firstname, lastname, email, phone,
    } = this.state

    return (
      <AppView id="CreateNewContact">
        <BackgroundView hideLogo={true}>
          <TouchableOpacity onPress={this.onShowImagePicker}>
            <View className="profilePictureWrapper">
              <div
                className={ profile_picture
                  ? 'profilePicture'
                  : 'profilePictureUpload profilePicture'
                }
                style={{ backgroundImage: profile_picture
                  ? `url(${profile_picture.tmp})`
                  : `url(${ require('../../../assets/img/photo-upload.png') })`
                }}
              ></div>
              <input
                ref={(input) => this.ImagePicker = input}
                id="ImagePicker"
                type="file"
                accept=".jpeg, .jpg, .png"
                onChange={this.onChooseFile}
              />
            </View>
          </TouchableOpacity>
          { validationErrors && validationErrors['contact_profile_picture']
            ? <Text className="error">{validationErrors['contact_profile_picture'][0]}</Text>
            : null
          }
          <ElevatedView>
            <Dropdown
              error={ error && ! salutation }
              validationError={ validationErrors && validationErrors['contact_salutation']
                ? validationErrors['contact_salutation'][0]
                : null
              }
              items={[ { label: 'Frau', value: 1 }, { label: 'Herr', value: 2 } ]}
              placeholder="Anrede"
              value={salutation}
              onValueChange={(value: string, index: number) => {
                this.setState({ salutation: value })
              }}
            />
            <Input
              error={ error && ! firstname }
              validationError={ validationErrors && validationErrors['contact_firstname']
                ? validationErrors['contact_firstname'][0]
                : null
              }
              placeholder="Vorname"
              style={{ marginVertical: 5 }}
              type="text"
              value={firstname}
              onChangeText={(value: string) => this.setState({ firstname: value })}
            />
            <Input
              error={ error && ! lastname }
              validationError={ validationErrors && validationErrors['contact_lastname']
                ? validationErrors['contact_lastname'][0]
                : null
              }
              placeholder="Nachname"
              style={{ marginVertical: 5 }}
              type="text"
              value={lastname}
              onChangeText={(value: string) => this.setState({ lastname: value })}
            />
            <Input
              error={ error && ! email }
              validationError={ validationErrors && validationErrors['contact_email']
                ? validationErrors['contact_email'][0]
                : null
              }
              type="email"
              placeholder="E-Mail"
              style={{ marginVertical: 5 }}
              value={email}
              onChangeText={(value: string) => this.setState({ email: value })}
            />
            <Input
              error={ error && ! phone }
              validationError={ validationErrors && validationErrors['contact_phone']
                ? validationErrors['contact_phone'][0]
                : null
              }
              type="text"
              infoText={ validationErrors && validationErrors['contact_phone']
                ? null
                : 'Bitte mit internationaler Vorwahl +43, +44 oder +41 angeben: z.B. +43660xxx'
              }
              placeholder="Mobilrufnummer"
              style={{ marginVertical: 5 }}
              value={phone}
              onChangeText={(value: string) => this.setState({ phone: value })}
            />
          </ElevatedView>

          { error
            ? <Text className="error">{error}</Text>
            : null
          }

          <Button
            disabled={isSaving}
            containerStyle={{ marginTop: 'auto'}}
            type="gradient"
            onPress={this.onCreate}
          >Speichern{ isSaving ? '...' : null }</Button>
        </BackgroundView>
      </AppView>
    )
  }

  onCreate() {
    const {
      isSaving, salutation, firstname, lastname,
      phone, email, profile_picture,
    } = this.state

    if ( ! isSaving && salutation && firstname
      && lastname && phone && email
    ) {
      this.setState({ isSaving: true }, () => {
        let formData = new FormData()

        if ( profile_picture ) {
          formData.append('contact_profile_picture', profile_picture.uri)
        }

        const formDataSerialized = JSON.stringify({
          contact_salutation: salutation,
          contact_firstname: firstname,
          contact_lastname: lastname,
          contact_phone: phone,
          contact_email: email,
        })

        formData.append('formDataSerialized', formDataSerialized)

        api({
          url: 'createContact',
          method: 'post',
          data: formData,
          formData: true,
          onSuccess: (response) => {
            // console.debug(response)
            if ( response
              && response.data
              && response.data.status === 'ok'
            ) {
              this.setState({
                error: null,
                validationErrors: null,
                isSaving: false,
              })
              this.props.history.goBack()
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isSaving: false,
              })
            }
          },
          onError: (error) => {
            // console.debug(error.response)
            if ( error
              && error.response
              && error.response.status == 422
              && error.response.data
              && error.response.data.errors
            ) {
              const errorInputNames = []
              Object.keys(error.response.data.errors).map((key) => {
                errorInputNames.push(key)
              })
              this.setState({
                error: 'Bitte Eingaben überprüfen.',
                validationErrors: error.response.data.errors,
                isSaving: false,
              })
            } else if ( error
              && error.response
              && error.response.status == 413
            ) {
              this.setState({
                error: 'Das hochgeladene Bild ist zu groß.',
                validationErrors: null,
                isSaving: false,
              })
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isSaving: false,
              })
            }
          }
        })
      })
    } else {
      this.setState({ error: 'Bitte alle Felder ausfüllen.' })
    }
  }

  onChooseFile(event) {
    const file = event.target.files[0]

    if ( file ) {
      if ( file.size && file.size > MAX_FILE_SIZE ) {
        this.setState({
          error: 'Das ausgewählte Bild ist zu groß.',
          profile_picture: null,
        })
      } else {
        const reader = new FileReader()

        reader.onload = (e) => {
          const mimeType = mime.lookup(file.name)

          if ( mimeType && mimeType === 'image/jpeg'
            || mimeType && mimeType === 'image/png'
          ) {
            const source = {
              uri: file,
              // display the file temporarily
              tmp: e.target.result,
              type: 'image',
              mime: mimeType,
            }
            this.setState({
              error: null,
              profile_picture: source,
            })
          } else {
            this.setState({
              error: 'Das Dateiformat ist nicht erlaubt.',
              profile_picture: null,
            })
          }
        }

        reader.onerror = (e) => {
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            profile_picture: null,
          })
        }

        reader.onabort = (e) => {
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            profile_picture: null,
          })
        }

        reader.readAsDataURL(file)
      }
    }
  }

  onShowImagePicker() {
    this.ImagePicker.click()
  }
}
