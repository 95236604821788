/**
 * MessageUpload
 * @flow
 */
'use strict'

import React from 'react'
import mime from 'mime-types'

import {
  AppView, ArrowButtonGradient, ArrowButtonPlain, BackgroundView, BottomNavigation, Button, Content,
  ElevatedView, Icon, Input, LoadingSpinner, Popup, Preview, Text, TouchableOpacity, View,
} from '../../../components'


type Props = {
  messageData: ?Object,
  onNavigate: (messageData: ?Object, route: string) => void,
}

type State = {
  error: ?string,
  isLoading: boolean,
  showMediaPopup: boolean,
  showTextArea: boolean,
  showTextPopup: boolean,
  uploaded_file: ?Object,
  uploaded_text: ?string,
}

const MAX_FILE_SIZE = 250000000 // bytes = 250MB
const MAX_TEXT_SIZE = 1000 // characters


export default class MessageUpload extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      isLoading: false,
      showMediaPopup: false,
      showTextArea: false,
      showTextPopup: false,
      uploaded_file: props.messageData && props.messageData.uploaded_file
        ? props.messageData.uploaded_file
        : null,
      uploaded_text: props.messageData && props.messageData.uploaded_text
        ? props.messageData.uploaded_text
        : null,
    }

    const MessageUpload = (this: any)
    MessageUpload.onContinue = this.onContinue.bind(this)
    MessageUpload.onChooseFile = this.onChooseFile.bind(this)
    MessageUpload.onShowPicker = this.onShowPicker.bind(this)
  }

  render() {
    const { messageData, onNavigate } = this.props
    const {
      error, isLoading, showMediaPopup, showTextArea, showTextPopup,
      uploaded_file, uploaded_text,
    } = this.state

    return (
      <AppView id="MessageUpload">
        <BackgroundView>
          <Text className="welcomeTitle">Botschaft erstellen</Text>
          { ( ! uploaded_file && ! uploaded_text && ! showTextArea && ! isLoading ) || error
            ? <View>
                <ElevatedView style={{ textAlign: 'left' }}>
                  <Text className="pagination">Schritt 1 von 5</Text>
                  <Text className="highlight">Datei hochladen oder Nachricht verfassen</Text>
                  <Text>
                    Füge Deiner Botschaft ein Video, ein Bild, ein Dokument bzw. eine Sprachnachricht hinzu, oder verfasse direkt einen persönlichen Text.
                  </Text>
                </ElevatedView>

                <a href="https://www.alm-app.eu/bsp_tipps" target="_blank">
                  <ArrowButtonPlain
                    style={{ margin: '20px 0' }}
                    // onPress={() => onNavigate({
                    //   ...messageData,
                    //   uploaded_file,
                    // }, '/create-message/examples')}
                  >Beispiele & Tipps</ArrowButtonPlain>
                </a>

                { error
                  ? <Text className="error">{error}</Text>
                  : null
                }

                <ElevatedView>
                  <View>
                    <View className="uploadWrapper">
                      <View className="uploadButtonWrapper">
                        <Text className="uploadText">
                          Video, Bild, Dokument oder Sprachnachricht
                        </Text>
                        <ArrowButtonGradient
                          onPress={this.onShowPicker}
                        >Datei hochladen</ArrowButtonGradient>
                        <input
                          ref={(input) => this.Picker = input}
                          id="Picker"
                          type="file"
                          accept=".mp4, .wmv, .mpeg, .jpeg, .jpg, .png, .gif, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .txt, .rtf, .mp3, .wav, .wma"
                          onChange={this.onChooseFile}
                        />
                      </View>
                      <View className="uploadInfoWrapper">
                        <Icon
                          src={require('../../../assets/img/info.png')}
                          className="uploadInfoIcon"
                          style={{ height: 28, width: 28 }}
                          onPress={() => this.setState({ showMediaPopup: true })}
                        />
                      </View>
                    </View>
                  </View>
                  <View className="uploadWrapper" style={{ marginTop: 15 }}>
                    <View className="uploadButtonWrapper">
                      <Text className="uploadText">
                        Persönlicher Text
                      </Text>
                      <ArrowButtonGradient
                        onPress={() => {
                          this.setState({ error: null, showTextArea: true })
                        }}
                      >Nachricht verfassen</ArrowButtonGradient>
                    </View>
                    <View className="uploadInfoWrapper">
                      <Icon
                        src={require('../../../assets/img/info.png')}
                        className="uploadInfoIcon"
                        style={{ height: 28, width: 28 }}
                        onPress={() => this.setState({ showTextPopup: true })}
                      />
                    </View>
                  </View>
                </ElevatedView>
              </View>
            : <>
                <View>
                  <ElevatedView>
                    <Content>
                      <Text className="pagination">Schritt 1 von 5</Text>
                      <Text className="highlight">
                        { showTextArea || uploaded_text
                          ? 'Nachricht verfassen'
                          : 'Datei hochladen'
                        }
                      </Text>
                    </Content>
                    { isLoading ?
                        <LoadingSpinner />
                      : uploaded_file ?
                        <View>
                          <Preview
                            disableAspectRatio={true}
                            className="previewImage"
                            src={uploaded_file.tmp}
                            type={uploaded_file.type}
                          />
                          <TouchableOpacity onPress={() => {
                            this.setState({ error: null, uploaded_file: null })
                          }}>
                            <Text style={{
                              cursor: 'pointer',
                              textAlign: 'left',
                              textDecorationLine: 'underline',
                            }}>
                              Andere Datei auswähen
                            </Text>
                          </TouchableOpacity>
                        </View>
                      : showTextArea || uploaded_text ?
                        <View>
                          <Text style={{ textAlign: 'left' }}>
                            Verfasse hier direkt einen persönlichen Text.<br />
                            <span className="infoGrey">(maximal 1.000 Zeichen)</span>
                          </Text>
                          <Input
                            placeholder="Nachricht eingeben"
                            maxLength={MAX_TEXT_SIZE}
                            multiline
                            rows="10"
                            value={uploaded_text}
                            onChange={(event) => {
                              this.setState({ uploaded_text: event.target.value })
                            }}
                          />
                          <TouchableOpacity onPress={() => {
                            this.setState({
                              error: null,
                              uploaded_text: null,
                              showTextArea: false,
                            })
                          }}>
                            <Text style={{
                              cursor: 'pointer',
                              textAlign: 'left',
                              textDecorationLine: 'underline',
                            }}>
                              Zurück
                            </Text>
                          </TouchableOpacity>
                        </View>
                      : null
                    }
                  </ElevatedView>
                </View>
              </>
          }

          { ( uploaded_file || uploaded_text || showTextArea ) && ! isLoading && (
            <Button
              type="gradient"
              containerStyle={{ marginTop: 'auto' }}
              style={{ marginTop: 20 }}
              onPress={this.onContinue}
            >Weiter</Button>
          ) }

          <Popup
            visible={showMediaPopup}
            title="Info - Datei hochladen"
            onClose={() => this.setState({ showMediaPopup: false })}
          >
            <Text>Hier hast Du die Möglichkeit, Dateien, die auf Deinem Gerät gespeichert sind, Deiner Botschaft hinzuzufügen.</Text>
            <Text>
              <span className="highlightBold">Welche Dateiformate sind möglich?</span><br />
              Videos: mp4, wmv, mpeg<br />
              Fotos: jpeg, jpg, png, gif<br />
              Dokumente: pdf, doc(x), ppt(x), xls(x), txt, rtf<br />
              Audio: mp3, wav, wma
            </Text>
            <Text>Bitte beachte, dass die Dateigröße 250 MB nicht überschreiten darf.</Text>
          </Popup>

          <Popup
            visible={showTextPopup}
            title="Info - Nachricht verfassen:"
            onClose={() => this.setState({ showTextPopup: false })}
          >
            <Text>Hier hast Du die Möglichkeit, direkt in der Anwendung einen einfachen, persönlichen Text zu verfassen.</Text>
            <Text>
              Tipp:<br />
              Auch nur ein kurzes <span className="italic">„Ich hab Dich lieb“</span> tut immer gut…
            </Text>
            <Text>Max. Zeichenanzahl: 1000</Text>
          </Popup>

          <BottomNavigation
            currentRoute="MessageUpload"
            routes={[
              'MessageUpload', 'MessageTitle', 'MessageReceiver',
              'MessageDelivery', 'MessageSummary',
            ]}
            // onPress={(route: string) => this.props.navigation.navigate(route)}
          />
        </BackgroundView>
      </AppView>
    )
  }

  onContinue() {
    const { messageData, onNavigate } = this.props
    const { uploaded_file, uploaded_text } = this.state

    if ( uploaded_file || uploaded_text ) {
      this.setState({ error: null })
      onNavigate({
        ...messageData,
        uploaded_file: uploaded_file && ! uploaded_text ? uploaded_file : undefined,
        uploaded_text: uploaded_text && ! uploaded_file ? uploaded_text : undefined,
      }, '/create-message/title')
    } else {
      this.setState({ error: 'Bitte Datei hochladen oder Nachricht verfassen.', showTextArea: false })
    }
  }

  onChooseFile(event) {
    const file = event.target.files[0]

    this.setState({ isLoading: true }, () => {
      if ( file ) {
        if ( file.size && file.size > MAX_FILE_SIZE ) {
          this.setState({
            error: 'Die ausgewählte Datei ist zu groß.',
            isLoading: false,
            uploaded_file: null,
          })
        } else {
          const reader = new FileReader()

          reader.onload = (e) => {
            const mimeType = mime.lookup(file.name)

            if ( mimeType && mimeType === 'image/jpeg'
              || mimeType && mimeType === 'image/png'
              || mimeType && mimeType === 'image/gif'
              || mimeType && mimeType === 'video/mp4'
              || mimeType && mimeType === 'video/x-ms-wmv'
              || mimeType && mimeType === 'video/mpeg'
              || mimeType && mimeType === 'application/pdf'
              || mimeType && mimeType === 'application/msword' // doc
              || mimeType && mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // docx
              || mimeType && mimeType === 'application/vnd.ms-excel' // xls
              || mimeType && mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // xlsx
              || mimeType && mimeType === 'application/vnd.ms-powerpoint' // ppt
              || mimeType && mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' // pptx
              || mimeType && mimeType === 'text/plain' // txt
              || mimeType && mimeType === 'text/rtf' // rtf
              || mimeType && mimeType === 'application/rtf' // rtf
              || mimeType && mimeType === 'audio/mpeg' // mp3
              || mimeType && mimeType === 'audio/x-wav' // wav
              || mimeType && mimeType === 'audio/vnd.wave' // wav
              || mimeType && mimeType === 'audio/wave' // wav
              || mimeType && mimeType === 'audio/x-ms-wma' // wma
            ) {
              const source = {
                uri: file,
                // display the file temporarily
                tmp: e.target.result,
                type: ( mimeType === 'image/jpeg' || mimeType === 'image/png' || mimeType === 'image/gif' ) ?
                    'image'
                  : ( mimeType === 'video/mp4' || mimeType === 'video/x-ms-wmv' || mimeType === 'video/mpeg' ) ?
                    'video'
                  : mimeType === 'application/pdf' ?
                    'pdf'
                  : ( mimeType === 'audio/mpeg' || mimeType === 'audio/x-wav' || mimeType === 'audio/vnd.wave' || mimeType === 'audio/wave' || mimeType === 'audio/x-ms-wma' ) ?
                    'audio'
                  : 'document',
                mime: mimeType,
              }
              this.setState({
                error: null,
                isLoading: false,
                uploaded_file: source,
              }, () => this.onContinue())
            } else {
              this.setState({
                error: 'Das Dateiformat ist nicht erlaubt.',
                isLoading: false,
                uploaded_file: null,
              })
            }
          }

          reader.onerror = (e) => {
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
              isLoading: false,
              uploaded_file: null,
            })
          }

          reader.onabort = (e) => {
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
              isLoading: false,
              uploaded_file: null,
            })
          }

          reader.readAsDataURL(file)
        }
      } else {
        this.setState({ isLoading: false })
      }
    })
  }

  onShowPicker() {
    this.Picker.click()
  }
}
