/**
 * Clients
 * @flow
 */
'use strict'

import React from 'react'
import { Link } from 'react-router-dom'

import { api } from '../../helpers'

import {
  AppView, ArrowButtonPlain, LoadingSpinner, Text, TouchableOpacity, View,
} from '../../components'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  error: ?string,
  isLoading: boolean,

  allClients: Array<?Object>,
  filteredClients: Array<?Object>,
  filter: 0 | 1, // 0 = alle Benutzer, 1 = Benutzer ohne Pakete
}


export default class Clients extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      isLoading: true,

      allClients: [],
      filteredClients: [],
      filter: 0,
    }

    const Clients = (this: any)
    Clients.onFilter = this.onFilter.bind(this)
  }

  componentDidMount() {
    api({
      url: 'clients',
      method: 'get',
      onSuccess: (response) => {
        // console.debug(response)
        if ( response
          && response.data
          && response.data.status === 'ok'
          && response.data.clients != undefined
        ) {
          this.setState({
            allClients: response.data.clients,
            filteredClients: response.data.clients,
            error: null,
            isLoading: false,
          })
        } else {
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            isLoading: false,
          })
        }
      },
      onError: (error) => {
        // console.error(error.response)
        this.setState({
          error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
          isLoading: false,
        })
      }
    })
  }

  render() {
    const {
      error, isLoading, allClients, filteredClients, filter,
    } = this.state

    return (
      <AppView
        id="Clients"
        hideLogo={true}
      >
        <Container maxWidth="md">
          <Text className="welcomeTitle">Benutzerübersicht</Text>
          <Link to="/management/statistics">
            <ArrowButtonPlain
              fullWidth={false}
              style={{ width: 105 }}
            >Statistik</ArrowButtonPlain>
          </Link>
          { isLoading ?
              <LoadingSpinner />
            : allClients && allClients.length ?
              <>
                <View className="filter">
                  <TouchableOpacity onPress={() => this.onFilter(0)}>
                    <Text className={ filter === 0 ? 'currentFilter filterText' : 'filterText' }>
                      Alle Benutzer
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => this.onFilter(1)}>
                    <Text className={ filter === 1 ? 'currentFilter filterText' : 'filterText' }>
                      Benutzer ohne Pakete
                    </Text>
                  </TouchableOpacity>
                </View>

                <Paper className="table">
                  <View className="tableWrapper">
                    <Table stickyHeader size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Nachname</TableCell>
                          <TableCell>Vorname</TableCell>
                          <TableCell>E-Mail-Adresse</TableCell>
                          <TableCell>PLZ</TableCell>
                          <TableCell>Land</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        { filteredClients.map((client, index) => (
                          <TableRow
                            key={client.id}
                            hover
                            onClick={() => {
                              this.props.history.push({
                                pathname: '/management/client',
                                state: {
                                  id: client.id
                                }
                              })
                            }}
                          >
                            <TableCell component="th" scope="client">{client.id}</TableCell>
                            <TableCell>{client.lastname}</TableCell>
                            <TableCell>{client.firstname}</TableCell>
                            <TableCell>{client.email}</TableCell>
                            <TableCell>{client.zipcode}</TableCell>
                            <TableCell>{client.country}</TableCell>
                          </TableRow>
                        )) }
                      </TableBody>
                    </Table>
                  </View>
                </Paper>
              </>
            : <View className="wrapper">
                { error
                  ? <Text className="error">{error}</Text>
                  : <Text>Noch keine Benutzer vorhanden.</Text>
                }
              </View>
          }
        </Container>
      </AppView>
    )
  }

  onFilter(filter: 0 | 1) {
    const allClients = this.state.allClients

    if ( filter === 0 ) {
      this.setState({ filteredClients: allClients, filter: 0 })
    } else if ( filter === 1 ) {
      const filteredClients = allClients.filter((client) => {
        return client.orders_count === 0
      })
      this.setState({ filteredClients: filteredClients, filter: 1 })
    }
  }
}
