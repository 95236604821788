/**
 * SignupInfo
 * @flow
 */
'use strict'

import React from 'react'

import { api } from '../../../helpers'
import {
  AppView, BackgroundView, Content, Button, ElevatedView, Image, Input, Text, View,
} from '../../../components'


type Props = {
  data: Object,
  onContinue: (data: Object) => void,
  onNavigate: () => void,
}

type State = {
  error: ?string,
  isSaving: boolean,

  email: string,
  step: 0 | 1,
  verificationCode: ?number,

  has_phone_verification_code: boolean,
  hasPhoneVerificationError: boolean,
}


export default class SignupInfo extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    const email = props.data.email
    const has_phone_verification_code =
      props.data.has_phone_verification_code
    // these params are only passed from login
    const has_verified_phone = props.data.has_verified_phone
    const has_verified_email = props.data.has_verified_email

    this.state = {
      error: null,
      isSaving: false,

      email,
      step: has_verified_phone ? 1 : 0,
      verificationCode: null,

      has_phone_verification_code,
      hasPhoneVerificationError: false,
    }

    const SignupInfo = (this: any);
    SignupInfo.onVerifyPhone = this.onVerifyPhone.bind(this)
    SignupInfo.onFinishVerification = this.onFinishVerification.bind(this)
  }

  render() {
    const {
      error, isSaving,
      email, step, verificationCode,
      has_phone_verification_code, hasPhoneVerificationError,
    } = this.state

    return (
      <AppView
        id="SignupInfo"
        hideMenu={true}
      >
        <BackgroundView>
          <Text className="welcomeTitle">Verifizierung</Text>
          <ElevatedView>
            <Content>
              <Text>Nun fehlen nur noch 2 kleine Schritte für die erfolgreiche Registrierung:</Text>
              <View>
                <View className={ step !== 0 ? 'disabledStepWrapper stepWrapper' : 'stepWrapper' }>
                  <Image
                    source={ step !== 0
                      ? require('../../../assets/img/step-done.png')
                      : require('../../../assets/img/step-1.png')
                    }
                    className="stepImage"
                  />
                  <Text
                    className="highlight stepTitle"
                  >Mobilrufnummer verifizieren</Text>
                </View>
                { step === 0
                  ? <>
                      { ! has_phone_verification_code
                        ? <Text style={{ color: '#B00500' }}>Mobilrufnummer Verifizierung ist derzeit nicht möglich. Bitte später erneut versuchen.</Text>
                        : <>
                            <Text>Zur Verifizierung Deiner Mobilrufnummer gib bitte hier den Aktivierungscode ein, den Du per SMS von der Nummer <span style={{ color: '#08167e' }}>+43&nbsp;828&nbsp;270&nbsp;994&nbsp;6663</span> erhalten hast.</Text>

                            <Input
                              autoFocus={true}
                              type="number"
                              maxLength={6}
                              placeholder="Aktivierungscode eingeben"
                              value={verificationCode}
                              validationError={ hasPhoneVerificationError ? 'Der eingegebene Aktivierungscode ist nicht richtig.' : null }
                              onChangeText={(value: number) => this.setState({ verificationCode: value })}
                            />
                          </>
                      }
                    </>
                  : null
                }
                </View>
              <View>
                <View className={ step !== 1 ? 'disabledStepWrapper stepWrapper' : 'stepWrapper' }>
                  <Image
                    source={require('../../../assets/img/step-2.png')}
                    className="stepImage"
                  />
                  <Text
                    className="highlight stepTitle"
                  >E-Mail-Adresse verifizieren</Text>
                </View>
                { step === 1
                  ? <Text style={{ marginTop: 10, marginBottom: 10 }}>Um die angegebene E-Mail-Adresse zu verifizieren, rufe bitte jetzt Dein E-Mail-Postfach ab. Öffne die E-Mail, die Du soeben von <a href="mailto:service@alm-app.eu">service@alm-app.eu</a> mit dem Betreff „Aktivierung Deines Benutzer-Accounts bei After Life Message“ erhalten hast und klicke bitte dort auf den Aktivierungslink.</Text>
                  : null
                }
              </View>
            </Content>

            { step === 0 && has_phone_verification_code
              ? <Button
                  type="gradient"
                  className="button"
                  containerStyle={{ marginTop: 'auto' }}
                  onPress={this.onVerifyPhone}
                >Mobilrufnummer bestätigen{ isSaving ? '...' : null }</Button>
              : <Button
                  type="gradient"
                  className="button"
                  containerStyle={{ marginTop: 'auto' }}
                  onPress={this.onFinishVerification}
                >Zum Login</Button>
            }

            { error
              ? <Text className="error">{error}</Text>
              : null
            }

            <Text className="highlight" style={{ marginTop: 15, textAlign: 'left' }}>
              Bitte die Verifizierung innerhalb von 15 Minuten abschließen. Danach wird der Registrierungsvorgang abgebrochen und muss erneut durchgeführt werden.
            </Text>
          </ElevatedView>
        </BackgroundView>
      </AppView>
    )
  }

  onVerifyPhone() {
    const { isSaving, email, verificationCode } = this.state

    if ( ! isSaving && email && verificationCode ) {
      this.setState({ error: null, isSaving: true }, () => {
        api({
          url: 'phone/verify',
          method: 'post',
          data: { email, code: verificationCode },
          onSuccess: (response) => {
            // console.debug(response)
            if ( response
              && response.data
              && response.data.status === 'ok'
              && typeof response.data.phone_verified === 'boolean'
            ) {
              if ( response.data.phone_verified ) {
                this.setState({
                  error: null,
                  isSaving: false,
                  step: 1,
                  hasPhoneVerificationError: false,
                })
              } else {
                this.setState({
                  error: null,
                  isSaving: false,
                  hasPhoneVerificationError: true,
                })
              }
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isSaving: false,
                hasPhoneVerificationError: false,
              })
            }
          },
          onError: (error) => {
            // console.debug(error.response)
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
              isSaving: false,
              hasPhoneVerificationError: false,
            })
          }
        })
      })
    } else {
      this.setState({
        error: 'Bitte Eingaben überprüfen.',
        isSaving: false,
        hasPhoneVerificationError: false,
      })
    }
  }

  onFinishVerification() {
    this.props.onNavigate('/login')
  }
}
