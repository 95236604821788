/**
 * ShoppingCart
 * @flow
 */
'use strict'

import React from 'react'
import { sortBy } from  'lodash'

import {
  AppView, BottomNavigation, Button, Colors, Icon, Image, Text, View,
} from '../../../components'
import Container from '@material-ui/core/Container'

import logo from '../../../assets/img/logo.png'


type Props = {
  orderData: ?Object,
  onNavigate: (orderData: ?Object, route: string) => void,
  onUpdate: (orderData: Object) => void,
}

type State = {
  shoppingCart: Array<?Object>,
  shoppingCartAmount: number,
}

const SHOPPING_CART_MAX = 99


export default class ShoppingCart extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    const shoppingData = props.orderData && props.orderData.shoppingData
      ? props.orderData.shoppingData
      : null

    if ( shoppingData ) {
      const { shoppingCart, shoppingCartAmount } = shoppingData

      if ( shoppingCart
        && shoppingCart.length
        && shoppingCartAmount
      ) {
        const sortedShoppingCart = sortBy(shoppingCart, 'price')

        this.state = {
          shoppingCart: sortedShoppingCart,
          shoppingCartAmount: shoppingCartAmount,
        }
      } else {
        this.state = {
          shoppingCart: [],
          shoppingCartAmount: 0,
        }
      }
    } else {
      this.state = {
        shoppingCart: [],
        shoppingCartAmount: 0,
      }
    }

    const ShoppingCart = (this: any)
    ShoppingCart.onDecreaseAmount = this.onDecreaseAmount.bind(this)
    ShoppingCart.onIncreaseAmount = this.onIncreaseAmount.bind(this)
    ShoppingCart.onRemove = this.onRemove.bind(this)
  }

  render() {
    const { orderData, onNavigate } = this.props
    const { shoppingCart, shoppingCartAmount } = this.state

    let shoppingCartTotalValue = 0

    return (
      <AppView
        id="ShoppingCart"
        background={1}
        className="d-block"
      >
        <Container maxWidth="xs">
          <View className="spreadWrapper">
            <View className="viewWrapper">
              <Image
                alt="ALM"
                className="logo static"
                source={logo}
              />
              <Image
                resizeMode="contain"
                source={require('../../../assets/img/checkout-progress-1.png')}
                className="progressBar"
              />
            </View>
            { shoppingCart && shoppingCart.length
              ? <View className="card">
                  <View className="row cardHeader">
                    <View className="column1">
                      <Text className="cardHeaderText">Produkt</Text>
                    </View>
                    <View className="column2">
                      <Text className="cardHeaderText">Anzahl</Text>
                    </View>
                    <View className="column3">
                      <Text className="cardHeaderText">Betrag</Text></View>
                    <View className="column4"></View>
                  </View>
                  { shoppingCart.map((product, index) => {
                      const { id, amount, credit_messages, credit_documents, price } = product

                      const productTotalValue = price * amount
                      shoppingCartTotalValue += productTotalValue

                      return (
                        <View key={index} className="row product">
                          <View className="column1 productColumn1">
                            <Image
                              source={require('../../../assets/img/message.png')}
                              className="productImage"
                            />
                            <Text className="productText">
                              <span className="bold">
                                { id === 1 ? 'Paket S' :
                                  id === 2 ? 'Paket M' :
                                  id === 3 ? 'Paket L' :
                                  null
                                }
                              </span><br />
                              {credit_messages} Botschaften<br />
                              {credit_documents} Dokumente
                            </Text>
                          </View>
                          <View className="column2">
                            <Icon
                              src={require('../../../assets/img/minus-box.png')}
                              className="controlsIcon"
                              onPress={() => this.onDecreaseAmount(id)}
                            />
                            <Text className="productAmount">{amount}</Text>
                            <Icon
                              src={require('../../../assets/img/plus-box.png')}
                              className="controlsIcon"
                              onPress={() => this.onIncreaseAmount(id)}
                            />
                          </View>
                          <View className="column3">
                            <Text className="productPrice">{productTotalValue.toFixed(2)}€</Text>
                          </View>
                          <View className="column4">
                            <Icon
                              src={require('../../../assets/img/trash.png')}
                              className="removeIcon"
                              onPress={() => this.onRemove(id)}
                            />
                          </View>
                        </View>
                      )
                    })
                  }
                  <View className="row footer">
                    <View className="column1"></View>
                    <View className="column2"></View>
                    <View className="column3">
                      <Text className="footerText">
                        insg.  {shoppingCartTotalValue.toFixed(2)}€
                      </Text>
                    </View>
                    <View className="column4">
                      <Text className="footerInfo">
                        incl. MwSt
                      </Text>
                    </View>
                  </View>
                </View>
              : <Text className="noProductsText">
                  Bitte Produkte in den Warenkorb legen.
                </Text>
            }
          </View>

          <View className="viewWrapper">
            { shoppingCart && shoppingCart.length
              ? <Button
                  className="button"
                  type="gradient"
                  onPress={() => onNavigate({
                    ...orderData,
                    shoppingData: { shoppingCart, shoppingCartAmount },
                  }, '/order/payment')}
                >Zahlungsart wählen</Button>
              : <Button
                  className="button"
                  type="gradient"
                  onPress={() => onNavigate(null, '/order/packages')}
                >Zu den Paketen</Button>
            }
            <BottomNavigation
              currentRoute="ShoppingCart"
              routes={[
                'Packages', 'ShoppingCart',
                'PaymentMethod', 'Confirmation',
              ]}
            />
          </View>
        </Container>
      </AppView>
    )
  }

  onDecreaseAmount(productId: number) {
    const { orderData, onUpdate } = this.props
    const { shoppingCart, shoppingCartAmount } = this.state

    const newShoppingCart = shoppingCart.filter((product) => {
      if ( product.id === productId ) {
        if ( product.amount > 1 ) {
          product.amount--
          return product
        }
        // products that would have amount 0 will not be returned
        // and therefore removed from array
      } else {
        return product
      }
    })
    const newShoppingCartAmount = shoppingCartAmount - 1

    this.setState({
      shoppingCart: newShoppingCart,
      shoppingCartAmount: newShoppingCartAmount,
    })
    onUpdate({
      ...orderData,
      shoppingData: {
        shoppingCart: newShoppingCart,
        shoppingCartAmount: newShoppingCartAmount,
      },
    })
  }

  onIncreaseAmount(productId: number) {
    const { orderData, onUpdate } = this.props
    const { shoppingCart, shoppingCartAmount } = this.state

    if ( shoppingCartAmount < SHOPPING_CART_MAX ) {
      const newShoppingCart = shoppingCart.map((product) => {
        if ( product.id === productId ) {
          product.amount++
        }
        return product
      })
      const newShoppingCartAmount = shoppingCartAmount + 1

      this.setState({
        shoppingCart: newShoppingCart,
        shoppingCartAmount: newShoppingCartAmount,
      })
      onUpdate({
        ...orderData,
        shoppingData: {
          shoppingCart: newShoppingCart,
          shoppingCartAmount: newShoppingCartAmount,
        },
      })
    }
  }

  onRemove(productId: number) {
    const { orderData, onUpdate } = this.props
    const { shoppingCart, shoppingCartAmount } = this.state

    let newShoppingCartAmount = shoppingCartAmount
    const newShoppingCart = shoppingCart.filter((product) => {
      if ( product.id === productId ) {
        newShoppingCartAmount -= product.amount
      }
      return product.id !== productId
    })

    this.setState({
      shoppingCart: newShoppingCart,
      shoppingCartAmount: newShoppingCartAmount,
    })
    onUpdate({
      ...orderData,
      shoppingData: {
        shoppingCart: newShoppingCart,
        shoppingCartAmount: newShoppingCartAmount,
      },
    })
  }
}
