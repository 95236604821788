/**
 * Input
 * @flow
 */
'use strict'

import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import { Colors, Image, View } from '.'


type Icon = {
  src: number,
  style?: Object,
  onPress?: () => void,
}

type Props = {
  error?: boolean,
  validationError?: string,
  icon?: Icon,
  infoText?: string,
  maxLength?: number,
  onChangeText: (value: string) => void,
}

type State = {
  // ...
}

const useStyles = makeStyles({
  root: {
    marginTop: 5,
    marginBottom: 5,
  },
  input: {
    color: Colors.primary,
    fontSize: 13,
    '&::placeholder': {
      color: Colors.secondary,
      opacity: 1,
    },
  },
})


export default function Input(props: Props) {
  const {
    error, validationError, icon, infoText, maxLength, onChangeText, ...other
  } = props
  const classes = useStyles()

  return (
    <View className="inputWrapper">
      <TextField
        autoComplete="off"
        className={classes.root}
        error={ error || validationError }
        fullWidth={true}
        helperText={ validationError || infoText }
        inputProps={{
          className: classes.input,
          maxLength: maxLength ? maxLength : undefined,
        }}
        size="small"
        variant="outlined"
        onChange={(event: Object) => onChangeText(event.target.value)}
        {...other}
      />
      { icon
        ? <View
            className="inputIconWrapper"
            onPress={icon.onPress}
          >
            <Image
              className="inputIcon"
              source={icon.src}
              style={icon.style}
            />
          </View>
        : null
      }
    </View>
  )
}
