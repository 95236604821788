/**
 * MessageSummary
 * @flow
 */
'use strict'

import React from 'react'
import moment from 'moment'

import { api } from '../../../helpers'
import {
  AppView, BackgroundView, Button, BottomNavigation, Checkbox, Colors,
  Content, ElevatedView, Icon, LoadingOverlay, LoadingSpinner, LegendaryButton,
  Preview, Popup, Text, View,
} from '../../../components'


type Props = {
  messageData: ?Object,
  onNavigate: (messageData: ?Object, route: string) => void,
}

type State = {
  error: ?string,
  termsError: boolean,
  validationErrors: ?Object,
  isSaving: boolean,
  showLoadingOverlay: boolean,
  showPopup: boolean,

  credit_messages: ?number,
  terms_confirmed: boolean,
}


export default class MessageSummary extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    // redirect if required params are not available
    if ( ! props.messageData
      || props.messageData.delivery_mode == undefined
      || ( props.messageData.delivery_mode === 1
            && ( ! props.messageData.delivery_date /* || props.messageData.delivery_interval == undefined */ )
         )
    ) {
      props.onNavigate(null, '/create-message/delivery')
    }

    this.state = {
      error: null,
      termsError: false,
      validationErrors: null,
      isSaving: false,
      showLoadingOverlay: false,
      showPopup: false,

      credit_messages: null,
      terms_confirmed: false,
    }

    const MessageSummary = (this: any)
    MessageSummary.onCreate = this.onCreate.bind(this)
  }

  componentDidMount() {
    const messageData = this.props.messageData

    if ( messageData
      && ( messageData.uploaded_file || messageData.uploaded_text )
      && messageData.title
      && messageData.selectedContacts
      && messageData.selectedContacts.length
      && ( messageData.delivery_mode === 0
           || ( messageData.delivery_mode === 1
             && messageData.delivery_date
             /* && messageData.delivery_interval != undefined */ )
         )
    ) {
      api({
        url: 'userCredit',
        method: 'get',
        onSuccess: (response) => {
          // console.debug(response)
          if ( response
            && response.data
            && response.data.status === 'ok'
            && response.data.credit_messages != undefined
          ) {
            this.setState({
              credit_messages: response.data.credit_messages,
            })
          }
        },
        onError: (error) => {
          // console.error(error.response)
          // proceed
        }
      })
    } else {
      this.setState({ error: 'Ein Fehler ist aufgetreten. Bitte Eingaben überprüfen.' })
    }
  }

  render() {
    const { messageData, onNavigate } = this.props
    const {
      error, termsError, validationErrors, isSaving,
      showPopup, showLoadingOverlay,
      credit_messages, terms_confirmed,
    } = this.state

    return (
      ! messageData ?
        <LoadingSpinner />
      : ! messageData && error ?
        <Text className="error">{error}</Text>
      : <AppView id="MessageSummary">
          <BackgroundView>
            <Text className="welcomeTitle">Botschaft speichern</Text>
            <ElevatedView>
              <Content>
                <Text
                  className="pagination"
                >Schritt 5 von 5</Text>
                <Text className="highlight">Botschaft - Zusammenfassung</Text>
              </Content>

              <LegendaryButton
                disabled={true}
                validationError={ validationErrors && validationErrors['title']
                  ? validationErrors['title'][0]
                  : null
                }
                shrink={true}
                title="Titel"
                value={messageData.title}
              />

              <LegendaryButton
                disabled={true}
                validationError={ validationErrors && validationErrors['contacts']
                  ? validationErrors['contacts'][0]
                  : null
                }
                shrink={true}
                title="Empfänger"
                value={ messageData && messageData.selectedContacts && messageData.selectedContacts.length
                  ? messageData.selectedContacts.map((selectedContact, index) => {
                      const { firstname, lastname } = selectedContact
                      const selectedContactsLength = messageData.selectedContacts.length
                      const isLastItem = index === selectedContactsLength - 1

                      return (
                        ! isLastItem
                        ? `${firstname} ${lastname}, `
                        : `${firstname} ${lastname}`
                      )
                    })
                  : null
                }
              />

              <LegendaryButton
                disabled={true}
                validationError={ validationErrors && validationErrors['delivery_mode'] ?
                    validationErrors['delivery_mode'][0]
                  : validationErrors && validationErrors['delivery_date'] ?
                    validationErrors['delivery_date'][0]
                  // : validationErrors && validationErrors['delivery_interval'] ?
                  //   validationErrors['delivery_interval'][0]
                  : null
                }
                shrink={true}
                title="Versendeoption"
                value={ messageData.delivery_mode === 0 ?
                    'Automatisch'
                  : messageData.delivery_mode === 1 ?
                    `am ${moment(messageData.delivery_date).format('DD.MM.YYYY')}`
                  // : messageData.delivery_interval === 0 ?
                  //   `einmalig am ${moment(messageData.delivery_date).format('DD.MM.YYYY')}`
                  // : messageData.delivery_interval === 1 ?
                  //   `jährlich am ${moment(messageData.delivery_date).format('DD.MM.')}`
                  // : messageData.delivery_interval === 2 ?
                  //   `2-jährlich am ${moment(messageData.delivery_date).format('DD.MM.')}`
                  // : messageData.delivery_interval === 3 ?
                  //   `3-jährlich am ${moment(messageData.delivery_date).format('DD.MM.')}`
                  // : messageData.delivery_interval === 4 ?
                  //   `5-jährlich am ${moment(messageData.delivery_date).format('DD.MM.')}`
                  // : messageData.delivery_interval === 5 ?
                  //   `10-jährlich am ${moment(messageData.delivery_date).format('DD.MM.')}`
                  : null
                }
              />

              { messageData && messageData.uploaded_file
                ? <LegendaryButton
                    disabled={true}
                    validationError={ validationErrors && validationErrors['uploaded_file']
                      ? validationErrors['uploaded_file'][0]
                      : null
                    }
                    shrink={true}
                    title="Zugewiesene Nachricht"
                  >
                    <Preview
                      disableAspectRatio={true}
                      className="previewImage"
                      src={ messageData && messageData.uploaded_file
                        ? messageData.uploaded_file.tmp
                        : null
                      }
                      type={ messageData && messageData.uploaded_file
                        ? messageData.uploaded_file.type
                        : null
                      }
                    />
                  </LegendaryButton>
                : null
              }
              { messageData && messageData.uploaded_text
                ? <LegendaryButton
                    disabled={true}
                    validationError={ validationErrors && validationErrors['uploaded_text']
                      ? validationErrors['uploaded_text'][0]
                      : null
                    }
                    multiline
                    rows="7"
                    shrink={true}
                    value={messageData.uploaded_text.trim()}
                    title="Zugewiesene Nachricht"
                  />
                : null
              }

              <View className="termsWrapper">
                <Checkbox
                  checked={terms_confirmed}
                  className="termsCheckbox"
                  onPress={() => this.setState({ terms_confirmed : ! terms_confirmed })}
                >
                  <Text className={ termsError && ! terms_confirmed ? 'termsText small error' : 'termsText small' }>
                    Ich bin damit einverstanden, dass die angegebenen Empfänger nach meinem Ableben diese Botschaft erhalten.
                  </Text>
                </Checkbox>
                <Icon
                  src={require('../../../assets/img/info.png')}
                  style={{ height: 29, width: 29 }}
                  onPress={() => this.setState({ showPopup: true })}
                />
              </View>
            </ElevatedView>

            { error
              ? <Text className="error">{error}</Text>
              : null
            }

            <View style={{ marginTop: 'auto' }}>
              <Button
                disabled={isSaving}
                className="saveButton"
                type="gradient"
                style={{ marginBottom: 0 }}
                onPress={this.onCreate}
              >Speichern{ isSaving ? '...' : null }</Button>
              { credit_messages && credit_messages > 0
                ? <Button
                    type="outlined"
                    onPress={() => onNavigate(null, '/credit')}
                  >Guthabenstand</Button>
                : <Button
                    type="outlined"
                    onPress={() => onNavigate(null, '/order/packages')}
                  >Guthaben aufladen</Button>
              }
            </View>

            <Popup
              visible={showPopup}
              title="Info - Botschaft Speichern"
              onClose={() => this.setState({ showPopup: false })}
            >
              <Text>Die Empfänger dieser Botschaft erhalten nach dem „Speichern“ von „service@alm-app.eu“ eine kurze, anonyme Information mit folgendem Inhalt an die angegebene E-Mail-Adresse:</Text>
              <Text style={{ margin: 0 }}>--------------------</Text>
              <Text className="italic">
                <Text style={{ fontSize: 12 }}>Sehr geehrte(r) ... ,</Text>
                <Text style={{ fontSize: 12 }}>ein/e Anwender:in von <a href="https://www.alm-app.eu" target="_blank">After Life Message</a> hat gerade eine persönliche Nachricht für Sie erstellt, welche Sie aber erst nach dessen Ableben erhalten werden. Diese Nachricht kann entweder eine persönliche Botschaft sein oder Ihnen aber auch den Zugriff zu wichtigen Dokumenten der Anwender:innen gewähren.</Text>
                <Text style={{ fontSize: 12 }}>Mit freundlichen Grüßen<br />Ihr Team von After Life Message<br /><a href="https://www.alm-app.eu" target="_blank">www.alm-app.eu</a></Text>
              </Text>
              <Text style={{ margin: 0 }}>--------------------</Text>
              <Text>Erst im traurigen Fall Deines Ablebens, welches von Deinen beiden Vertrauenspersonen bestätigt wurde, erhalten die Empfänger Deine Botschaft. Diese erhalten bis zu Deinem bestätigten Ableben keine weiteren Informationen.</Text>
            </Popup>

            { showLoadingOverlay
              ? <LoadingOverlay
                  onClose={() => this.setState({ showLoadingOverlay: false })}
                >
                  <Text className="highlight" style={{ textAlign: 'center', marginTop: 20 }}>
                    Die Datei wird gerade hochgeladen, dies kann je nach Größe einige Zeit in Anspruch nehmen.<br />
                    Bitte die Anwendung in der Zwischenzeit nicht schließen.
                  </Text>
                </LoadingOverlay>
              : null
            }

            <BottomNavigation
              currentRoute="MessageSummary"
              routes={[
                'MessageUpload', 'MessageTitle', 'MessageReceiver',
                'MessageDelivery', 'MessageSummary',
              ]}
              // onPress={(route: string) => navigation.navigate(route)}
            />
          </BackgroundView>
        </AppView>
    )
  }

  onCreate() {
    const messageData = this.props.messageData
    const { isSaving, terms_confirmed } = this.state

    if ( terms_confirmed ) {
      if ( ! isSaving
        && messageData
        && ( messageData.uploaded_file || messageData.uploaded_text )
        && messageData.title
        && messageData.selectedContacts
        && messageData.selectedContacts.length
        && ( messageData.delivery_mode === 0
             || ( messageData.delivery_mode === 1
               && messageData.delivery_date
               /* && messageData.delivery_interval != undefined */ )
           )
      ) {
        this.setState({ isSaving: true, showLoadingOverlay: true }, () => {
          const {
            uploaded_file, uploaded_text, title, selectedContacts,
            delivery_mode, delivery_date,
            // delivery_interval,
          } = messageData

          const selectedContactIds = selectedContacts.map(
            (selectedContact) => selectedContact.id
          )

          let formData = new FormData()

          if ( uploaded_file && ! uploaded_text ) {
            // uploaded_file.uri contains the file from file chooser
            // we dont need to add type or name property
            formData.append('uploaded_file', uploaded_file.uri)
          }

          const formDataSerialized = JSON.stringify({
            uploaded_text: uploaded_text && ! uploaded_file ? uploaded_text.trim() : undefined,
            title: title,
            contacts: selectedContactIds,
            delivery_mode: delivery_mode,
            // remove timezone offset
            delivery_date: delivery_mode === 1
              ? moment(delivery_date).format('YYYY-MM-DD')
              : undefined,
            // delivery_interval: delivery_interval,
            terms_confirmed: terms_confirmed,
            w: true,
          })

          formData.append('formDataSerialized', formDataSerialized)

          api({
            url: 'createMessage',
            method: 'post',
            data: formData,
            formData: true,
            // headers: { 'Content-Type': 'multipart/form-data' },
            onSuccess: (response) => {
              // console.debug(response)
              if ( response
                && response.data
                && response.data.status === 'ok'
              ) {
                this.setState({
                  error: null,
                  validationErrors: null,
                  // isSaving stays true so that the button stays disabled
                  // isSaving: false,
                  showLoadingOverlay: false,
                })
                this.props.onNavigate(null, '/create-message/success')
              } else {
                this.setState({
                  error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                  isSaving: false,
                  showLoadingOverlay: false,
                })
              }
            },
            onError: (error) => {
              // console.error(error)
              // console.error(error.response)
              if ( error
                && error.response
                && error.response.status == 422
                && error.response.data
                && error.response.data.errors
              ) {
                const errorInputNames = []
                Object.keys(error.response.data.errors).map((key) => {
                  errorInputNames.push(key)
                })
                this.setState({
                  error: 'Bitte Eingaben überprüfen.',
                  validationErrors: error.response.data.errors,
                  isSaving: false,
                  showLoadingOverlay: false,
                })
              } else if ( error
                && error.response
                && error.response.status == 403
              ) {
                this.setState({
                  error: 'Es ist nicht genügend Guthaben vorhanden, bitte ein neues Datenpaket erwerben.',
                  validationErrors: null,
                  isSaving: false,
                  showLoadingOverlay: false,
                })
              } else if ( error
                && error.response
                && error.response.status == 413
              ) {
                this.setState({
                  error: 'Die hochgeladene Datei ist zu groß.',
                  validationErrors: null,
                  isSaving: false,
                  showLoadingOverlay: false,
                })
              } else {
                this.setState({
                  error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                  isSaving: false,
                  showLoadingOverlay: false,
                })
              }
            }
          })

        })
      } else {
        this.setState({ error: 'Bitte Eingaben überprüfen.' })
      }
    } else {
      this.setState({ termsError: true })
    }
  }
}
