import {
  ADD_DOCUMENT_DATA,
  REMOVE_DOCUMENT_DATA,
  ADD_MESSAGE_DATA,
  REMOVE_MESSAGE_DATA,
  ADD_ORDER_DATA,
  REMOVE_ORDER_DATA,
} from '../actionTypes'

const initialState = {
  documentData: null,
  messageData: null,
  orderData: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_DOCUMENT_DATA: {
      const documentData = action.payload.documentData
      return {
        ...state,
        documentData,
      }
    }
    case REMOVE_DOCUMENT_DATA: {
      return {
        ...state,
        documentData: null,
      }
    }
    case ADD_MESSAGE_DATA: {
      const messageData = action.payload.messageData
      return {
        ...state,
        messageData,
      }
    }
    case REMOVE_MESSAGE_DATA: {
      return {
        ...state,
        messageData: null
      }
    }
    case ADD_ORDER_DATA: {
      const orderData = action.payload.orderData
      return {
        ...state,
        orderData,
      }
    }
    case REMOVE_ORDER_DATA: {
      return {
        ...state,
        orderData: null,
      }
    }
    default:
      return state
  }
}
