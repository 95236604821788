/**
 * Colors
 * @flow
 */
'use strict'

const Colors = {
  primary: '#08167e',
  secondary: '#808080',
  accent: '#4350ab',
  light: '#e1e5fe',
  lightBlue: '#f1f5f8',
  lightGray: '#d2d2d2',
  dark: '#333333',
  error: '#B00500',
}

export default Colors
