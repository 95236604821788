/**
 * AppView
 * @flow
 */
'use strict'

import React from 'react'

import { Icon, Image, Menu, View } from '.'
import Drawer from '@material-ui/core/Drawer'

import menu from '../assets/img/menu.png'


type Props = {
  background?: number,
  children: Object,
  className?: string,
  hideMenu?: boolean,
  id?: string,
}

type State = {
  isOpenDrawer: boolean,
}


export default class AppView extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      isOpenDrawer: false,
    }

    const AppView = (this: any)
    AppView.onToggleDrawer = this.onToggleDrawer.bind(this)
  }

  render() {
    const {
      background, children, className, hideMenu, id,
    } = this.props

    return (
      <div id={id} className="view">
        { ! hideMenu
          ? <header>
              <Icon
                alt="Menü"
                className="menuIcon"
                source={menu}
                onPress={() => this.onToggleDrawer(true)}
              />
            </header>
          : null
        }

        <main className={ className ? className : undefined }>{children}</main>

        { ! hideMenu
          ? <Drawer
              anchor="right"
              open={this.state.isOpenDrawer}
              onClose={() => this.onToggleDrawer(false)}
            >
              <Menu
                onToggleDrawer={(isOpenDrawer: boolean) => this.onToggleDrawer(isOpenDrawer)}
              />
            </Drawer>
          : null
        }

        { background != undefined
          ? <>
              <View className="backgroundWhite" />
              <View className={ background === 1 ?
                  'background1'
                : background === 2 ?
                  'background2'
                : background === 3 ?
                  'background3'
                : null
              } />
            </>
          : null
        }
      </div>
    )
  }

  onToggleDrawer(state: boolean) {
    this.setState({ isOpenDrawer: state })
  }
}
