/**
 * Profile
 * @flow
 */
'use strict'

import React from 'react'
import moment from 'moment'

import { api } from '../../helpers'

import {
  AppView, BackgroundView, Button, Colors, Dropdown, ElevatedView, Icon, Input,
  LegendaryButton, LoadingSpinner, Popup, Text, View,
} from '../../components'
import MomentUtils from "@date-io/moment"
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  error: ?string,
  loadingError: ?string,
  validationErrors: ?string,
  isLoading: boolean,
  isSaving: boolean,
  isDeleting: boolean,
  showDatePicker: boolean,
  showDeleteAlert: boolean,

  salutation: ?string,
  firstname: ?string,
  lastname: ?string,
  address: ?string,
  zipcode: ?string,
  city: ?string,
  country: ?string,
  birthdate: ?Object,
  email: ?string,
  phone: ?string,
}


export default class Profile extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      loadingError: null,
      validationErrors: null,
      isLoading: true,
      isSaving: false,
      isDeleting: false,
      showDatePicker: false,
      showDeleteAlert: false,

      salutation: null,
      firstname: null,
      lastname: null,
      address: null,
      zipcode: null,
      city: null,
      country: null,
      birthdate: null,
      email: null,
      phone: null,
    }

    const Profile = (this: any)
    Profile.onConfirmDelete = this.onConfirmDelete.bind(this)
    Profile.onSave = this.onSave.bind(this)
    Profile.onDelete = this.onDelete.bind(this)
  }

  componentDidMount() {
    api({
      url: 'userData',
      method: 'get',
      onSuccess: (response) => {
        // console.debug(response)
        if ( response
          && response.data
          && response.data.status === 'ok'
          && response.data.user
        ) {
          const user = response.data.user

          this.setState({
            salutation: user.salutation,
            firstname: user.firstname,
            lastname: user.lastname,
            address: user.address,
            zipcode: user.zipcode,
            city: user.city,
            country: user.country,
            birthdate: user.birthdate,
            email: user.email,
            phone: user.phone,
            loadingError: null,
            isLoading: false,
          })
        } else {
          this.setState({
            loadingError: 'Beim Laden der Daten ist ein Fehler aufgetreten. Bitte später erneut versuchen.',
            isLoading: false,
          })
        }
      },
      onError: (error) => {
        // console.error(error.response)
        this.setState({
          loadingError: 'Beim Laden der Daten ist ein Fehler aufgetreten. Bitte später erneut versuchen.',
          isLoading: false,
        })
      }
    })
  }

  render() {
    const {
      error, loadingError, validationErrors, isLoading, isSaving, isDeleting, showDatePicker, showDeleteAlert,
      salutation, firstname, lastname, address, zipcode, city,
      country, birthdate, email, phone,
    } = this.state

    // user has to be at least 18 years old
    const maximumDate = moment().subtract(18, 'years').toDate()

    return (
      <AppView id="Profile">
        <BackgroundView hideLogo={true}>
          <Text className="welcomeTitle">Profil</Text>
          { isLoading ?
              <LoadingSpinner />
            : loadingError ?
              <Text className="error">{loadingError}</Text>
            : <View>
                <ElevatedView>
                  <Dropdown
                    validationError={ validationErrors && validationErrors['salutation']
                      ? validationErrors['salutation'][0]
                      : null
                    }
                    items={[ { label: 'Frau', value: 1 }, { label: 'Herr', value: 2 } ]}
                    placeholder="Anrede"
                    value={salutation}
                    onValueChange={(value: string, index: number) => {
                      this.setState({ salutation: value })
                    }}
                  />
                  <Input
                    error={ error && ! firstname }
                    validationError={ validationErrors && validationErrors['firstname']
                      ? validationErrors['firstname'][0]
                      : null
                    }
                    placeholder="Vorname*"
                    type="text"
                    value={firstname}
                    onChangeText={(value: string) => this.setState({ firstname: value })}
                  />
                  <Input
                    error={ error && ! lastname }
                    validationError={ validationErrors && validationErrors['lastname']
                      ? validationErrors['lastname'][0]
                      : null
                    }
                    placeholder="Nachname*"
                    type="text"
                    value={lastname}
                    onChangeText={(value: string) => this.setState({ lastname: value })}
                  />
                  <Input
                    validationError={ validationErrors && validationErrors['address']
                      ? validationErrors['address'][0]
                      : null
                    }
                    placeholder="Straße / Hausnummer / Stiege / Tür"
                    type="text"
                    value={address}
                    onChangeText={(value: string) => this.setState({ address: value })}
                  />
                  <Input
                    validationError={ validationErrors && validationErrors['zipcode']
                      ? validationErrors['zipcode'][0]
                      : null
                    }
                    placeholder="PLZ"
                    type="text"
                    value={zipcode}
                    onChangeText={(value: string) => this.setState({ zipcode: value })}
                  />
                  <Input
                    error={ error && ! city }
                    validationError={ validationErrors && validationErrors['city']
                      ? validationErrors['city'][0]
                      : null
                    }
                    placeholder="Ort*"
                    type="text"
                    value={city}
                    onChangeText={(value: string) => this.setState({ city: value })}
                  />
                  <Dropdown
                    error={ error && ! country }
                    validationError={ validationErrors && validationErrors['country']
                      ? validationErrors['country'][0]
                      : null
                    }
                    items={[ { label: 'Österreich', value: 'Österreich' }, { label: 'Deutschland', value: 'Deutschland' }, { label: 'Schweiz', value: 'Schweiz' } ]}
                    placeholder="Land*"
                    value={country}
                    onValueChange={(value: string, index: number) => {
                      this.setState({ country: value })
                    }}
                  />
                  <LegendaryButton
                    error={ error && ! birthdate }
                    validationError={ validationErrors && validationErrors['birthdate']
                      ? validationErrors['birthdate'][0]
                      : null
                    }
                    title="Geburtsdatum*"
                    value={ birthdate ? moment(birthdate).format('DD.MM.YYYY') : null }
                    onPress={() => {
                      this.setState({ showDatePicker: ! showDatePicker })
                    }}
                  />
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      open={showDatePicker}
                      disableFuture
                      format="YYYY-MM-DD"
                      maxDate={maximumDate}
                      value={birthdate}
                      onClose={() => {
                        this.setState({ showDatePicker: false })
                      }}
                      onChange={(newDate: Object) => {
                        this.setState({
                          birthdate: newDate,
                          showDatePicker: false,
                        })
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <Input
                    error={ error && ! email }
                    validationError={ validationErrors && validationErrors['email']
                      ? validationErrors['email'][0]
                      : null
                    }
                    type="email"
                    placeholder="E-Mail*"
                    value={email}
                    onChangeText={(value: string) => this.setState({ email: value })}
                  />
                  <Input
                    error={ error && ! phone }
                    validationError={ validationErrors && validationErrors['phone']
                      ? validationErrors['phone'][0]
                      : null
                    }
                    type="text"
                    infoText={ validationErrors && validationErrors['phone']
                      ? null
                      : 'Bitte mit internationaler Vorwahl +43, +44 oder +41 angeben: z.B. +43660xxx'
                    }
                    placeholder="Mobilrufnummer*"
                    value={phone}
                    onChangeText={(value: string) => this.setState({ phone: value })}
                  />
                </ElevatedView>

                { error
                  ? <Text className="error">{error}</Text>
                  : null
                }

                <View style={{ marginTop: 'auto' }}>
                  <Button
                    type="gradient"
                    disabled={isSaving}
                    style={{ marginBottom: 0 }}
                    onPress={this.onSave}
                  >Aktualisieren{ isSaving ? '...' : null }</Button>

                  <Button
                    type="outlined"
                    disabled={isDeleting}
                    onPress={this.onConfirmDelete}
                  >Konto löschen{ isDeleting ? '...' : null }</Button>
                </View>

                <Popup
                  visible={showDeleteAlert}
                  title="Bist Du sicher?"
                  onConfirm={this.onDelete}
                  onClose={() => this.setState({ showDeleteAlert: false })}
                >
                  <Text>Möchtest Du Dein Konto wirklich löschen?<br />Diese Aktion kann nicht widerrufen werden.</Text>
                </Popup>
              </View>
          }
        </BackgroundView>
      </AppView>
    )
  }

  onConfirmDelete() {
    this.setState({ showDeleteAlert: true })
  }

  onSave() {
    const {
      isSaving, salutation, firstname, lastname, address, zipcode,
      city, country, birthdate, email, phone
    } = this.state

    if ( ! isSaving && firstname && lastname
        && city && country && birthdate && email && phone
    ) {
      this.setState({ isSaving: true }, () => {
        // remove timezone offset
        const formattedBirthdate = moment(birthdate).format('YYYY-MM-DD')

        api({
          url: 'updateData',
          method: 'post',
          data: {
            salutation, firstname, lastname,
            address, zipcode, city, country,
            birthdate: formattedBirthdate, email, phone,
          },
          onSuccess: (response) => {
            // console.debug(response)
            if ( response
              && response.data
              && response.data.status === 'ok'
            ) {
              this.setState({
                error: null,
                validationErrors: null,
                isSaving: false,
              })
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isSaving: false,
              })
            }
          },
          onError: (error) => {
            // console.debug(error.response)
            if ( error
              && error.response
              && error.response.status == 422
              && error.response.data
              && error.response.data.errors
            ) {
              const errorInputNames = []
              Object.keys(error.response.data.errors).map((key) => {
                errorInputNames.push(key)
              })
              this.setState({
                error: 'Bitte Eingaben überprüfen.',
                validationErrors: error.response.data.errors,
                isSaving: false,
              })
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isSaving: false,
              })
            }
          }
        })
      })
    } else {
      this.setState({ error: 'Bitte Felder ausfüllen.' })
    }
  }

  onDelete() {
    const { isDeleting } = this.state

    if ( ! isDeleting )
    {
      this.setState({ isDeleting: true, showDeleteAlert: false }, () => {
        api({
          url: 'deleteAccount',
          method: 'post',
          // data: {},
          onSuccess: (response) => {
            // console.debug(response)
            if ( response
              && response.data
              && response.data.status === 'ok'
            ) {
              this.setState({
                error: null,
                isDeleting: false,
              })

              this.props.history.push('/login')
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isDeleting: false,
              })
            }
          },
          onError: (error) => {
            // console.debug(error.response)
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
              isDeleting: false,
            })
            // if ( error
            //   && error.response
            //   && error.response.status == 422
            //   // && error.response.data
            //   // && error.response.data.errors
            // ) {
            //   this.setState({
            //     error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            //     isDeleting: false,
            //   })
            // } else {
            //   this.setState({
            //     error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            //     isDeleting: false,
            //   })
            // }
          },
          // onTokenError: (error) => {
          //   // console.error(error)
          //   this.setState({
          //     error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
          //     isDeleting: false,
          //   })
          // }
        })
      })
    }
  }
}
