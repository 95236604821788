/**
 * MessageDetails
 * @flow
 */
'use strict'

import React from 'react'
import moment from 'moment'

import { BASE_URL, SERVER_URL, api } from '../../../helpers'
import {
  AppView, BackgroundView, Button, Colors, ElevatedView, Image, LoadingSpinner, Preview, Text, View,
} from '../../../components'
import Container from '@material-ui/core/Container'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  error: ?string,
  isSaving: boolean,
  message: ?Object,
}

const MESSAGE_ITEM_HEIGHT = 50


export default class MessageDetails extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      isSaving: false,
      message: null,
    }

    const MessageDetails = (this: any)
    MessageDetails.onDelete = this.onDelete.bind(this)
  }

  componentDidMount() {
    const message = this.props.location.state && this.props.location.state.message
      ? this.props.location.state.message
      : null
    this.setState({ message })
  }

  render() {
    const { error, isSaving, message } = this.state

    return (
      <AppView id="MessageDetails">
        <BackgroundView hideLogo={true}>
          <Text className="welcomeTitle">Botschaften ansehen</Text>
          { ! message ?
              <>
                { error
                  ? <Text className="error">{error}</Text>
                  : <LoadingSpinner />
                }
              </>
            : <>
                <ElevatedView className="header">
                  <View className="messageItem">
                    <View className="messageWrapper">
                      <Image
                        source={ message.contacts && message.contacts.length === 1 && message.contacts[0] && message.contacts[0].profile_picture
                          ? `${BASE_URL}/profilePicture/${message.contacts[0].profile_picture}`
                          : require('../../../assets/img/profile-picture.jpg')
                        }
                        className="profilePicture"
                      />
                      <View>
                        <Text
                          className="messageReceivers"
                        >
                          { message.contacts && message.contacts.length
                            ? message.contacts.map((contact, index) => {
                                if (contact) {
                                  if ( index + 1 !== message.contacts.length ) {
                                    return `${contact.firstname} ${contact.lastname}, `
                                  } else {
                                    return `${contact.firstname} ${contact.lastname}`
                                  }
                                }
                              })
                            : null
                          }
                        </Text>
                        { message.delivery_mode === 0 ?
                            <Text className="messageDate">
                              Diese Nachricht wird automatisch versendet
                            </Text>
                          : message.delivery_mode === 1 && message.delivery_date ?
                            <Text className="messageDate">
                              {/* Diese Nachricht wird { message.delivery_interval === 0 ?
                                  `einmalig am ${moment(message.delivery_date).format('DD.MM.YYYY')}`
                                : message.delivery_interval === 1 ?
                                  `jährlich am ${moment(message.delivery_date).format('DD.MM.')}`
                                : message.delivery_interval === 2 ?
                                  `2-jährlich am ${moment(message.delivery_date).format('DD.MM.')}`
                                : message.delivery_interval === 3 ?
                                  `3-jährlich am ${moment(message.delivery_date).format('DD.MM.')}`
                                : message.delivery_interval === 4 ?
                                  `5-jährlich am ${moment(message.delivery_date).format('DD.MM.')}`
                                : message.delivery_interval === 5 ?
                                  `10-jährlich am ${moment(message.delivery_date).format('DD.MM.')}`
                                : null
                              } versendet */}
                              { moment().isAfter(message.delivery_date, 'day')
                                ? 'Diese Nachricht ist abgelaufen'
                                : `Diese Nachricht wird am ${moment(message.delivery_date).format('DD.MM.YYYY')} versendet`
                              }
                            </Text>
                          : null
                        }
                      </View>
                    </View>
                    <View className="messageIcon">
                      { message.delivery_mode === 1
                        ? <Image
                            source={require('../../../assets/img/clock.png')}
                            style={{ height: 20, width: 20 }}
                          />
                        : <Image
                            source={require('../../../assets/img/bookmark.png')}
                            style={{ height: 19, width: 19 }}
                          />
                      }
                    </View>
                  </View>
                </ElevatedView>

                <ElevatedView className="content">
                  <View className="messageContent">
                    <Text className="messageTitle">{message.title}</Text>

                    <Preview
                      disableAspectRatio={true}
                      style={{ height: 200 }}
                      src={`${BASE_URL}/messageFile/${message.uploaded_file}`}
                      type={message.uploaded_file_type}
                    />
                  </View>
                </ElevatedView>

                { error
                  ? <Text className="error">{error}</Text>
                  : null
                }

                <View style={{ marginTop: 'auto' }}>
                  <Button
                    type="outlined"
                    disabled={isSaving}
                    className="important"
                    style={{ marginBottom: 0 }}
                    onPress={this.onDelete}
                  >{ isSaving ? 'Löschen...' : 'Löschen' }</Button>
                  <Button type="gradient" onPress={() => this.props.history.goBack()}>
                    Zurück
                  </Button>
                </View>
              </>
          }
        </BackgroundView>
      </AppView>
    )
  }

  onDelete() {
    const { message, isSaving } = this.state

    if ( ! isSaving && message && message.id ) {
      this.setState({ isSaving: true }, () => {
        api({
          url: 'deleteMessage',
          method: 'post',
          data: { id: message.id },
          onSuccess: (response) => {
            // console.debug(response)
            if ( response
              && response.data
              && response.data.status === 'ok'
            ) {
              this.setState({
                error: null,
                isSaving: false,
              })
              this.props.history.push('/messages')
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isSaving: false,
              })
            }
          },
          onError: (error) => {
            // console.debug(error.response)
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
              isSaving: false,
            })
          }
        })
      })
    } else {
      this.setState({ error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.' })
    }
  }
}
