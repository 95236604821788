/**
 * Preview
 * @flow
 */
'use strict'

import React from 'react'

import { api } from '../helpers'
import { Colors, Icon, Image, ScrollView, Text, View } from '.'


type Props = {
  border?: boolean,
  disabled?: boolean,
  disableAspectRatio?: boolean,
  thumbnailStyle?: Object,
  src: string,
  style?: Object,
  type: 'image' | 'video' | 'audio' | 'pdf' | 'txt' | 'document',
}

type State = {
  error: ?string,
  isOpenLightbox: boolean,
  txtFileContent: ?string,
}


export default class Preview extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      isOpenLightbox: false,
      txtFileContent: null,
    }
  }

  componentDidMount() {
    if ( this.props.type === 'txt' ) {
      api({
        url: this.props.src,
        method: 'get',
        onSuccess: (response) => {
          if ( response
            && response.data
            && response.data.status === 'ok'
            && response.data.text != undefined
          ) {
            this.setState({ txtFileContent: response.data.text })
          } else {
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            })
          }
        },
        onError: (error) => {
          // console.error(error.response)
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
          })
        }
      })
    }
  }

  render() {
    const {
      border, disabled, disableAspectRatio,
      thumbnailStyle, src, style, type,
    } = this.props
    const { error, isOpenLightbox, txtFileContent } = this.state

    return (
      <View className={ ! disableAspectRatio ? 'Preview PreviewSmall' : 'Preview' }>
        <View
          className="PreviewWrapper"
          style={{
            height: type === 'txt' && ! disabled ? 0 : '100%',
            cursor: type === 'txt' || type === 'document' || type === 'audio'
              ? 'auto'
              : 'pointer'
          }}
          onPress={ disabled || type === 'txt' || type === 'document' || type === 'audio'
            ? undefined
            : () => this.setState({ isOpenLightbox: true })
          }
        >
          { type === 'image' ?
              <div
                className={ disableAspectRatio ? 'thumbnailNoRatio' : 'thumbnail' }
                style={{ backgroundImage: `url(${src})` }}
              ></div>
            : type === 'video' ?
              <video
                className="thumbnailVideo"
                src={src}
                muted
              />
            : type === 'pdf' || ( type === 'txt' && disabled ) || type === 'document' || type === 'audio' ?
              <View className={ border ? 'thumbnailDocumentWrapperBorder thumbnailDocumentWrapper' : 'thumbnailDocumentWrapper' }>
                <Image
                  className={ disableAspectRatio ? 'thumbnailNoRatio thumbnailDocument' : 'thumbnail thumbnailDocument' }
                  source={ type === 'pdf' ?
                      require('../assets/img/pdf.png')
                    : type === 'audio' ?
                      require('../assets/img/audio.png')
                    : require('../assets/img/document.png')
                  }
                  style={thumbnailStyle}
                />
                { ( type === 'document' || type === 'audio' ) && ! disabled
                  ? <Text>(keine Vorschau verfügbar)</Text>
                  : null
                }
              </View>
            : null
          }
          { ! disabled
            ? <View className="iconWrapper">
                <Image
                  source={(
                    type === 'image' ? require('../assets/img/camera-white.png') :
                    type === 'video' ? require('../assets/img/play.png') :
                    null
                  )}
                  className="icon"
                />
              </View>
            : null
          }
        </View>

        { type === 'txt' && ! disabled
          ? <ScrollView className="txtWrapper">
              <Text className="txt">
                { txtFileContent ? txtFileContent : 'Wird geladen...' }
              </Text>
            </ScrollView>
          : null
        }

        { isOpenLightbox
          ? <View
              className="PreviewModal"
              onPress={() => this.setState({ isOpenLightbox: false })}
            >
              <View className="closeIconWrapper">
                <Icon
                  src={require('../assets/img/close-white.png')}
                  className="closeIcon"
                  onPress={() => this.setState({ isOpenLightbox: false })}
                />
              </View>
              <View className="wrapper">
                { ! error && type === 'video'
                  ? <div className="video">
                      <video
                        src={src}
                        autoplay="true"
                      />
                    </div>
                  : ! error && type === 'pdf' ?
                    <div className="frameWrapper">
                      <iframe className="frame" src={src}></iframe>
                    </div>
                  : ! error && type === 'image' ?
                    <Image
                      source={src}
                      className="image"
                      onError={(event) => {
                        this.setState({ error: 'Die Datei konnte nicht angezeigt werden.' })
                      }}
                    />
                  : <View>
                      <Text className="error" className="errorText">{error}</Text>
                    </View>
                }
              </View>
            </View>
          : null
        }
      </View>
    )
  }
}
