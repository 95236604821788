/**
 * MessageDelivery
 * @flow
 */
'use strict'

import React from 'react'
import moment from 'moment'

import {
  AppView, ArrowButton, ArrowButtonPlain, BackgroundView, BottomNavigation, Button, Colors, Content, Dropdown,
  ElevatedView, Icon, Image, LegendaryButton, Picker, Popup, Text, TouchableOpacity, View,
} from '../../../components'
import MomentUtils from "@date-io/moment"
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'


type Props = {
  messageData: ?Object,
  onNavigate: (messageData: ?Object, route: string) => void,
}

type State = {
  error: ?string,
  delivery_mode: 0 | 1,
  delivery_date: ?Object,
  // delivery_interval: ?number,
  showDatePicker: boolean,
  // showIntervalPicker: boolean,
  showMode0Popup: boolean,
  showMode1Popup: boolean,
}

// NOTE: if interval is added or changed
// it also should be changed on the server-side
// const DELIVERY_INTERVAL_ITEMS = [
//   { label: 'nur einmal', value: 0 },
//   { label: 'jährlich', value: 1 },
//   { label: '2-jährlich', value: 2 },
//   { label: '3-jährlich', value: 3 },
//   { label: '5-jährlich', value: 4 },
//   { label: '10-jährlich', value: 5 },
// ]


export default class MessageDelivery extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    // redirect if required params are not available
    if ( ! props.messageData
      || ! props.messageData.selectedContacts
      || ! props.messageData.selectedContacts.length
    ) {
      props.onNavigate(null, '/create-message/receiver')
    }

    this.state = {
      error: null,
      delivery_mode: props.messageData && props.messageData.delivery_mode != undefined
        ? props.messageData.delivery_mode
        : 0,
      delivery_date: props.messageData && props.messageData.delivery_date
        ? props.messageData.delivery_date
        : null,
      // delivery_interval: props.messageData && props.messageData.delivery_interval != undefined
      //   ? props.messageData.delivery_interval
      //   : null,
      showDatePicker: false,
      // showIntervalPicker: false,
      showMode0Popup: false,
      showMode1Popup: false,
    }

    const MessageDelivery = (this: any)
    MessageDelivery.onContinue = this.onContinue.bind(this)
  }

  render() {
    const {
      error, delivery_mode, delivery_date,
      // delivery_interval, showIntervalPicker,
      showDatePicker, showMode0Popup, showMode1Popup,
    } = this.state

    const minimumDate = moment().add(1, 'days').toDate()

    return (
      <AppView id="MessageDelivery">
        <BackgroundView>
          <Text className="welcomeTitle">Versendeoptionen</Text>
          <ElevatedView>
            <View className="spreadWrapper">
              <Content>
                <Text className="pagination">Schritt 4 von 5</Text>
                <Text className="highlight">Versendeoptionen festlegen</Text>
                <Text>
                  Gib in diesem Schritt an, wann Deine Botschaft<br />versendet werden soll.<br />
                  <span className="infoGrey">(siehe auch Infobuttons)</span>
                </Text>
              </Content>
            </View>

            <View className="spreadWrapper">
              <View className="deliveryWrapper" style={{ marginTop: 20 }}>
                <TouchableOpacity
                  className="deliveryButtonWrapper"
                  onPress={() => {
                    this.setState({
                      error: null,
                      delivery_mode: 0,
                      delivery_date: null,
                      // delivery_interval: null,
                      showDatePicker: false,
                      // showIntervalPicker: false,
                    })
                  }}
                >
                  <Image
                    source={ delivery_mode === 0
                      ? require('../../../assets/img/checked-circle.png')
                      : require('../../../assets/img/unchecked-circle.png')
                    }
                    style={{ height: 20, width: 20, marginLeft: 12, marginRight: 12 }}
                  />
                  <Text className="deliveryButtonText">
                    AUTOMATISCH
                  </Text>
                </TouchableOpacity>
                <View className="deliveryInfoWrapper">
                  <Icon
                    src={require('../../../assets/img/info.png')}
                    className="deliveryInfoIcon"
                    onPress={() => this.setState({ showMode0Popup: true })}
                  />
                </View>
              </View>

              <View className="separatorWrapper">
                <View className="separatorLine" />
                <Text className="separatorText">oder</Text>
                <View className="separatorLine" />
              </View>

              <View className="deliveryWrapper" style={{ marginBottom: 10 }}>
                <TouchableOpacity
                  className="deliveryButtonWrapper"
                  onPress={() => {
                    this.setState({ error: null, delivery_mode: 1 })
                  }}
                >
                  <Image
                    source={ delivery_mode === 1
                      ? require('../../../assets/img/checked-circle.png')
                      : require('../../../assets/img/unchecked-circle.png')
                    }
                    style={{ height: 20, width: 20, marginLeft: 12, marginRight: 12 }}
                  />
                  <Text className="deliveryButtonText">
                    BESONDERER ZEITPUNKT
                  </Text>
                </TouchableOpacity>
                <View className="deliveryInfoWrapper">
                  <Icon
                    src={require('../../../assets/img/info.png')}
                    className="deliveryInfoIcon"
                    onPress={() => this.setState({ showMode1Popup: true })}
                  />
                </View>
              </View>

              <View className="optionsWrapper">
                { ! delivery_date
                  ? <ArrowButtonPlain
                      disabled={ delivery_mode === 0 }
                      error={ error && delivery_mode === 1 && ! delivery_date }
                      iconRight={{
                        src: require('../../../assets/img/calender.png'),
                        style: { height: 22, width: 22, marginTop: 7 },
                      }}
                      style={{ marginTop: 15 }}
                      onPress={() => {
                        this.setState({ showDatePicker: ! showDatePicker })
                      }}
                    >Datum angeben</ArrowButtonPlain>
                  : <LegendaryButton
                      shrink={true}
                      style={{ marginTop: 15 }}
                      title="Datum"
                      value={ moment(delivery_date).format('DD.MM.YYYY') }
                      onPress={() => {
                        this.setState({ showDatePicker: ! showDatePicker })
                      }}
                    />
                }
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    open={showDatePicker}
                    disablePast
                    format="YYYY-MM-DD"
                    minDate={minimumDate}
                    value={ delivery_date || minimumDate }
                    onClose={() => {
                      this.setState({ showDatePicker: false })
                    }}
                    onChange={(newDate: Object) => {
                      this.setState({
                        delivery_date: newDate,
                        showDatePicker: false,
                      })
                    }}
                  />
                </MuiPickersUtilsProvider>

                {/* <Dropdown
                  className="plain"
                  disabled={ delivery_mode === 0 }
                  error={ error && delivery_mode === 1 && delivery_interval == undefined }
                  items={DELIVERY_INTERVAL_ITEMS}
                  placeholder="Intervall auswählen"
                  value={delivery_interval}
                  onValueChange={(value: number, index: number) => {
                    this.setState({ delivery_interval: value })
                  }}
                /> */}
              </View>
            </View>
          </ElevatedView>
          <Button
            containerStyle={{ marginTop: 'auto' }}
            style={{ marginTop: 20 }}
            type="gradient"
            onPress={this.onContinue}
          >Weiter</Button>

          <Popup
            visible={showMode0Popup}
            title="Info - Versendeoption „AUTOMATISCH“"
            onClose={() => this.setState({ showMode0Popup: false })}
          >
            <Text>Die Botschaft wird versendet, sofort nachdem wir die Bestätigung über Dein Ableben von beiden Deiner Vertrauenspersonen erhalten haben.</Text>
            <Text><span className="highlightBold">Tipp:</span><br />Du kannst hier zum Beispiel „Deine letzten Worte“ an liebe Menschen richten.</Text>
            <Text><span className="highlightBold">Wichtig:</span><br />Bitte überprüfe regelmäßig die E-Mail-Adresse und Mobilrufnummer Deiner Vertrauenspersonen.</Text>
          </Popup>

          <Popup
            visible={showMode1Popup}
            title="Info - Versendeoption „BESONDERER ZEITPUNKT“"
            onClose={() => this.setState({ showMode1Popup: false })}
          >
            <Text>Die Botschaft wird unter der Voraussetzung, dass wir die Bestätigung über Dein Ableben von beiden Deiner Vertrauenspersonen erhalten haben, zum angegebenen Zeitpunkt versendet.</Text>
            <Text><span className="highlightBold">Tipp:</span><br />Du kannst zum Beispiel jemanden zum Geburtstag gratulieren oder Deinen Liebsten am Weihnachtsabend eine Botschaft zukommen lassen.</Text>
            <Text><span className="highlightBold">Wichtig:</span><br />Bitte überprüfe regelmäßig die E-Mail-Adresse und Mobilrufnummer Deiner Vertrauenspersonen.</Text>
          </Popup>

          <BottomNavigation
            currentRoute="MessageDelivery"
            routes={[
              'MessageUpload', 'MessageTitle', 'MessageReceiver',
              'MessageDelivery', 'MessageSummary',
            ]}
            // onPress={(route: string) => this.props.navigation.navigate(route)}
          />
        </BackgroundView>
      </AppView>
    )
  }

  onContinue() {
    const { messageData, onNavigate } = this.props
    const { delivery_mode, delivery_date } = this.state
    // let delivery_interval = this.state.delivery_interval

    // if user does not choose delivery_interval use interval 0 as default
    // if ( delivery_mode === 1 && delivery_interval == undefined ) {
    //   delivery_interval = 0
    // }

    if ( delivery_mode === 0
         || ( delivery_mode === 1 && delivery_date /* && delivery_interval != undefined */ )
    ) {
      this.setState({ error: null, showDatePicker: false })
      onNavigate({
        ...messageData,
        delivery_mode: delivery_mode,
        delivery_date: delivery_mode === 1
          ? delivery_date
          : undefined,
        // delivery_interval: delivery_mode === 1
        //   ? delivery_interval
        //   : undefined,
      }, '/create-message/summary')
    } else {
      this.setState({
        error: 'Bitte Versandoption auswählen.'
      })
    }
  }
}
