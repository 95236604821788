/**
 * Order
 * @flow
 */
'use strict'

import React from 'react'
import { connect } from 'react-redux'
import { addOrderData } from '../../../redux/actions'

import {
  Packages, ShoppingCart, PaymentMethod,
  PaymentStatus, Confirmation,
} from '.'


type Props = {
  history: Object,
  location: Object,
  match: Object,
  orderData: ?Object,
}

type State = {
  error: ?string,
}


class Order extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
    }

    const Order = (this: any)
    Order.onNavigate = this.onNavigate.bind(this)
    Order.onUpdate = this.onUpdate.bind(this)
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].className = ''
  }

  render() {
    const orderData = this.props.orderData

    // NOTE: if no route param is available, render first route
    const route = this.props.match && this.props.match.params && this.props.match.params.route
      ? this.props.match.params.route
      : 'packages'

    if ( route === 'packages' ) {
      document.getElementsByTagName('body')[0].className = ''
    } else {
      document.getElementsByTagName('body')[0].className = 'no-bg'
    }

    return (
      route === 'packages' ?
        <Packages
          error={this.state.error}
          orderData={orderData}
          onNavigate={this.onNavigate}
        />
      : route === 'shopping-cart' ?
        <ShoppingCart
          orderData={orderData}
          onNavigate={this.onNavigate}
          onUpdate={this.onUpdate}
        />
      : route === 'payment' ?
        <PaymentMethod
          ref={(component) => { window.PaymentMethod = component }}
          error={this.state.error}
          orderData={orderData}
          onNavigate={this.onNavigate}
        />
      : route === 'payment-status' ?
        <PaymentStatus
          location={this.props.location}
          onNavigate={this.onNavigate}
        />
      : route === 'success' ?
        <Confirmation
          onNavigate={this.onNavigate}
        />
      : null
    )
  }

  onNavigate(orderData: ?Object, route: string, error?: string) {
    if ( orderData ) {
      this.props.addOrderData(orderData)
    }

    if ( error ) {
      this.setState({ error }, () => {
        this.props.history.push(route)
      })
    } else {
      this.setState({ error: null }, () => {
        this.props.history.push(route)
      })
    }
  }

  onUpdate(orderData: Object) {
    this.props.addOrderData(orderData)
  }
}


const mapStateToProps = (state) => ({
  orderData: state.data.orderData
})
const mapDispatchToProps = (dispatch) => ({
  addOrderData: (orderData) => dispatch(addOrderData(orderData))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Order)
