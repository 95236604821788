/**
 * Image
 * @flow
 */
'use strict'

import React from 'react'


type Props = {
  className?: string,
  source: Object,
}

type State = {
  // ...
}


export default class Image extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
  }

  render() {
    const { className, source, ...other } = this.props

    return (
      <div className={className}>
        <img src={source} {...other} />
      </div>
    )
  }
}
