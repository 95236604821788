/**
 * LoadingSpinner
 * @flow
 */
'use strict'

import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'


type Props = {
  // ...
}

type State = {
  // ...
}


export default class LoadingSpinner extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
  }

  render() {
    return (
      <div className="LoadingSpinner view">
        <CircularProgress />
      </div>
    )
  }
}
