/**
 * Packages
 * @flow
 */
'use strict'

import React from 'react'

import {
  AppView, BackgroundView, BottomNavigation, Button, ButtonAccent,
  Colors, Content, Image, Text, View,
} from '../../../components'


type Props = {
  error: ?string,
  orderData: ?Object,
  onNavigate: (orderData: ?Object, route: string) => void,
}

type State = {
  shoppingCart: Array<?Object>,
  shoppingCartAmount: number,
}

const SHOPPING_CART_MAX = 99


export default class Packages extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    const shoppingData = props.orderData && props.orderData.shoppingData
      ? props.orderData.shoppingData
      : null

    if ( shoppingData ) {
      const { shoppingCart, shoppingCartAmount } = shoppingData

      if ( shoppingCart
        && shoppingCart.length
        && shoppingCartAmount
      ) {
        this.state = {
          shoppingCart: shoppingCart,
          shoppingCartAmount: shoppingCartAmount,
        }
      } else {
        this.state = {
          shoppingCart: [],
          shoppingCartAmount: 0,
        }
      }
    } else {
      this.state = {
        shoppingCart: [],
        shoppingCartAmount: 0,
      }
    }

    const Packages = (this: any)
    Packages.onAddToCart = this.onAddToCart.bind(this)
  }

  render() {
    const { error, orderData, onNavigate } = this.props
    const { shoppingCart, shoppingCartAmount } = this.state

    return (
      <AppView id="Packages">
        <BackgroundView>
          <View className="spreadWrapper">
            <View className="welcomeWrapper">
              <Content>
                <Text className="welcomeTitle">Wähle dein Paket</Text>
              </Content>
            </View>

            { error && shoppingCartAmount === 0
              ? <Text className="error">{error}</Text>
              : null
            }

            <View className="packagesWrapper">
              <View className="packagesBackground" />
              <View className="package">
                <Image
                   source={require('../../../assets/img/message-no-shadow.png')}
                   className="packageImage"
                 />
                <Text className="packageText">
                  Paket S<br />
                  3 Botschaften<br />
                  5 Dokumente
                </Text>
                <Text className="packagePrice">14,90€</Text>
                <ButtonAccent
                  className="packageButton"
                  // textStyle={styles.packageButtonText}
                  onPress={() => this.onAddToCart({ id: 1, credit_messages: 3, credit_documents: 5, price: 14.90 })}
                >Auswählen</ButtonAccent>
              </View>
              <View className="package highlightPackage">
                <View className="highlightPackageBackground" />
                <Image
                    source={require('../../../assets/img/message.png')}
                    className="packageImage highlightPackageImage"
                 />
                <Text className="packageText highlightPackageText">
                  Paket M<br />
                  5 Botschaften<br />
                  10 Dokumente
                </Text>
                <Text className="packagePrice highlightPackagePrice">19,90€</Text>
                <ButtonAccent
                  className="packageButton highlightPackageButton"
                  type="gradient"
                  // textStyle={styles.packageButtonText}
                  onPress={() => this.onAddToCart({ id: 2, credit_messages: 5, credit_documents: 10, price: 19.90 })}
                >Auswählen</ButtonAccent>
              </View>
              <View className="package">
                <Image
                   source={require('../../../assets/img/message-no-shadow.png')}
                   className="packageImage"
                 />
                <Text className="packageText">
                  Paket L<br />
                  10 Botschaften<br />
                  20 Dokumente
                </Text>
                <Text className="packagePrice">24,90€</Text>
                <ButtonAccent
                  className="packageButton"
                  type="gradient"
                  // textStyle={styles.packageButtonText}
                  onPress={() => this.onAddToCart({ id: 3, credit_messages: 10, credit_documents: 20, price: 24.90 })}
                >Auswählen</ButtonAccent>
              </View>
            </View>

            <Text className="packagesInfo">Eröffnungspreise gültig bis 31.12.2020</Text>
          </View>

          <View>
            { shoppingCartAmount
              ? <Button
                  counter={shoppingCartAmount}
                  className="button"
                  type="gradient"
                  onPress={() => onNavigate({
                    ...orderData,
                    shoppingData: { shoppingCart, shoppingCartAmount },
                  }, '/order/shopping-cart')}
                >Warenkorb</Button>
              : <View style={{ height: 70 }}></View>
            }
            <BottomNavigation
              currentRoute="Packages"
              routes={[
                'Packages', 'ShoppingCart',
                'PaymentMethod', 'Confirmation',
              ]}
            />
          </View>
        </BackgroundView>
      </AppView>
    )
  }

  onAddToCart(selectedProduct: Object) {
    const { shoppingCart, shoppingCartAmount } = this.state

    if ( shoppingCartAmount < SHOPPING_CART_MAX ) {
      const product = shoppingCart && shoppingCart.length
        ? shoppingCart.find((product) => product.id === selectedProduct.id)
        : undefined

      if ( product ) {
        const newShoppingCart = shoppingCart.map((product) => {
          if ( product.id === selectedProduct.id ) {
            product.amount++
          }
          return product
        })
        this.setState({
          shoppingCart: newShoppingCart,
          shoppingCartAmount: shoppingCartAmount + 1,
        })
      } else {
        this.setState({
          shoppingCart: [
            ...shoppingCart,
            { ...selectedProduct, amount: 1 },
          ],
          shoppingCartAmount: shoppingCartAmount + 1,
        })
      }
    }
  }
}
