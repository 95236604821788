/**
 * DocumentDetails
 * @flow
 */
'use strict'

import React from 'react'

import { BASE_URL, SERVER_URL, api } from '../../../helpers'
import {
  AppView, BackgroundView, Button, Colors, ElevatedView, Image, LoadingSpinner,
  Preview, Text, View,
} from '../../../components'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  error: ?string,
  isSaving: boolean,
  document: ?Object,
}

const DOCUMENT_ITEM_HEIGHT = 50


export default class DocumentDetails extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      isSaving: false,
      document: null,
    }

    const DocumentDetails = (this: any)
    DocumentDetails.onDelete = this.onDelete.bind(this)
  }

  componentDidMount() {
    const document = this.props.location.state && this.props.location.state.document
      ? this.props.location.state.document
      : null

    if (document) {
      this.setState({ document })
    } else {
      this.setState({
        error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
      })
    }
  }

  render() {
    const { error, isSaving, document } = this.state

    return (
      <AppView id="DocumentDetails">
        <BackgroundView hideLogo={true}>
          <Text className="welcomeTitle">Tresorinhalte ansehen</Text>
          { ! document ?
              <>
                { error
                  ? <Text className="error">{error}</Text>
                  : <LoadingSpinner />
                }
              </>
            : <>
                <ElevatedView className="header">
                  <View className="documentItem">
                    <View className="documentWrapper">
                      <Image
                        source={ document.contacts && document.contacts.length === 1 && document.contacts[0] && document.contacts[0].profile_picture
                          ? `${BASE_URL}/profilePicture/${document.contacts[0].profile_picture}`
                          : require('../../../assets/img/profile-picture.jpg')
                        }
                        className="profilePicture"
                      />
                      <View>
                        <Text
                          className="documentReceivers"
                        >
                          { document.contacts && document.contacts.length
                            ? document.contacts.map((contact, index) => {
                                if (contact) {
                                  if ( index + 1 !== document.contacts.length ) {
                                    return `${contact.firstname} ${contact.lastname}, `
                                  } else {
                                    return `${contact.firstname} ${contact.lastname}`
                                  }
                                }
                              })
                            : null
                          }
                        </Text>
                      </View>
                    </View>
                  </View>
                </ElevatedView>

                <ElevatedView className="content">
                  <View className="documentContent">
                    <Text className="documentTitle">{document.title}</Text>

                    <Preview
                      disableAspectRatio={true}
                      style={{ height: 200 }}
                      src={`${BASE_URL}/documentFile/${document.uploaded_file}`}
                      type={document.uploaded_file_type}
                    />
                  </View>
                </ElevatedView>

                { error
                  ? <Text className="error">{error}</Text>
                  : null
                }

                <View style={{ marginTop: 'auto' }}>
                  <Button
                    disabled={isSaving}
                    type="outlined"
                    style={{ marginBottom: 0 }}
                    onPress={this.onDelete}
                  >{ isSaving ? 'Löschen...' : 'Löschen' }</Button>
                  <Button onPress={() => this.props.history.goBack()}>
                    Zurück
                  </Button>
                </View>
              </>
          }
        </BackgroundView>
      </AppView>
    )
  }

  onDelete() {
    const { isSaving, document } = this.state

    if ( ! isSaving && document && document.id ) {
      this.setState({ isSaving: true }, () => {
        api({
          url: 'deleteDocument',
          method: 'post',
          data: { id: document.id },
          onSuccess: (response) => {
            // console.debug(response)
            if ( response
              && response.data
              && response.data.status === 'ok'
            ) {
              this.setState({
                error: null,
                isSaving: false,
              })
              this.props.history.push('/vault')
            } else {
              this.setState({
                error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                isSaving: false,
              })
            }
          },
          onError: (error) => {
            // console.debug(error.response)
            this.setState({
              error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
              isSaving: false,
            })
          }
        })
      })
    } else {
      this.setState({ error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.' })
    }
  }
}
