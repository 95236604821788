/**
 * Credit
 * @flow
 */
'use strict'

import React from 'react'

import { api } from '../../../helpers'
import {
  AppView, BackgroundView, Button, Content, ElevatedView, Icon, LoadingSpinner, Text, View,
} from '../../../components'
import Container from '@material-ui/core/Container'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  error: ?string,
  isLoading: boolean,

  credit_messages: ?number,
  credit_documents: ?number
}


export default class Credit extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      isLoading: true,

      credit_messages: null,
      credit_documents: null,
    }
  }

  componentDidMount() {
    api({
      url: 'userCredit',
      method: 'get',
      onSuccess: (response) => {
        // console.debug(response)
        if ( response
          && response.data
          && response.data.status === 'ok'
          && response.data.credit_messages != undefined
          && response.data.credit_documents != undefined
        ) {
          this.setState({
            credit_messages: response.data.credit_messages,
            credit_documents: response.data.credit_documents,
            error: null,
            isLoading: false,
          })
        } else {
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            isLoading: false,
          })
        }
      },
      onError: (error) => {
        // console.error(error.response)
        this.setState({
          error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
          isLoading: false,
        })
      }
    })
  }

  render() {
    const {
      error, isLoading,
      credit_messages, credit_documents,
    } = this.state

    return (
      <AppView id="Credit">
          { isLoading ?
              <LoadingSpinner />
            : <BackgroundView>
                <View className="spreadWrapper">
                  <ElevatedView>
                    { error ?
                        <Text className="error">{error}</Text>
                      : credit_messages != undefined && credit_documents != undefined ?
                        <Content style={{ alignItems: 'center' }}>
                          <Text className="welcomeTitle">Aktuelles Guthaben</Text>
                          <Text className="creditInfo">
                            {credit_messages} { credit_messages && credit_messages === 1
                              ? 'Botschaft'
                              : 'Botschaften'
                            }<br />
                            {credit_documents} { credit_documents && credit_documents === 1
                              ? 'Tresorinhalt'
                              : 'Tresorinhalte'
                            }
                          </Text>
                        </Content>
                      : <Content style={{ alignItems: 'center' }}>
                          <Text className="welcomeTitle">Guthabenstand</Text>
                          <Text className="highlight" style={{ textAlign: 'center' }}>
                            Es ist nicht genügend Guthaben vorhanden, bitte ein neues Datenpaket erwerben.
                          </Text>
                        </Content>
                    }
                  </ElevatedView>
                </View>
                <Button
                  containerStyle={{ marginTop: 'auto' }}
                  style={{ marginTop: 20 }}
                  type="gradient"
                  onPress={() => this.props.history.push('/order/packages')}
                >Guthaben aufladen</Button>
              </BackgroundView>
          }
      </AppView>
    )
  }
}
