/**
 * FlatList
 * @flow
 */
'use strict'

import React from 'react'


type Props = {
  data: Array<Object>,
  renderItem: (data: Object) => void,
}

type State = {
  // ...
}


export default class FlatList extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
  }

  render() {
    const { data, renderItem, ...other } = this.props

    return (
      <div className="FlatList" {...other}>
        { data && data.length
          ? data.map((item, index) => renderItem({item, index}) )
          : null
        }
      </div>
    )
  }
}
