/**
 * ArrowButtonPlain
 * @flow
 */
'use strict'

import React from 'react'

import { Colors, Image } from '.'
import { makeStyles } from '@material-ui/core/styles'
import { Button as MuiButton } from '@material-ui/core'

import nextGray from '../assets/img/next-gray.png'


type Icon = {
  src: number,
  style?: Object
}

type Props = {
  children: Object,
  className?: string,
  iconLeft?: Icon,
  iconRight?: ?Icon,
  onPress: () => void,
}

type State = {
  // ...
}


const useStyles = makeStyles({
  root: {
    fontSize: 13,
    marginTop: 5,
    marginBottom: 5,
    backgroundColor: 'transparent',
    color: '#808080 !important',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    height: 40,
    borderColor: '#808080',
    textTransform: 'unset',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: '#808080',
    },
  },
})


export default function ArrowButtonPlain(props) {
  const {
    children, className, iconLeft, iconRight, onPress, ...other
  } = props
  const classes = useStyles()

  const iconRightSrc = iconRight && iconRight.src
    ? iconRight.src
    : nextGray

  return (
    <MuiButton
      className={classes.root}
      color="primary"
      disableRipple={true}
      endIcon={ iconRightSrc
        ? <Image
            className="arrowButtonPlainEndIcon"
            source={iconRightSrc}
            style={ iconRight && iconRight.style ? iconRight.style : undefined }
          />
        : undefined
      }
      fullWidth={true}
      size="medium"
      startIcon={ iconLeft
        ? <Image
            className="arrowButtonPlainStartIcon"
            source={iconLeft.src}
            style={iconLeft.style}
          />
        : undefined
      }
      variant="outlined"
      onClick={onPress}
      {...other}
    >{children}</MuiButton>
  )
}
