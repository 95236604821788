/**
 * ButtonAccent
 * @flow
 */
'use strict'

import React from 'react'

import { Colors } from '.'
import { makeStyles } from '@material-ui/core/styles'
import { Button as MuiButton } from '@material-ui/core'


type Props = {
  children: Object,
  className?: string,
  onPress: () => void,
}

type State = {
  // ...
}


const useStyles = makeStyles({
  root: {
    marginTop: 5,
    marginBottom: 5,
  },
})


export default function ButtonAccent(props) {
  const { children, onPress, ...other } = props
  const classes = useStyles()

  return (
    <div className="ButtonAccent">
      <MuiButton
        className={classes.root}
        color="primary"
        fullWidth={true}
        size="medium"
        variant="contained"
        onClick={onPress}
        {...other}
      >{children}</MuiButton>
    </div>
  )
}
