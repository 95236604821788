/**
 * Messages
 * @flow
 */
'use strict'

import React from 'react'
import moment from 'moment'
import { filter } from 'lodash'

import { BASE_URL, SERVER_URL, api } from '../../../helpers'
import {
  AppView, BackgroundView, Button, Colors, ElevatedView, FlatList, Icon, Image,
  Input, LoadingSpinner, Preview, Text, TouchableOpacity, View,
} from '../../../components'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  error: ?string,
  isLoading: boolean,

  allMessages: Array<Object>,
  filteredMessages: Array<Object>,
  filter: 0 | 1 | 2,
  searchResults: Array<Object>,
  searchQuery: string,
}

const MESSAGE_ITEM_HEIGHT = 70


export default class Messages extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      isLoading: true,

      allMessages: [],
      filteredMessages: [],
      filter: 0,
      searchResults: [],
      searchQuery: '',
    }

    const Messages = (this: any)
    Messages.onFilterMessages = this.onFilterMessages.bind(this)
    Messages.onSearchMessages = this.onSearchMessages.bind(this)
  }

  componentDidMount() {
    api({
      url: 'userMessages',
      method: 'get',
      onSuccess: (response) => {
        // console.debug(response)
        if ( response
          && response.data
          && response.data.status === 'ok'
          && response.data.messages != undefined
        ) {
          const messages = response.data.messages
          const filter = this.props.location.state && this.props.location.state.filter != undefined
            ? this.props.location.state.filter
            : null

          if ( filter ) {
            this.setState({
              error: null,
              isLoading: false,
              allMessages: messages,
            }, () => {
              this.onFilterMessages(filter)
            })
          } else {
            this.setState({
              error: null,
              isLoading: false,
              allMessages: messages,
              filteredMessages: messages,
            })
          }
        } else {
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            isLoading: false,
          })
        }
      },
      onError: (error) => {
        console.error(error.response)
        this.setState({
          error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
          isLoading: false,
        })
      }
    })
  }

  render() {
    const {
      error, isLoading,
      allMessages, filter, filteredMessages, searchResults, searchQuery,
    } = this.state
    const today = moment()

    return (
      <AppView id="Messages">
        <BackgroundView hideLogo={true}>
          <Text className="welcomeTitle">Meine Botschaften</Text>
          { isLoading ?
              <LoadingSpinner />
            : allMessages && allMessages.length ?
              <>
                <Input
                  icon={{
                    src: require('../../../assets/img/search.png'),
                    style: { height: 14, width: 14 }
                  }}
                  placeholder="Suchen"
                  placeholderTextColor={Colors.lightGray}
                  className="searchField"
                  value={searchQuery}
                  onChangeText={(value: string) => this.onSearchMessages(value)}
                />

                <View className="filter">
                  <TouchableOpacity onPress={() => this.onFilterMessages(0)}>
                    <Text className={ filter === 0 ? 'currentFilter filterText' : 'filterText' }>
                      Alle
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => this.onFilterMessages(2)}>
                    <Text className={ filter === 2 ? 'currentFilter filterText' : 'filterText' }>
                      Automatisch
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => this.onFilterMessages(1)}>
                    <Text className={ filter === 1 ? 'currentFilter filterText' : 'filterText' }>
                      Besonderer<br />Zeitpunkt
                    </Text>
                  </TouchableOpacity>
                </View>

                <FlatList
                  data={ searchQuery ? searchResults : filteredMessages }
                  renderItem={({ item, index }) => {
                    const {
                      id, contacts, uploaded_file, uploaded_file_type,
                      title, delivery_mode, delivery_date,
                      // delivery_interval,
                    } = item

                    return (
                      <TouchableOpacity
                        onPress={() => {
                          this.props.history.push({
                            pathname: '/message',
                            state: {
                              message: item
                            }
                          })
                        }}
                        className="messageItem"
                      >
                        <View className="messageWrapper">
                          <Image
                            source={ contacts && contacts.length === 1 && contacts[0] && contacts[0].profile_picture
                              ? `${BASE_URL}/profilePicture/${contacts[0].profile_picture}`
                              : require('../../../assets/img/profile-picture.jpg')
                            }
                            className="profilePicture"
                          />
                          <View className="messageInfoWrapper">
                            <View className="messageFirstRow">
                              <View className="messageReceiversWrapper">
                                <Text
                                  ellipsizeMode="tail"
                                  numberOfLines={1}
                                  className="messageReceivers"
                                >
                                  { contacts && contacts.length
                                    ? contacts.map((contact, index) => {
                                        if ( index + 1 !== contacts.length ) {
                                          return `${contact.firstname} ${contact.lastname}, `
                                        } else {
                                          return `${contact.firstname} ${contact.lastname}`
                                        }
                                      })
                                    : null
                                  }
                                </Text>
                              </View>
                              <View className="messageIcon">
                                { delivery_mode === 1
                                  ? <Image
                                      source={require('../../../assets/img/clock.png')}
                                      style={{ height: 17, width: 17 }}
                                    />
                                  : <Image
                                      source={require('../../../assets/img/bookmark.png')}
                                      style={{ height: 16, width: 16 }}
                                    />
                                }
                              </View>
                            </View>
                            { delivery_mode === 1 && delivery_date
                              ? <Text
                                  ellipsizeMode="tail"
                                  numberOfLines={1}
                                  className="messageDate"
                                >
                                  {/* delivery_interval === 0 ?
                                      `${moment(delivery_date).format('DD.MM.YYYY')}`
                                    : delivery_interval === 1 ?
                                      `jährlich am ${moment(delivery_date).format('DD.MM.')}`
                                    : delivery_interval === 2 ?
                                      `2-jährlich am ${moment(delivery_date).format('DD.MM.')}`
                                    : delivery_interval === 3 ?
                                      `3-jährlich am ${moment(delivery_date).format('DD.MM.')}`
                                    : delivery_interval === 4 ?
                                      `5-jährlich am ${moment(delivery_date).format('DD.MM.')}`
                                    : delivery_interval === 5 ?
                                      `10-jährlich am ${moment(delivery_date).format('DD.MM.')}`
                                    : null
                                  */}
                                  { today.isAfter(delivery_date, 'day')
                                    ? 'Abgelaufen'
                                    : moment(delivery_date).format('DD.MM.YYYY')
                                  }
                                </Text>
                              : null
                            }
                            <Text
                              ellipsizeMode="tail"
                              numberOfLines={1}
                              className="messageTitle"
                            >{title}</Text>
                          </View>
                        </View>
                        <View className="previewWrapper">
                          <Preview
                            disabled={true}
                            thumbnailStyle={{
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                            }}
                            src={`${BASE_URL}/messageThumbnail/${uploaded_file}`}
                            type={uploaded_file_type}
                          />
                        </View>
                      </TouchableOpacity>
                    )
                  }}
                  style={{ width: '100%', marginTop: 10 }}
                />
              </>
            : <ElevatedView>
                <View className="wrapper">
                  { error
                    ? <Text className="error">{error}</Text>
                    : <Text>Noch keine Botschaften vorhanden.</Text>
                  }
                </View>
              </ElevatedView>
          }
          <Button
            containerStyle={{ marginTop: 'auto' }}
            type="gradient"
            onPress={() => this.props.history.push('/create-message')}
          >Neue Botschaft hinzufügen</Button>
        </BackgroundView>
      </AppView>
    )
  }

  onFilterMessages(filter: 0 | 1 | 2) {
    const { allMessages, searchQuery } = this.state

    if ( filter === 0 ) {
      this.setState({ filteredMessages: allMessages, filter: 0 }, () => {
        if ( searchQuery ) {
          this.onSearchMessages(searchQuery)
        }
      })
    } else if ( filter === 1 ) {
      const filteredMessages = allMessages.filter((message) => {
        return message.delivery_mode === 1
      })
      this.setState({ filteredMessages: filteredMessages, filter: 1 }, () => {
        if ( searchQuery ) {
          this.onSearchMessages(searchQuery)
        }
      })
    } else if ( filter === 2 ) {
      const filteredMessages = allMessages.filter((message) => {
        return message.delivery_mode === 0
      })
      this.setState({ filteredMessages: filteredMessages, filter: 2 }, () => {
        if ( searchQuery ) {
          this.onSearchMessages(searchQuery)
        }
      })
    }
  }

  onSearchMessages(searchQuery: string) {
    const { filteredMessages, filter } = this.state

    if ( searchQuery ) {
      const searchResults = filteredMessages.filter((message) => {
        const cleanSearchQuery = searchQuery.trim().toLowerCase()
        const cleanMessageTitle = message.title.toLowerCase()

        const filteredContactIds = message.contacts.filter((contact) => {
          if (
            contact.firstname.toLowerCase().indexOf(cleanSearchQuery) !== -1
            || contact.lastname.toLowerCase().indexOf(cleanSearchQuery) !== -1
          ) {
            return contact.id
          }
        });

        if (
          ( message.title && cleanMessageTitle.indexOf(cleanSearchQuery) !== -1 )
          || ( filteredContactIds && filteredContactIds.length )
        ) {
          return message
        }
      })
      this.setState({
        searchResults: searchResults,
        searchQuery: searchQuery,
      })
    } else {
      this.setState({ searchResults: [], searchQuery: '' })
    }
  }
}
