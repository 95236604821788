/**
 * helpers
 * @flow
 */
'use strict'

import axios from 'axios'


export const PRODUCTION = true
export const SERVER_URL = PRODUCTION
? 'https://app.alm-app.eu'
: 'http://localhost:8000'
export const BASE_URL = `${SERVER_URL}/api`
export const PORTAL_URL = PRODUCTION
? 'https://portal.alm-app.eu'
: 'http://localhost:3000'


type ApiRequestConfig = {
  url: string,
  method: 'get' | 'post',
  data?: Object,
  formData?: boolean,
  headers?: Object,
  onSuccess: (response: Object) => void,
  onError: (error: Object) => void,
}


export function api(config: ApiRequestConfig) {
  const { url, method, data, formData, headers, onSuccess, onError } = config

  axios({
    url,
    method,
    baseURL: `${BASE_URL}/`,
    data: formData
      ? data
      : { ...data, w: true },
    headers,
    // allow cookies
    withCredentials: true,
  })
  .then(function (response) {
    onSuccess(response)
  })
  .catch(function (error) {
    onError(error)
  })
}
