/**
 * Confirmation
 * @flow
 */
'use strict'

import React from 'react'

import { api } from '../../../helpers'
import {
  AppView, BottomNavigation, Button, Image,
  LoadingSpinner, Text, View,
} from '../../../components'
import Container from '@material-ui/core/Container'

import logo from '../../../assets/img/logo.png'


type Props = {
  orderData: ?Object,
  onNavigate: (orderData: ?Object, route: string) => void,
}

type State = {
  error: ?string,
  isLoading: boolean,

  credit_messages: ?number,
  credit_documents: ?number
}


export default class Confirmation extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      isLoading: true,

      credit_messages: null,
      credit_documents: null,
    }

    // const Confirmation = (this: any)
    // Confirmation.onEvent = this.onEvent.bind(this)
  }

  componentDidMount() {
    api({
      url: 'userCredit',
      method: 'get',
      onSuccess: (response) => {
        // console.debug(response)
        if ( response
          && response.data
          && response.data.status === 'ok'
          && response.data.credit_messages != undefined
          && response.data.credit_documents != undefined
        ) {
          this.setState({
            credit_messages: response.data.credit_messages,
            credit_documents: response.data.credit_documents,
            error: null,
            isLoading: false,
          })
        } else {
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            isLoading: false,
          })
        }
      },
      onError: (error) => {
        // console.error(error.response)
        this.setState({
          error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
          isLoading: false,
        })
      }
    })
  }

  render() {
    const {
      error, isLoading,
      credit_messages, credit_documents,
    } = this.state

    return (
      <AppView
        id="Confirmation"
        background={3}
        className="d-block"
      >
        { isLoading
          ? <LoadingSpinner />
          : <>
              <Container maxWidth="xs">
                <View className="spreadWrapper">
                  <Image
                    alt="ALM"
                    className="logo static"
                    source={logo}
                  />
                  <Image
                    resizeMode="contain"
                    source={require('../../../assets/img/checkout-progress-3.png')}
                    className="progressBar"
                  />
                </View>

                <View className="spreadWrapper">
                  <Image
                    resizeMode="contain"
                    source={require('../../../assets/img/payment-confirmation.png')}
                    className="image"
                  />
                  <Text className="welcomeTitle" className="title">
                    VIELEN DANK FÜR<br />DEINE BESTELLUNG
                  </Text>
                  { ! error && credit_messages != undefined && credit_documents != undefined
                    ? <Text>
                        Du hast nun<br />
                        <span className="creditText">{credit_messages} Botschaften</span><br />
                        und <br />
                        <span className="creditText">{credit_documents} Tresorinhalte</span><br />
                        zur Verfügung
                      </Text>
                    : null
                  }
                </View>

                <View className="view">
                  { false
                    ? <Button
                        className="button"
                        type="gradient"
                        onPress={() => this.props.onNavigate(null, '/create-message/summary')}
                      >Zur Botschaft</Button>
                    : <Button
                        className="button"
                        type="gradient"
                        onPress={() => this.props.onNavigate(null, '/')}
                      >Home</Button>
                  }
                </View>
              </Container>
            </>
        }
      </AppView>
    )
  }
}
