/**
 * MessageSuccess
 * @flow
 */
'use strict'

import React from 'react'

import { AppView, BackgroundView, Button, ElevatedView, Text, View } from '../../../components'

type Props = {
  onNavigate: (path: string) => void,
}

type State = {
  // ...
}


export default class MessageSuccess extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    // this.state = {}

    // const MessageSuccess = (this: any)
    // MessageSuccess.onEvent = this.onEvent.bind(this)
  }

  render() {
    return (
      <AppView id="MessageSuccess">
        <BackgroundView>
          <Text className="welcomeTitle">Botschaft erstellt</Text>
          <ElevatedView>
            <Text className="highlightTitle">
              Die Botschaft wurde erfolgreich erstellt.
            </Text>
          </ElevatedView>
          <View style={{ marginTop: 'auto' }}>
            <Button
              type="gradient"
              style={{ marginBottom: 0 }}
              onPress={() => this.props.onNavigate(null, '/messages')}
            >Zu meinen Botschaften</Button>
            <Button
              type="outlined"
              onPress={() => this.props.onNavigate(null, '/')}
            >Zurück zum Hauptmenü</Button>
          </View>
        </BackgroundView>
      </AppView>
    )
  }
}
