/**
 * ClientStatistics
 * @flow
 */
'use strict'

import React from 'react'
import moment from 'moment'
import { groupBy, orderBy } from 'lodash'

import { api } from '../../helpers'

import { AppView, LoadingSpinner, Text, View } from '../../components'
import Container from '@material-ui/core/Container'


type Props = {
  history: Object,
  location: Object,
  match: Object,
}

type State = {
  error: ?string,
  isLoading: boolean,

  clientsBySalutation: Array<?Object>,
  clientsByAge: Array<?Object>,
  clientsByCountry: Array<?Object>,
  clientsByZipcode: Array<?Object>,
}


export default class ClientStatistics extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    this.state = {
      error: null,
      isLoading: true,

      clientsBySalutation: [],
      clientsByAge: [],
      clientsByCountry: [],
      clientsByZipcode: [],
    }

    // const ClientStatistics = (this: any)
    // ClientStatistics.onEvent = this.onEvent.bind(this)
  }

  componentDidMount() {
    api({
      url: 'clientStatistics',
      method: 'get',
      onSuccess: (response) => {
        // console.debug(response)
        if ( response
          && response.data
          && response.data.status === 'ok'
          && response.data.clients != undefined
        ) {
          const clients = response.data.clients

          // calculate data for statistics
          const clientsBySalutation = groupBy(clients, 'salutation')
          const clientsByCountry = groupBy(clients, 'country')
          const orderedClientsByZipcode = orderBy(clients, ['zipcode'])
          const clientsByZipcode = groupBy(orderedClientsByZipcode, 'zipcode')

          // group by age 18-29, 30-49, 50-69, 70+
          // const orderedClientsByBirthdate = orderBy(clients, ['birthdate'], ['desc'])
          const clientsByAge = groupBy(clients, (client) => {
            const age = moment().diff(client.birthdate, 'years')
            return (
              age >= 18 && age <= 29 ? '18-29'
              : age >= 30 && age <= 49 ? '30-49'
              : age >= 50 && age <= 69 ? '50-69'
              : age >= 70 ? '70+'
              : '–'
            )
          })

          this.setState({
            error: null,
            isLoading: false,
            clientsBySalutation,
            clientsByAge,
            clientsByCountry,
            clientsByZipcode,
          })
        } else {
          this.setState({
            error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
            isLoading: false,
          })
        }
      },
      onError: (error) => {
        // console.error(error.response)
        this.setState({
          error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
          isLoading: false,
        })
      }
    })
  }

  render() {
    const {
      error, isLoading,
      clientsBySalutation, clientsByAge, clientsByCountry, clientsByZipcode,
    } = this.state

    return (
      <AppView
        id="ClientStatistics"
        hideLogo={true}
      >
        <Container maxWidth="xs">
          <Text className="welcomeTitle">Benutzerstatistik</Text>
          { isLoading ?
              <LoadingSpinner />
            : error ?
              <Text className="error">{error}</Text>
            : <>
                <Text className="highlightTitle">Benutzer nach Anrede</Text>
                <Text>Frau: { clientsBySalutation[1] ? clientsBySalutation[1].length : 0 }</Text>
                <Text>Herr: { clientsBySalutation[2] ? clientsBySalutation[2].length : 0 }</Text>
                <Text>Keine Angabe: { clientsBySalutation[null] ? clientsBySalutation[null].length : 0 }</Text>

                <Text className="highlightTitle">Benutzer nach Altersgruppe</Text>
                <Text>18-29: { clientsByAge['18-29'] ? clientsByAge['18-29'].length : 0 }</Text>
                <Text>30-49: { clientsByAge['30-49'] ? clientsByAge['30-49'].length : 0 }</Text>
                <Text>50-69: { clientsByAge['50-69'] ? clientsByAge['50-69'].length : 0 }</Text>
                <Text>70+: { clientsByAge['70+'] ? clientsByAge['70+'].length : 0 }</Text>

                <Text className="highlightTitle">Benutzer nach Land</Text>
                <Text>Österreich: { clientsByCountry['Österreich'] ? clientsByCountry['Österreich'].length : 0 }</Text>
                <Text>Deutschland: { clientsByCountry['Deutschland'] ? clientsByCountry['Deutschland'].length : 0 }</Text>
                <Text>Schweiz: { clientsByCountry['Schweiz'] ? clientsByCountry['Schweiz'].length : 0 }</Text>

                <Text className="highlightTitle">Benutzer nach Postleitzahl</Text>
                { Object.keys(clientsByZipcode).map((zipcode, index) => (
                    <Text>{ zipcode !== 'null' ? zipcode : 'Keine Angabe' }: { clientsByZipcode[zipcode].length }</Text>
                )) }
              </>
          }
        </Container>
      </AppView>
    )
  }
}
