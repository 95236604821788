/**
 * PaymentMethod
 * @flow
 */
'use strict'

import React from 'react'

import {
  BASE_URL, PORTAL_URL, PRODUCTION, api,
} from '../../../helpers'
import {
  AppView, ArrowButton, BottomNavigation, Button, Colors, Content,
  Icon, Image, Input, LoadingSpinner, Text, TouchableOpacity, View,
} from '../../../components'
import Container from '@material-ui/core/Container'

import logo from '../../../assets/img/logo.png'


type Props = {
  error: ?string,
  orderData: ?Object,
  onNavigate: (orderData: ?Object, route: string) => void,
}

type State = {
  error: ?string,
  validationErrors: ?Object,
  isLoadingCheckoutId: boolean,
  isLoadingCheckoutForm: boolean,

  checkout_id: ?string,
  email: ?string,

  payment_brand: ?string,
  // phone: ?string,
  showPaymentBrandPicker: boolean,
}


export default class PaymentMethod extends React.Component<Props, State> {
  props: Props
  state: State

  constructor(props: Props) {
    super(props)

    // redirect if shoppingCart is not available
    if ( ! props.orderData
      || ! props.orderData.shoppingData
      || ! props.orderData.shoppingData.shoppingCart
      || ! props.orderData.shoppingData.shoppingCart.length
    ) {
      props.onNavigate(null, '/order/packages')
    }

    this.state = {
      error: props.error ? props.error : null,
      validationErrors: null,
      isLoadingCheckoutId: false,
      isLoadingCheckoutForm: false,

      checkout_id: null,
      email: null,

      payment_brand: null,
      // phone: null,
      showPaymentBrandPicker: false,
    }

    const PaymentMethod = (this: any)
    PaymentMethod.onChoosePaymentBrand = this.onChoosePaymentBrand.bind(this)
    PaymentMethod.onContinue = this.onContinue.bind(this)
    PaymentMethod.onLoadCheckoutForm = this.onLoadCheckoutForm.bind(this)
    PaymentMethod.onPrepareCheckout = this.onPrepareCheckout.bind(this)
    PaymentMethod.onReset = this.onReset.bind(this)
    PaymentMethod.onSubmitPayment = this.onSubmitPayment.bind(this)
    PaymentMethod.onWpwlError = this.onWpwlError.bind(this)
    PaymentMethod.onWpwlReady = this.onWpwlReady.bind(this)
    PaymentMethod.onWpwlReadyIframeCommunication = this.onWpwlReadyIframeCommunication.bind(this)
  }


  render() {
    const {
      error, validationErrors, isLoadingCheckoutId, isLoadingCheckoutForm,
      checkout_id, email,
      payment_brand, showPaymentBrandPicker,
      // phone
    } = this.state

    return (
      <AppView
        id="PaymentMethod"
        background={2}
        className="d-block"
      >
        <Container maxWidth="xs">
          <View className="viewWrapper">
            <Image
              alt="ALM"
              className="logo static"
              source={logo}
            />
            <Image
              resizeMode="contain"
              source={require('../../../assets/img/checkout-progress-2.png')}
              className="progressBar"
            />
            <Content>
              { ! payment_brand
                ? <ArrowButton
                    className="paymentBrandChooseButton plain"
                    error={ error && ! showPaymentBrandPicker }
                    iconRight={{
                      src: require('../../../assets/img/dropdown.png'),
                      style: { height: 17, width: 17 },
                    }}
                    textStyle={{ fontFamily: 'Roboto-Regular' }}
                    onPress={() => {
                      this.setState({ showPaymentBrandPicker: ! showPaymentBrandPicker })
                    }}
                  >- Zahlungsart bitte wählen -</ArrowButton>
                : <View className="paymentBrandSelected">
                    <Image
                      source={require('../../../assets/img/checked-circle-blue.png')}
                      className="paymentBrandCheckbox"
                    />
                    <Image
                      resizeMode="contain"
                      source={ payment_brand === 'VISA MASTER' ?
                          require('../../../assets/img/creditcard.png')
                        : payment_brand === 'PAYPAL' ?
                          require('../../../assets/img/paypal.png')
                        : payment_brand === 'EPS' ?
                          require('../../../assets/img/eps.png')
                        : payment_brand === 'SOFORTUEBERWEISUNG' ?
                          require('../../../assets/img/sofort.png')
                        // : payment_brand === 'PAYBOX' ?
                        //   require('../../../assets/img/paybox.png')
                        // : payment_brand === 'PAYSAFECARD' ?
                        //   require('../../../assets/img/paysafecard.png')
                        : null
                      }
                      className="paymentBrandImage"
                    />
                  </View>
              }

              { showPaymentBrandPicker
                ? <View className="paymentBrands">
                    <TouchableOpacity
                      className="paymentBrand"
                      onPress={() => this.onChoosePaymentBrand('VISA MASTER')}
                    >
                      <Image
                        source={require('../../../assets/img/unchecked-circle-blue.png')}
                        className="paymentBrandCheckbox"
                      />
                      <Image
                        resizeMode="contain"
                        source={require('../../../assets/img/creditcard.png')}
                        className="paymentBrandImage"
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      className="paymentBrand"
                      onPress={() => this.onChoosePaymentBrand('PAYPAL')}
                    >
                      <Image
                        source={require('../../../assets/img/unchecked-circle-blue.png')}
                        className="paymentBrandCheckbox"
                      />
                      <Image
                        resizeMode="contain"
                        source={require('../../../assets/img/paypal.png')}
                        className="paymentBrandImage"
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      className="paymentBrand"
                      onPress={() => this.onChoosePaymentBrand('EPS')}
                    >
                      <Image
                        source={require('../../../assets/img/unchecked-circle-blue.png')}
                        className="paymentBrandCheckbox"
                      />
                      <Image
                        resizeMode="contain"
                        source={require('../../../assets/img/eps.png')}
                        className="paymentBrandImage"
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      className="paymentBrand"
                      onPress={() => this.onChoosePaymentBrand('SOFORTUEBERWEISUNG')}
                    >
                      <Image
                        source={require('../../../assets/img/unchecked-circle-blue.png')}
                        className="paymentBrandCheckbox"
                      />
                      <Image
                        resizeMode="contain"
                        source={require('../../../assets/img/sofort.png')}
                        className="paymentBrandImage"
                      />
                    </TouchableOpacity>
                    {/*<TouchableOpacity
                      className="paymentBrand"
                      onPress={() => this.onChoosePaymentBrand('PAYBOX')}
                    >
                      <Image
                        source={require('../../../assets/img/unchecked-circle-blue.png')}
                        className="paymentBrandCheckbox"
                      />
                      <Image
                        resizeMode="contain"
                        source={require('../../../assets/img/paybox.png')}
                        className="paymentBrandImage"
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      className="paymentBrand"
                      onPress={() => this.onChoosePaymentBrand('PAYSAFECARD')}
                    >
                      <Image
                        source={require('../../../assets/img/unchecked-circle-blue.png')}
                        className="paymentBrandCheckbox"
                      />
                      <Image
                        resizeMode="contain"
                        source={require('../../../assets/img/paysafecard.png')}
                        className="paymentBrandImage"
                      />
                    </TouchableOpacity>*/}
                  </View>
                : null
              }
            </Content>
          </View>

          { ! showPaymentBrandPicker && ! payment_brand ?
              <View className="spreadWrapper">
                <Content className="infoWrapper">
                  <Text className="infoText">Sicher bezahlen</Text>
                  <Image
                    source={require('../../../assets/img/payment.png')}
                    className="infoImage"
                  />
                </Content>
              </View>
            : isLoadingCheckoutId ?
              <LoadingSpinner />
            // : ! checkout_id && payment_brand === 'PAYBOX' ?
            //   <View className="spreadWrapper">
            //     <Content>
            //       <Input
            //           error={ error && ! phone }
            //           validationError={ validationErrors && validationErrors['phone']
            //             ? validationErrors['phone'][0]
            //             : null
            //           }
            //           keyboardType="phone-pad"
            //           infoText="Bitte mit internationaler Vorwahl angeben: z.B. +43660xxx"
            //           placeholder="Mobilrufnummer eingeben"
            //           className="input"
            //           value={phone}
            //           onChangeText={(value: string) => this.setState({ phone: value })}
            //         />
            //     </Content>
            //   </View>
            : checkout_id && payment_brand && email ?
              <>
                { ! error && ! isLoadingCheckoutForm
                  ? <Text className="confirmationInfo">
                      Wir senden eine Bestätigung<br />
                      an folgende Adresse:<br />
                      <Text>{email}</Text>
                    </Text>
                  : null
                }
                <View className="spreadWrapper">
                  <form
                    action={`${PORTAL_URL}/order/payment-status`}
                    className="paymentWidgets"
                    data-brands={payment_brand}
                  ></form>
                </View>
              </>
            : null
          }

          <View className="buttonWrapper viewWrapper">
            { isLoadingCheckoutId || isLoadingCheckoutForm
              ? null
              // : ! checkout_id && payment_brand === 'PAYBOX' ?
              //   <Button
              //     className="button"
              //     onPress={() => this.onPrepareCheckout('PAYBOX')}
              //   >Weiter</Button>
              : ! checkout_id ?
                <>
                  { error && error !== true && ! showPaymentBrandPicker
                    ? <Text className="error">{error}</Text>
                    : null
                  }
                  <Button
                    className="button"
                    type="gradient"
                    onPress={this.onContinue}
                  >Weiter</Button>
                </>
              : ! error && ! isLoadingCheckoutForm ?
                <Button
                  className="button"
                  type="gradient"
                  onPress={this.onSubmitPayment}
                >Jetzt kostenpflichtig bestellen</Button>
              : <>
                  { error && ! showPaymentBrandPicker
                    ? <Text className="error">{error}</Text>
                    : null
                  }
                  <Button
                    className="button"
                    type="gradient"
                    onPress={() => this.props.onNavigate(null, '/order/shopping-cart')}
                  >Zurück</Button>
                </>
            }
            <BottomNavigation
              currentRoute="PaymentMethod"
              routes={[
                'Packages', 'ShoppingCart',
                'PaymentMethod', 'Confirmation',
              ]}
            />
          </View>
        </Container>
      </AppView>
    )
  }

  onChoosePaymentBrand(payment_brand: string) {
    this.onPrepareCheckout(payment_brand)

    // if ( payment_brand !== 'PAYBOX' ) {
    //   this.onPrepareCheckout(payment_brand)
    // } else {
    //   this.setState({
    //     error: null,
    //     payment_brand: payment_brand,
    //     showPaymentBrandPicker: false,
    //   })
    // }
  }

  onContinue() {
    const payment_brand = this.state.payment_brand

    if ( ! payment_brand ) {
      this.setState({ error: true })
    }
  }

  onPrepareCheckout(payment_brand: string) {
    const shoppingData = this.props.orderData && this.props.orderData.shoppingData
      ? this.props.orderData.shoppingData
      : null

    if ( shoppingData
      && shoppingData.shoppingCart
      && shoppingData.shoppingCart.length
    ) {
      // if ( payment_brand === 'PAYBOX' && ! this.state.phone ) {
      //   this.setState({ error: true })
      // } else {
        this.setState({
          isLoadingCheckoutId: true,
          isLoadingCheckoutForm: true,
          payment_brand: payment_brand,
          showPaymentBrandPicker: false,
        }, () => {
          api({
            url: 'prepareCheckout',
            method: 'post',
            data: {
              payment_brand: payment_brand,
              // phone: payment_brand === 'PAYBOX' ? this.state.phone : undefined,
              shopping_cart: shoppingData.shoppingCart,
            },
            onSuccess: (response) => {
              // console.debug(response)
              if ( response
                && response.data
                && response.data.status === 'ok'
                && response.data.checkout_id
                && response.data.email
              ) {
                this.setState({
                  error: null,
                  validationErrors: null,
                  isLoadingCheckoutId: false,
                  checkout_id: response.data.checkout_id,
                  email: response.data.email,
                }, this.onLoadCheckoutForm)
              } else {
                this.onReset(() => {
                  this.setState({
                    error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                  })
                })
              }
            },
            onError: (error) => {
              // console.error(error.response)
              if ( error
                && error.response
                && error.response.status == 422
                && error.response.data
                && error.response.data.errors
              ) {
                // const errorInputNames = []
                // Object.keys(error.response.data.errors).map((key) => {
                //   // only show validation errors for phone
                //   if ( key === 'phone' ) {
                //     errorInputNames.push(key)
                //   }
                // })
                // if ( errorInputNames.length ) {
                //   this.setState({
                //     error: 'Bitte überprüfen Sie Ihre Eingabe.',
                //     validationErrors: error.response.data.errors,
                //     isLoadingCheckoutId: false,
                //     isLoadingCheckoutForm: false,
                //   })
                // } else {
                  this.onReset(() => {
                    this.setState({
                      error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                    })
                  })
                // }
              } else {
                this.onReset(() => {
                  this.setState({
                    error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
                  })
                })
              }
            }
          })
        })
      // }
    } else {
      this.onReset(() => {
        this.setState({
          error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
        })
      })
    }
  }

  onReset(callback?: () => void) {
    this.setState({
      error: null,
      validationErrors: null,
      isLoadingCheckoutId: false,
      isLoadingCheckoutForm: false,

      checkout_id: null,
      email: null,

      payment_brand: null,
      // phone: null,
      showPaymentBrandPicker: false,
    }, () => {
      if (callback) callback()
    })
  }

  onLoadCheckoutForm() {
    const { checkout_id, payment_brand } = this.state

    const script1 = document.createElement('script')
    script1.type = 'text/javascript'
    script1.text =
      `var wpwlOptions = {
        locale: 'de',
        style: 'plain',
        brandDetection: true,
        brandDetectionType: 'binlist',
        brandDetectionPriority: ['VISA', 'MASTER'],
        iframeStyles: {
          'card-number-placeholder': {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale'
          },
          'cvv-placeholder': {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale'
          }
        },
        // threeDIframeSize
        sofortCountries: {
          AT: 'Österreich',
          DE: 'Deutschland',
          CH: 'Schweiz',
        },
        spinner: { color: '#08167e', width: 3 },
        onError: function(error) {
          window.PaymentMethod.onWpwlError();
        },
        onLightboxCancel: function() {
          window.PaymentMethod.onWpwlError();
        },
        onReady: function() {
          window.PaymentMethod.onWpwlReady();
        },
        onReadyIframeCommunication: function() {
          window.PaymentMethod.onWpwlReadyIframeCommunication();
        },
        validateCard: function(e) {
          return validateHolder(e);
        },
      }
      function submitForm() {
        // $('.wpwl-button').trigger('click');
        ${ payment_brand === 'VISA MASTER' ?
            `wpwl.executePayment('wpwl-container-card');`
          : payment_brand === 'EPS' ?
            `wpwl.executePayment('wpwl-container-onlineTransfer-EPS');`
          : payment_brand === 'SOFORTUEBERWEISUNG' ?
            `wpwl.executePayment('wpwl-container-onlineTransfer-SOFORTUEBERWEISUNG');`
          : `wpwl.executePayment('wpwl-container-virtualAccount-${payment_brand}');`
        }
      }
      function validateHolder(e) {
        var holderElement = document.getElementsByClassName('wpwl-control-cardHolder')[0]
        var holder = holderElement.value;
        if ( holder.trim().length < 2 && ! holderElement.classList.contains('wpwl-has-error') ) {
          holderElement.classList += ' wpwl-has-error';
          var errorNode = document.createElement('div');
          errorNode.className = 'wpwl-hint wpwl-hint-cardHolderError';
          var errorTextNode = document.createTextNode('Ungültiger Karteninhaber');
          errorNode.appendChild(errorTextNode);
          var wrapperNode = document.getElementsByClassName('wpwl-wrapper-cardHolder')[0];
          wrapperNode.appendChild(errorNode);
          return false;
        }
        return true;
      }`

    const script2 = document.createElement('script')
    script2.type = 'text/javascript'
    script2.src = PRODUCTION
      ? `https://oppwa.com/v1/paymentWidgets.js?checkoutId=${checkout_id}`
      : `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkout_id}`

    document.getElementsByTagName('head')[0].appendChild(script1)
    document.getElementsByTagName('head')[0].appendChild(script2)
  }

  onSubmitPayment() {
    window.submitForm()
  }

  onWpwlError() {
    this.setState({
      error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
      isLoadingCheckoutForm: false,
    })
  }

  onWpwlReady() {
    const payment_brand = this.state.payment_brand
    if ( payment_brand && payment_brand !== 'VISA MASTER' ) {
      this.setState({ isLoadingCheckoutForm: false })
    }
  }

  onWpwlReadyIframeCommunication() {
    const payment_brand = this.state.payment_brand
    if ( payment_brand && payment_brand === 'VISA MASTER' ) {
      this.setState({ isLoadingCheckoutForm: false })
    }
  }
}
