/**
 * Button
 * @flow
 */
'use strict'

import React from 'react'

import { Colors, Image, Text, View } from '.'
import { makeStyles } from '@material-ui/core/styles'
import { Button as MuiButton } from '@material-ui/core'


type Props = {
  children: Object,
  className?: string,
  counter?: number,
  containerStyle?: Object,
  style?: Object,
  type?: string,
  onPress: () => void,
}

type State = {
  // ...
}


const useStyles = makeStyles({
  root: {
    marginTop: 5,
    marginBottom: 5,
  },
})


export default function Button(props) {
  const { children, counter, containerStyle, style, type, onPress, ...other } = props
  const classes = useStyles()

  return (
    <View style={{ position: 'relative', ...containerStyle }}>
      <MuiButton
        className={classes.root}
        color={ props.className === 'plain' ? 'secondary' : 'primary'}
        fullWidth={true}
        size="medium"
        variant={ props.className === 'plain' ? 'outlined' : 'contained' }
        onClick={onPress}
        style={{
          background: type === 'gradient'
            ? 'linear-gradient(90deg, #08167E, #172F8F, #3C6EBB, #73CAFC)'
            : type === 'outlined'
              ? 'transparent'
              : '',
          border: type === 'outlined' ? '2px solid #08167E' : '0',
          borderRadius: 20,
          color: type === 'outlined' ? '#08167E' : '#fff',
          height: type === 'outlined' ? 39 : 40,
          marginTop: 20,
          marginBottom: 20,
          ...style
        }}
        {...other}
      >{children}</MuiButton>
      { counter
        ? <View className="counterWrapper">
            <Image
              source={require('../assets/img/red-circle.png')}
              className="counterIcon"
            />
            <Text className={ counter >= 10
              ? 'counterTextSmall counterText'
              : 'counterText'
            }>{counter}</Text>
          </View>
        : null
      }
    </View>
  )
}
