/**
 * Checkbox
 * @flow
 */
'use strict'

import React from 'react'

import { Icon, View } from '.'


type Props = {
  checked: boolean,
  children?: Object,
  className?: string,
  style?: Object,
  onPress: () => void,
}

type State = {
  // ...
}


export default class Checkbox extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
  }

  render() {
    const { checked, children, className, style, onPress } = this.props

    const iconSrc = (
      className === 'gray' && checked ? require('../assets/img/checked-gray.png') :
      className === 'gray' && ! checked ? require('../assets/img/unchecked-gray.png') :
      checked ? require('../assets/img/checked.png') : require('../assets/img/unchecked.png')
    )

    return (
      <View className="checkboxWrapper">
        <Icon
          src={iconSrc}
          className={ className === 'gray'
            ? 'checkboxGray'
            : 'checkbox'
          }
          onPress={onPress}
        />
        { children ? children : null }
      </View>
    )
  }
}
