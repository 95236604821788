/**
 * Input
 * @flow
 */
'use strict'

import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import { Colors, Image, View } from '.'


type Props = {
  error?: boolean,
  validationError?: string,
  children?: Object,
  shrink?: boolean,
  title: string,
  value: any,
  onPress?: () => void,
}

type State = {
  // ...
}

const useStyles = makeStyles({
  root: {
    marginTop: 5,
    marginBottom: 5,
  },
  input: {
    color: Colors.primary,
    fontSize: 13,
    // paddingTop: 13,
    // paddingBottom: 13,
    cursor: 'pointer',
    '&::placeholder': {
      color: Colors.secondary,
      opacity: 1,
    },
  },
  label: {
    fontSize: 13,
  },
})


export default function Input(props: Props) {
  const {
    error, validationError, children, shrink,
    title, value, onPress, ...other
  } = props
  const classes = useStyles()

  return (
    <View className={ children
      ? 'inputWrapperExpanded'
      : 'inputWrapper'
    }>
      <TextField
        autoComplete="off"
        className={classes.root}
        error={ error || validationError }
        fullWidth={true}
        inputProps={{ className: classes.input, readOnly: true }}
        InputLabelProps={{ className: classes.label, shrink: !! shrink }}
        label={ value && ! shrink ? '' : title }
        size="small"
        variant="outlined"
        value={value}
        onClick={onPress}
        {...other}
      />
      { children
        ? <View className="inputChildren">
            {children}
          </View>
        : null
      }
    </View>
  )
}
