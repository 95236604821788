/**
 * Menu
 * @flow
 */
'use strict'

import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  removeDocumentData, removeMessageData, removeOrderData,
} from '../redux/actions'

import { Image, Popup, Text } from '.'
import { api } from '../helpers'


type Props = {
  history: Object,
  location: Object,
  match: Object,
  onToggleDrawer?: (isOpenDrawer: boolean) => void,
}

type State = {
  nextRoute: ?string,
  showAlert: boolean,
}


class MenuClass extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      nextRoute: null,
      showAlert: false,
    }

    const Menu = (this: any)
    Menu.onConfirmNavigate = this.onConfirmNavigate.bind(this)
    Menu.onNavigate = this.onNavigate.bind(this)
    Menu.onLogout = this.onLogout.bind(this)
    Menu.onResetStore = this.onResetStore.bind(this)
  }

  render() {
    return (
      <div id="Menu">
        <Image
          source={require('../assets/img/logo.png')}
          className="nav-logo"
        />
        <nav>
          <ul>
            <li><Link onClick={() => this.onConfirmNavigate('/')}>Startseite</Link></li>
            <li><Link onClick={() => this.onConfirmNavigate('/create-message')}>Botschaft erstellen</Link></li>
            <li><Link onClick={() => this.onConfirmNavigate('/create-document')}>Tresorinhalt erstellen</Link></li>
            <li><Link onClick={() => this.onConfirmNavigate('/messages')}>Meine Botschaften</Link></li>
            <li><Link onClick={() => this.onConfirmNavigate('/vault')}>Meine Tresorinhalte</Link></li>
            <li><Link onClick={() => this.onConfirmNavigate('/contacts')}>Kontakte</Link></li>
            <li><Link onClick={() => this.onConfirmNavigate('/profile')}>Mein Profil</Link></li>
            <li><Link onClick={() => this.onConfirmNavigate('/credit')}>Guthaben</Link></li>
            <li><a href="https://www.alm-app.eu/faqs" target="_blank">FAQs</a></li>
            <li><a href="https://www.alm-app.eu/bsp_tipps" target="_blank">Beispiele & Tipps</a></li>
            <li><a href="https://www.alm-app.eu/agb" target="_blank">AGB</a></li>
            <li><a href="https://www.alm-app.eu/faqs#FAQ3" target="_blank">Kosten</a></li>
            <li><a href="https://www.alm-app.eu/impressum" target="_blank">Impressum</a></li>
            <li><a href="https://www.alm-app.eu/datenschutz" target="_blank">Datenschutz</a></li>
            <li><a href="https://www.alm-app.eu/kontakt" target="_blank">Kontakt</a></li>
            <li><Link onClick={this.onLogout}>LOGOUT</Link></li>
          </ul>
        </nav>

        <Popup
          visible={this.state.showAlert}
          title="Bist Du sicher?"
          onConfirm={this.onNavigate}
          onClose={() => {
            this.setState({ nextRoute: null, showAlert: false })
            this.props.onToggleDrawer(false)
          }}
        >
          <Text>Deine Botschaft wird nicht gespeichert. Möchtest Du trotzdem fortsetzen?</Text>
        </Popup>
      </div>
    )
  }

  onConfirmNavigate(nextRoute: string) {
    const currentRouteName = this.props.location.pathname
    const isCreateNewMessageRoute = currentRouteName.startsWith('/create-message')

    if ( isCreateNewMessageRoute ) {
      this.setState({
        showAlert: true,
        nextRoute,
      })
    } else {
      this.onResetStore()
      this.props.history.push(nextRoute)
    }
  }

  onNavigate() {
    const nextRoute = this.state.nextRoute

    if ( nextRoute ) {
      this.onResetStore()
      this.props.history.push(nextRoute)
    } else {
      this.setState({ nextRoute: null, showAlert: false })
      this.props.onToggleDrawer(false)
    }
  }

  onLogout(event) {
    event.preventDefault()

    this.onResetStore()

    // expire cookie
    api({
      url: 'logout',
      method: 'post',
      onSuccess: (response) => {
        // console.debug(response)
        if ( response
          && response.data
          && response.data.status === 'ok'
        ) {
          // redirect to login
          this.props.history.push('/login')
        } else {
          // this.setState({
          //   error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
          //   isLoading: false,
          // })
        }
      },
      onError: (error) => {
        // console.error(error.response)
        // this.setState({
        //   error: 'Ein Fehler ist aufgetreten. Bitte später erneut versuchen.',
        //   isLoading: false,
        // })
      }
    })
  }

  onResetStore() {
    // remove all data from redux store
    this.props.removeDocumentData()
    this.props.removeMessageData()
    this.props.removeOrderData()
  }
}

const Menu = withRouter(MenuClass)

const mapDispatchToProps = (dispatch) => ({
  removeDocumentData: () => dispatch(removeDocumentData()),
  removeMessageData: () => dispatch(removeMessageData()),
  removeOrderData: () => dispatch(removeOrderData()),
})

export default connect(
  null,
  mapDispatchToProps
)(Menu)
