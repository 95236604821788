/**
 * Dropdown
 * @flow
 */
'use strict'

import React from 'react'

import { Colors } from '.'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'


type Props = {
  error?: boolean,
  validationError?: string,
  className?: string,
  items: Array<Object>,
  placeholder?: string,
  value: ?number,
  onValueChange: (value: string | number, index: number) => void,
}

type State = {
  // ...
}

const CustomInput = withStyles(theme => ({
  root: {
    width: '100%',
    textAlign: 'left',
    marginTop: 5,
    marginBottom: 5,
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    fontSize: 13,
    padding: '8px 26px 8px 12px',
    fontFamily: 'Roboto',
    '&:focus': {
      borderRadius: 4,
      backgroundColor: 'transparent',
    },
  },
}))(InputBase)


export default function Dropdown(props: Props) {
  const {
    error, validationError, className, items,
    placeholder, style, value, onValueChange, ...other
  } = props

  return (
    <Select
      className="Dropdown"
      displayEmpty={placeholder}
      input={<CustomInput />}
      size="small"
      style={{
        color: ! value ? Colors.secondary : Colors.primary,
        border: validationError || error ? `1px solid ${Colors.error}` : '1px solid rgb(166,166,166)',
        borderRadius: 4,
        ...style,
      }}
      value={ value || "" }
      variant="outlined"
      onChange={(event: Object) => onValueChange(event.target.value)}
      {...other}
    >
      { placeholder
        ? <MenuItem value="">
            {placeholder}
          </MenuItem>
        : null
      }
      { items.map((item, index) => {
          return (
            <MenuItem value={item.value}>{item.label}</MenuItem>
          )
        })
      }
    </Select>
  )
}
